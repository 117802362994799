import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import PayantTextField from "../components/textfield";
import PayantCheckbox from "../components/checkbox";
import { useSnackbar } from "notistack";
import { FbLogin, FbSignup } from "../core/firebase";
import { BG_LOGIN, PAYANT_LOGO_TRANSPARENT, PHOENIX } from "../assets/assets";
import LoadingIndicator from "../components/loadingIndicator";

export default function PageSignup() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{
    name?: boolean;
    email?: boolean;
    pass1?: boolean;
    pass2?: boolean;
    agreement?: boolean;
  }>();
  const [form, setForm] = useState<any>({
    name: "",
    email: "",
    pass1: "",
    pass2: "",
    agreement: false,
  });
  const [accountCreated, setAccountCreated] = useState(false);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  // For redirecting
  const nav = useNavigate();

  // For errors and msgs
  const { enqueueSnackbar } = useSnackbar();

  function isFormValid() {
    if (!form) return false;
    if (!form.name) return false;
    if (!form.pass1) return false;
    if (!form.pass2) return false;
    if (!form.agreement) return false;
    return true;
  }

  /**
   * Parse the input and send it to the register function.
   */
  async function signup() {
    // Get the data.
    setErrors({});
    try {
      if (!Boolean(form.name)) {
        enqueueSnackbar("Please enter your full name.", {
          variant: "error",
        });
        setErrors({ name: true });
        return;
      }
      if (!Boolean(form.email)) {
        enqueueSnackbar("Please enter a valid email address.", {
          variant: "error",
        });
        setErrors({ email: true });
        return;
      }
      if (form.pass1 !== form.pass2) {
        enqueueSnackbar("Passwords do not match.", { variant: "error" });
        setErrors({ pass1: true, pass2: true });
        return;
      }
      if (form.pass1.length < 8) {
        enqueueSnackbar("Password must be at least 8 characters long.", {
          variant: "error",
        });
        setErrors({ pass1: true, pass2: true });
        return;
      }
      if (!form.agreement) {
        enqueueSnackbar(
          "You must accept Payant's terms of services and privacy policy.",
          {
            variant: "error",
          }
        );
        setErrors({ agreement: true });
        return;
      }

      setLoading(true);
      const resp = await FbSignup(form.email, form.name, form.pass1);
      // Signed up. Now, we wait for verification.
      setAccountCreated(true);
      waitForVerification();
      enqueueSnackbar(
        "Account created. Please verify your account by clicking the link sent to your email.",
        { variant: "success" }
      );
    } catch (err: any) {
      if (err.message?.includes("auth/invalid-email")) {
        setErrors({ email: true });
        enqueueSnackbar(
          "Invalid email address, please try entering your email again.",
          {
            variant: "error",
          }
        );
      }
      if (err.message?.includes("auth/email-already-in-use")) {
        setErrors({ email: true });
        enqueueSnackbar("An account with this email address already exists.", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Error signing up. Please contact administrators.", {
          variant: "error",
        });
        console.log(err);
      }
    }
    setLoading(false);
  }

  /**
   * Called evert 5 seconds only after the previous execution has finished.
   */
  async function checkUser() {
    // Try logging in on the backend.
    try {
      //const resp = await Login(email, pass);
      const resp = await FbLogin(form.email, form.pass1);
      if (resp.emailVerified) {
        return true;
      } else {
        return false;
      }
    } catch (err: any) {
      enqueueSnackbar("Unknown error: " + err.message, { variant: "error" });
      console.log(err);
      return false;
    }
  }

  /**
   * Starts the timer to check for account verification, every 5 seconds.
   * @returns
   */
  function waitForVerification() {
    let timer: any;
    async function _tick() {
      try {
        const r = await checkUser();
        if (r) {
          // Login succeeded. Here we redirect.
          enqueueSnackbar("Email verified, redirecting...", {
            variant: "success",
          });
          nav("/portal");
          return; // Prevent further timeouts
        }
      } catch (err: any) {
        console.log(err);
      }
      // Re-set the timer to start again
      timer = setTimeout(_tick, 5000);
    }
    timer = setTimeout(_tick, 0);
    return timer;
  }

  async function loadSupport() {
    try {
      // Here we show the bubbble
      (window as any).Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "xrmsb03k",
      });
    } catch (err: any) {}
  }

  useEffect(() => {
    loadSupport();
  }, []);

  return (
    <Grid
      container
      columns={2}
      sx={{
        width: "100%",
      }}
    >
      {/* The signup form  */}
      <Grid item xs={isDesktop ? 1 : 2}>
        <Stack
          flex={1}
          sx={{
            background: "#FFFFFF",
            py: isDesktop ? "0px" : "24px",
            height: "100vh",
            px: isDesktop ? 0 : "18px",
          }}
          justifyContent="center"
          alignItems="center"
        >
          {!accountCreated && (
            <Stack
              spacing={isDesktop ? "18px" : "12px"}
              sx={{ px: isDesktop ? "0px" : "12px" }}
            >
              <Stack
                direction="row"
                sx={{ width: "100%" }}
                spacing={1}
                alignItems="center"
              >
                <Typography fontSize={"14px"}>Have an account?</Typography>
                <NavLink
                  style={{ fontSize: "14px", textDecoration: "none" }}
                  to="/login"
                >
                  Sign In
                </NavLink>
              </Stack>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: isDesktop ? "44px" : "24px",
                  fontWeight: 700,
                  lineHeight: isDesktop ? "53px" : "20px",
                }}
              >
                Welcome to Payant
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "14px",
                  width: isDesktop ? "466px" : "auto",
                }}
              >
                Tired of getting paid late or worse, getting ghosted by web 3
                and crypto companies who owe you money? Sign up for Payant and
                never chase an invoice again.
              </Typography>
              {/* <img
            style={{ marginTop: "10px", alignSelf: "center" }}
            src={PAYANT_LOGO_TRANSPARENT}
            alt="Payant.io Logo"
            width={196}
          /> */}
              <PayantTextField
                error={errors?.name}
                label="Full Name"
                placeholder="Full name"
                onChange={(c) =>
                  setForm({ ...form, name: c.currentTarget.value })
                }
                disabled={loading}
                autoComplete="name"
                autoFocus
              />
              <PayantTextField
                error={errors?.email}
                label="Email Address"
                placeholder="Email address"
                onChange={(c) =>
                  setForm({ ...form, email: c.currentTarget.value })
                }
                disabled={loading}
                autoComplete="email"
              />
              <PayantTextField
                error={errors?.pass1}
                label="Password"
                type="password"
                disabled={loading}
                placeholder="Password"
                onChange={(c) =>
                  setForm({ ...form, pass1: c.currentTarget.value })
                }
              />
              <PayantTextField
                label="Confirm Password"
                error={errors?.pass2}
                type="password"
                placeholder="Confirm Password"
                onChange={(c) =>
                  setForm({ ...form, pass2: c.currentTarget.value })
                }
                disabled={loading}
              />
              <FormControlLabel
                disabled={loading}
                control={
                  <PayantCheckbox
                    onChange={(c) =>
                      setForm({ ...form, agreement: c.currentTarget.checked })
                    }
                  />
                }
                label="I agree to Payant's terms of services and privacy policy."
                sx={{ fontSize: "12px", fontWeight: 400 }}
              />
              <Button
                variant="contained"
                disabled={loading || !isFormValid()}
                onClick={signup}
              >
                Create a free account
              </Button>
            </Stack>
          )}
          {accountCreated && (
            <Stack
              spacing={isDesktop ? "18px" : "12px"}
              sx={{ px: isDesktop ? "0px" : "12px" }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: isDesktop ? "44px" : "24px",
                  fontWeight: 700,
                  lineHeight: "53px",
                }}
              >
                Welcome to Payant
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "14px",
                  width: isDesktop ? "466px" : "auto",
                }}
              >
                Please check your inbox, and click on the verification link to
                activate your account.
              </Typography>
              <Stack alignItems="center">
                <Typography sx={{ fontWeight: 700 }}>
                  Waiting for account confirmation...
                </Typography>
                <LoadingIndicator />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Grid>

      {/* Message  */}
      <Grid item xs={isDesktop ? 1 : 2}>
        <Stack
          justifyContent="center"
          alignItems="center"
          flex={1}
          sx={{
            background: "#5D18EB",
            color: "white",
            py: "8px",
            height: "100%",
          }}
        >
          <Stack>
            <Typography
              sx={{
                fontFamily: "Baloo",
                fontSize: isDesktop ? "99px" : "28px",
                lineHeight: isDesktop ? "104px" : "30px",
                ml: isDesktop ? 0 : "32px",
                mt: isDesktop ? 0 : "32px",
              }}
            >
              GET PAID
              <br />
              ON TIME.
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Bakbak One'",
                fontSize: "99px",
                lineHeight: "0px",
                mt: "40px",
                color: "#80E7D6",
                ml: "32px",
              }}
            >
              “
            </Typography>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                lineHeight: "14.5px",
                color: "#FFFFFF",
                width: "329px",
                ml: "32px",
              }}
            >
              Why is it so hard to get paid?
              <br />
              <br />
              Well, founder Emily often asked herself the same thing. She got
              paid late more times than she can count in the 7 years she worked
              as a contractor in Web 3 and Crypto, and the big companies were
              the worst. She was so over chasing invoices, that she decided to
              build a solution herself.
              <br />
              <br />
              With Payant, you get access to a web 2.5 platform that secures
              your payments in a smart escrow, so you never get paid late again.
            </Typography>
            <Stack
              sx={{
                borderLeft: "1px solid #80E7D6",
                height: isDesktop ? "200px" : "100px",
                my: "10px",
                pl: "22px",
                pt: "30px",
              }}
            >
              <Stack direction="row" alignItems="center" spacing={"12px"}>
                <Avatar
                  sx={{ height: "46px", width: "46px" }}
                  src={PHOENIX}
                  alt="Emily Rose Dallara"
                />
                <Stack spacing={"6px"}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "14px",
                      fontFamily: "Inter",
                    }}
                  >
                    Emily Rose Dallara
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      fontFamily: "Inter",
                    }}
                  >
                    CEO & Founder
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}
