/* eslint-disable react/jsx-no-comment-textnodes */
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Tab,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Onboarding from "../dialogs/onboarding";
import { FbGetProfile } from "../core/firebase";
import { BasicDialogProps } from "../models/DialogProps";
import { INVOICE_STATUS } from "payant-lib";
import { TransparentPaper } from "../components/transPaper";
import { Moneyfy, isActiveInvoice } from "../core/helper";
import {
  AddCircleOutlineOutlined,
  TaskAltRounded,
  VisibilityOutlined,
} from "@mui/icons-material";
import RevenueChart from "../components/revenueChart";
import OverviewCard from "../components/overviewCard";
import { TabContext } from "@mui/lab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import RecentInvoices from "../components/recentInvoices";
import RecentClients from "../components/recentClients";
import { useRecoilState } from "recoil";
import { aPageTitle } from "../states/ui";
import { useLocation, useNavigate } from "react-router";
import RecentQuotes from "../components/quotes";
import { BarChartIcon } from "../assets/icons";
import PageTitle from "../components/pageTitle";
import { RestGetInvoices } from "../core/api";

type DashboardData = {
  quotesApproved: number;
  quotesAwaiting: number;
  underReview: number;
  active: number;
  totalReceived: string;
  totalUnlocked: string;
};

export default function SectionDashboard() {
  const [data, setData] = useState<DashboardData>();
  const [selectedTab, setSelectedTab] = useState("1");
  const [scrollId, setScrollId] = useState(0);
  const [pageTitle, setPageTitle] = useRecoilState(aPageTitle);
  const nav = useNavigate();
  const loc = useLocation();
  const params = new URLSearchParams(loc.search);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  // If the boarded property of the current user is set to false, we show the onboarding dialog.
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();

  function showOnBoarding() {
    const modalData: React.FC<BasicDialogProps> = (props) => (
      <Dialog
        PaperComponent={TransparentPaper}
        open={props.isOpen}
        sx={{
          backdropFilter: "blur(2px)",
        }}
        fullWidth={isDesktop ? false : true}
      >
        <DialogContent sx={{ p: 0, ...(isDesktop ? { width: "466px" } : {}) }}>
          <Onboarding {...props} />
        </DialogContent>
      </Dialog>
    );

    const modal: any = showModal(modalData, {
      isOpen: true,
      closeHandler: () => modal.destroy(),
      canDismiss: false,
    });
  }

  async function load() {
    try {
      // If onboarding=true is present, we show onboarding dialog.
      if (params.has("onboarding") && params.get("onboarding") === "true") {
        // Let's also make sure we haven't already boarded.
        const prof = await FbGetProfile();
        if (prof && !prof.boarded) {
          showOnBoarding();
        }
      }
      // 1. Get invoices.
      const invs = await RestGetInvoices();
      // Iterate each
      let quotesAwaiting = 0;
      let quotesApproved = 0;
      let totalReceived = 0;
      let totalUnlocked = 0;
      let underReview = 0;
      let active = 0;
      for (let i of invs) {
        // Awaiting approval
        if (
          i.status === INVOICE_STATUS.REVIEW ||
          i.status === INVOICE_STATUS.REVIEWED
        ) {
          quotesAwaiting++;
        }
        // Approved
        if (i.status === INVOICE_STATUS.VALIDATED) {
          quotesApproved++;
        }
        // Active
        if (isActiveInvoice(i)) {
          active += 1;
        }
        // Delivered, waiting for approval
        if (i.status === INVOICE_STATUS.DELIVERED) {
          underReview += 1;
        }
        // If payment is made for this invoice, we include it in total received.
        if (
          i.status === INVOICE_STATUS.PAID ||
          i.status === INVOICE_STATUS.DELIVERED ||
          i.status === INVOICE_STATUS.REJECTED ||
          i.status === INVOICE_STATUS.DISPUTE ||
          i.status === INVOICE_STATUS.APPROVED
        ) {
          totalReceived += i.total;
        }
        // If it is completed, its paid out
        if (i.status === INVOICE_STATUS.APPROVED) {
          totalUnlocked += i.total;
        }
      }
      setData({
        quotesApproved: quotesApproved,
        quotesAwaiting: quotesAwaiting,
        totalUnlocked: Moneyfy(totalUnlocked),
        totalReceived: Moneyfy(totalReceived),
        active: active,
        underReview: underReview,
      });
    } catch (err: any) {
      // error
      enqueueSnackbar("Error loading data. ", {
        variant: "error",
      });
      console.log(err);
    }
  }

  function toggleScrollId() {
    setScrollId(scrollId === 0 ? 1 : 0);
  }

  function createClick() {
    if (selectedTab === "3") {
      // Create client
      nav("../clients/create");
    } else {
      // Create invoice/quote
      nav("../invoices/create");
    }
  }

  useEffect(() => {
    setPageTitle("Dashboard");
    load();
    const timer = setInterval(toggleScrollId, 15000);
    return () => clearInterval(timer);
  }, []);

  // Due to compaitability issues with grid, we cannot use it in desktop version (scroll bars do not work otherwise)
  // To fix that, the containers of this page are broken into two variants.
  // However, the elements remain same.

  function Cards() {
    return (
      <Stack
        spacing={{
          xs: "16px",
          md: "32px",
        }}
      >
        <OverviewCard
          totalUnlocked={data?.totalUnlocked || "$0"}
          totalReceived={data?.totalReceived || "$0"}
        />
        <Stack
          direction="row"
          sx={{
            overflow: "visible",
            width: isDesktop ? "520px" : undefined,
          }}
        >
          <Stack justifyContent="center" alignItems={"center"}>
            <Stack
              alignItems="center"
              justifyContent="center"
              spacing={"4px"}
              sx={{
                position: "relative",
                alignSelf: "center",
                width: 0,
                left: "-8px",
              }}
            >
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  background: scrollId === 0 ? "#5D18EB" : "transparent",
                  border: scrollId === 1 ? "2px solid #938BEF" : "none",
                  transition: "all .5s linear",
                }}
                onClick={() => setScrollId(0)}
              />
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  background: scrollId === 1 ? "#5D18EB" : "transparent",
                  borderRadius: "50%",
                  border: scrollId === 0 ? "2px solid #938BEF" : "none",
                  transition: "all .5s linear",
                }}
                onClick={() => setScrollId(1)}
              />
            </Stack>
          </Stack>
          <Stack
            justifyContent={"space-between"}
            direction="row"
            flex={1}
            spacing={isDesktop ? "27px" : "16px"}
          >
            {/* Quotes Awaiting Approval & Approved */}
            {scrollId === 0 && (
              <>
                <Tooltip title="This is the total number of proposal that are under review by your clients.">
                  <Stack
                    direction="row"
                    flex={1}
                    sx={{
                      background: "white",
                      borderRadius: "18px",
                      pl: "16px",
                      pr: isDesktop ? "25px" : "16px",
                      py: isDesktop ? "24px" : "16px",
                      // width: "230px",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {/* Label and Icon  */}
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Stack
                        sx={{
                          background: "#FFE9EA",
                          width: "32px",
                          height: "32px",
                          minWidth: "32px",
                          borderRadius: "50%",
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <VisibilityOutlined width={"16px"} height={"16px"} />
                      </Stack>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: isDesktop ? "14px" : "12px",
                          px: "10px",
                        }}
                      >
                        Proposals Awaiting Approval
                      </Typography>
                    </Stack>
                    {/* Value  */}
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: data?.quotesAwaiting ? "25px" : "18px",
                        color: data?.quotesAwaiting ? "black" : "#7F7F88",
                      }}
                    >
                      {data?.quotesAwaiting || "0"}
                    </Typography>
                  </Stack>
                </Tooltip>
                <Tooltip title="This is the total number of proposals that have been approved by your clients and are waiting for Invoice Creation.">
                  <Stack
                    direction="row"
                    flex={1}
                    sx={{
                      background: "white",
                      borderRadius: "18px",
                      pl: "16px",
                      pr: isDesktop ? "25px" : "16px",
                      py: isDesktop ? "24px" : "16px",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {/* Label and Icon  */}
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Stack
                        sx={{
                          background: "#FFE9EA",
                          minWidth: "32px",
                          minHeight: "32px",
                          width: "32px",
                          height: "32px",
                          borderRadius: "50%",
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <TaskAltRounded
                          sx={{ color: "green" }}
                          fontSize={"small"}
                        />
                      </Stack>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: isDesktop ? "14px" : "12px",
                        }}
                      >
                        Proposals Approved
                      </Typography>
                    </Stack>
                    {/* Value  */}
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: data?.quotesApproved ? "25px" : "18px",
                        color: data?.quotesApproved ? "black" : "#7F7F88",
                      }}
                    >
                      {data?.quotesApproved || "0"}
                    </Typography>
                  </Stack>
                </Tooltip>
              </>
            )}

            {/* Delivered & Active  */}
            {scrollId === 1 && (
              <>
                <Tooltip title="The total number of invoices with deliverables currently under review by your clients.">
                  <Stack
                    direction="row"
                    flex={1}
                    sx={{
                      background: "white",
                      borderRadius: "18px",
                      pl: "16px",
                      pr: isDesktop ? "25px" : "16px",
                      py: isDesktop ? "24px" : "16px",
                      // width: "230px",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {/* Label and Icon  */}
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Stack
                        sx={{
                          background: "#FFE9EA",
                          width: "32px",
                          height: "32px",
                          minWidth: "32px",
                          minHeight: "32px",
                          borderRadius: "50%",
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <VisibilityOutlined width={"16px"} height={"16px"} />
                      </Stack>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: isDesktop ? "14px" : "12px",
                          px: "10px",
                        }}
                      >
                        Deliverables Under Review
                      </Typography>
                    </Stack>
                    {/* Value  */}
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: data?.underReview ? "25px" : "18px",
                        color: data?.underReview ? "black" : "#7F7F88",
                      }}
                    >
                      {data?.underReview || "0"}
                    </Typography>
                  </Stack>
                </Tooltip>
                <Tooltip title="This is the total number of currently active invoices that are awaiting deliverables or under review.">
                  <Stack
                    direction="row"
                    flex={1}
                    sx={{
                      background: "white",
                      borderRadius: "18px",
                      pl: "16px",
                      pr: isDesktop ? "25px" : "16px",
                      py: isDesktop ? "24px" : "16px",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {/* Label and Icon  */}
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Stack
                        sx={{
                          background: "#FFE9EA",
                          width: "32px",
                          minWidth: "32px",
                          minHeight: "32px",
                          height: "32px",
                          borderRadius: "50%",
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <BarChartIcon
                          sx={{ color: "green" }}
                          fontSize={"small"}
                        />
                      </Stack>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: isDesktop ? "14px" : "12px",
                        }}
                      >
                        Active Invoices
                      </Typography>
                    </Stack>
                    {/* Value  */}
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: data?.active ? "25px" : "18px",
                        color: data?.active ? "black" : "#7F7F88",
                      }}
                    >
                      {data?.active || "0"}
                    </Typography>
                  </Stack>
                </Tooltip>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      flex={1}
      spacing={isDesktop ? "0" : "18px"}
      sx={{
        // For the 2 dots on side, negative margin is applied with same padding to show it as overflow.
        width: "100%",
        px: isDesktop ? "24px" : "18px",
        overflowY: isDesktop ? "hidden" : "scroll",
        "::-webkit-scrollbar": { display: "none" },
      }}
    >
      <PageTitle />
      {/* The first row: contains the cards and the chart  */}
      <Stack direction={"row"} spacing={"24px"}>
        {/* The cards  */}
        <Cards />
        {/* The chart is rendered here on desktop*/}
        {isDesktop && <RevenueChart sx={{ flex: 1 }} />}
      </Stack>
      {/* And for mobile, it is rendered here instead.  */}
      {!isDesktop && <RevenueChart />}
      {/* The second row, contains the quotes, invoices, and clients  */}
      <Stack
        flex={1}
        sx={{
          width: "100%",
          overflow: isDesktop ? "hidden" : "visible",
        }}
      >
        <TabContext value={selectedTab}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            sx={{ borderBottom: 0 }}
          >
            <TabList
              textColor="inherit"
              onChange={(c, e) => setSelectedTab(e)}
              aria-label="Dashboard"
            >
              <Tab
                id="dash_prop"
                sx={{
                  minWidth: "60px",
                  width: isDesktop ? "146px" : "60px",
                  height: "36px",
                  ...(isDesktop ? {} : { fontSize: "10px" }),
                }}
                label="Proposals"
                value="1"
              />
              <Tab
                id="dash_invoice"
                sx={{
                  minWidth: "60px",
                  width: isDesktop ? "146px" : "60px",
                  height: "36px",
                  ...(isDesktop ? {} : { fontSize: "10px" }),
                }}
                label="Invoices"
                value="2"
              />
              <Tab
                id="dash_client"
                sx={{
                  minWidth: "60px",
                  width: isDesktop ? "146px" : "60px",
                  height: "36px",
                  ...(isDesktop ? {} : { fontSize: "10px" }),
                }}
                label="Clients"
                value="3"
              />
            </TabList>
            <Button
              id="dash_CTA"
              onClick={createClick}
              variant="contained"
              sx={{
                width: isDesktop ? "140px" : "110px",
                fontSize: isDesktop ? "12px" : "10px",
                height: "36px",
                borderRadius: "25px",
                justifyContent: "start",
                px: "10px",
                py: "10px",
                background: "#5D18EB",
              }}
              startIcon={<AddCircleOutlineOutlined />}
            >
              {selectedTab === "3" ? "Create Client" : "Create Proposal"}
            </Button>
          </Stack>

          <TabPanel
            id="dash_invoice_table"
            sx={{
              p: 0,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
            value="2"
          >
            <RecentInvoices
              sx={{
                width: "100%",
              }}
            />
          </TabPanel>

          <TabPanel
            id="dash_prop_table"
            sx={{
              p: 0,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
            value="1"
          >
            <RecentQuotes />
          </TabPanel>

          <TabPanel
            id="dash_clients_table"
            value="3"
            sx={{
              p: 0,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <RecentClients
              sx={{
                width: "100%",
              }}
            />
          </TabPanel>
        </TabContext>
      </Stack>
    </Stack>
  );
}
