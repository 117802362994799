import { Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { aPageTitle } from "../states/ui";

export default function PageTitle() {
  const [pageTitle] = useRecoilState(aPageTitle);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  return (
    <Typography
      sx={{
        display: isDesktop ? "initial" : "none",
        fontWeight: 600,
        fontSize: "24px",
        my: "19px",
      }}
    >
      {pageTitle}
    </Typography>
  );
}
