import { Debug, memoryOperand, MemoryType, op, Opcode } from "../utils";
import { concat } from "ethers/lib/utils.js";

export const HIGH_BITS =
  "0xF000000000000000000000000000000000000000000000000000000000000000";

const ME = () => op(Opcode.context, 0x0001);
const YOU = () => op(Opcode.context, 0x0000);

const DEBUG = () => op(Opcode.debug, Debug.StatePacked);

const SENTINEL = () =>
  op(Opcode.read_memory, memoryOperand(MemoryType.Constant, 0));
const ZERO = () =>
  op(Opcode.read_memory, memoryOperand(MemoryType.Constant, 1));
const ONE = () => op(Opcode.read_memory, memoryOperand(MemoryType.Constant, 2));
const TOKEN = () =>
  op(Opcode.read_memory, memoryOperand(MemoryType.Constant, 3));
const DATA_HASH = () =>
  op(Opcode.read_memory, memoryOperand(MemoryType.Constant, 4));

const CALLER_CONTEXT_CLIENT = () => op(Opcode.context, 0x0100);
const CALLER_CONTEXT_CONTRACTOR = () => op(Opcode.context, 0x0101);
const CALLER_CONTEXT_AMOUNT = () => op(Opcode.context, 0x0102);
const CALLER_CONTEXT_END_TIME = () => op(Opcode.context, 0x0103);

const SIGNED_CONTEXT_HASH = () => op(Opcode.context, 0x0300);

const SIGNER = () => op(Opcode.context, 0x0200);

export const sourceFlowIOWithdraw = concat([
  DATA_HASH(), // data hash from constant
  SIGNED_CONTEXT_HASH(), // data hash from signature
  op(Opcode.equal_to),
  DATA_HASH(),
  // data in caller context
  CALLER_CONTEXT_CLIENT(),
  CALLER_CONTEXT_CONTRACTOR(),
  CALLER_CONTEXT_AMOUNT(),
  CALLER_CONTEXT_END_TIME(),
  op(Opcode.hash, 4),
  // compare hash
  op(Opcode.equal_to),
  op(Opcode.every, 2),
  op(Opcode.ensure, 1),

  //compare signer. must be client
  SIGNER(),
  CALLER_CONTEXT_CLIENT(),
  op(Opcode.equal_to, 2),
  op(Opcode.ensure, 1),

  //check if invoice is not settled
  DATA_HASH(),
  op(Opcode.get),
  op(Opcode.ensure, 0),

  SENTINEL(), // ERC1155 SKIP
  SENTINEL(), // ERC721 SKIP
  SENTINEL(), // ERC20 END

  // Token transfer
  TOKEN(),
  ME(), // FLowContract
  CALLER_CONTEXT_CONTRACTOR(), // contractor
  CALLER_CONTEXT_AMOUNT(), // amount

  SENTINEL(), // NATIVE SKIP

  DATA_HASH(),
  ONE(),
  op(Opcode.set),
]);

export const sourceFlowIOCancel = concat([
  DATA_HASH(), // data hash from constant
  SIGNED_CONTEXT_HASH(), // data hash from signature
  op(Opcode.equal_to),
  DATA_HASH(),
  // data in caller context
  CALLER_CONTEXT_CLIENT(),
  CALLER_CONTEXT_CONTRACTOR(),
  CALLER_CONTEXT_AMOUNT(),
  CALLER_CONTEXT_END_TIME(),
  op(Opcode.hash, 4),
  // compare hash
  op(Opcode.equal_to),
  op(Opcode.every, 2),
  op(Opcode.ensure, 1),

  //check if invoice is not settled
  DATA_HASH(),
  op(Opcode.get),
  op(Opcode.ensure, 0),

  //compare signer. must be contractor
  SIGNER(),
  CALLER_CONTEXT_CONTRACTOR(),
  op(Opcode.equal_to, 2),
  op(Opcode.ensure, 1),

  SENTINEL(), // ERC1155 SKIP
  SENTINEL(), // ERC721 SKIP
  SENTINEL(), // ERC20 END

  // Token transfer
  TOKEN(),
  ME(), // FLowContract
  CALLER_CONTEXT_CLIENT(), // contractor
  CALLER_CONTEXT_AMOUNT(), // amount

  SENTINEL(), // NATIVE SKIP

  DATA_HASH(),
  ONE(),
  op(Opcode.set),
]);

export const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay * 1000));
