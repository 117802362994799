import { Button, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import FragChangePassword from "../components/changePassword";
import PayantTextField from "../components/textfield";
import { FbGetProfile, FbSetProfile } from "../core/firebase";
import EditBusinessProfileDialog from "../dialogs/editBusinessProfile";
import EditUserProfileDialog from "../dialogs/editUserProfile";
import { UserProfile } from "payant-lib";
import { useRecoilState } from "recoil";
import { aPageTitle } from "../states/ui";

export default function SectionSettings() {
  const [profile, setProfile] = useState<UserProfile>();

  const [, setPageTitle] = useRecoilState(aPageTitle);
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const [legalEditing, setLegalEditing] = useState(false);
  const [busy, setBusy] = useState(false);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  async function load() {
    try {
      const _profile = await FbGetProfile();
      if (_profile) {
        setProfile(_profile);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading profile data. See logs for details. ", {
        variant: "error",
      });
    }
  }

  function showEditUserProfileDialog() {
    const modal: any = showModal(EditUserProfileDialog, {
      canDismiss: true,
      closeHandler: () => {
        modal.destroy();
        load();
      },
      isOpen: true,
      data: profile,
    });
  }

  function showEditBusinessProfileDialog() {
    const modal: any = showModal(EditBusinessProfileDialog, {
      canDismiss: true,
      closeHandler: () => {
        modal.destroy();
        load();
      },
      isOpen: true,
      data: profile,
    });
  }

  async function showEditLegalMentionsDialog() {
    if (legalEditing) {
      // Save
      setBusy(true);
      try {
        if (!profile) {
          throw new Error("Profile not loaded yet.");
        }
        const _prof = await FbSetProfile(profile!);
        const _newProf = await FbGetProfile();
        if (_prof && _newProf) {
          setProfile(_newProf);
          enqueueSnackbar("Profile updated successfully.", {
            variant: "success",
          });
          setLegalEditing(false);
        } else {
          throw new Error("Unknown error setting profile.");
        }
      } catch (err: any) {
        enqueueSnackbar("Error saving profile. See logs for details.", {
          variant: "error",
        });
        console.log(err);
      }
      setBusy(false);
    } else {
      setLegalEditing(true);
    }
  }

  useEffect(() => {
    setPageTitle("Settings");
    load();
  }, []);

  function PersonalDetails() {
    return (
      <Paper
        elevation={0}
        sx={{
          px: "25px",
          py: isDesktop ? "40px" : "18px",
          height: "344px",
          width: isDesktop ? "500px" : "auto",
          borderRadius: "18px",
        }}
      >
        <Stack spacing={"15px"}>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              Personal Details
            </Typography>
            <Button variant="text" onClick={showEditUserProfileDialog}>
              Edit Details
            </Button>
          </Stack>

          {/* Full name  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: "regular", fontSize: "15px" }}
            >
              Full Name
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              {profile?.fullName}
            </Typography>
          </Stack>

          {/* Phone number  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: "regular", fontSize: "15px" }}
            >
              Phone Number
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              {profile?.phoneNumber}
            </Typography>
          </Stack>

          {/* Email address  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: "regular", fontSize: "15px" }}
            >
              Email Address
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              {profile?.emailAddress}
            </Typography>
          </Stack>

          {/* The address  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: "regular", fontSize: "15px" }}
            >
              Address
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              {profile?.userAddress}
            </Typography>
          </Stack>

          {/* City  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: "regular", fontSize: "15px" }}
            >
              City
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              {profile?.userCity}
            </Typography>
          </Stack>

          {/* Country  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: "regular", fontSize: "15px" }}
            >
              Country
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              {profile?.userCountry}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    );
  }

  function BusinessDetails() {
    return (
      <Paper
        elevation={0}
        sx={{
          px: "25px",
          py: isDesktop ? "40px" : "18px",
          height: "376px",
          width: isDesktop ? "500px" : "auto",
          borderRadius: "18px",
        }}
      >
        <Stack spacing={"15px"}>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              Business Details
            </Typography>
            <Button variant="text" onClick={showEditBusinessProfileDialog}>
              Edit
            </Button>
          </Stack>

          {/* Business name*/}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: "regular", fontSize: "15px" }}
            >
              Legal Business Name
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              {profile?.businessName}
            </Typography>
          </Stack>

          {/* Registration Number  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: "regular", fontSize: "15px" }}
            >
              Registration Number
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              {profile?.registrationNumber}
            </Typography>
          </Stack>

          {/* Tax ID  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: "regular", fontSize: "15px" }}
            >
              Tax ID
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              {profile?.taxId}
            </Typography>
          </Stack>

          {/* The VAT  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: "regular", fontSize: "15px" }}
            >
              VAT ID
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              {profile?.vatId}
            </Typography>
          </Stack>

          {/* Address  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: "regular", fontSize: "15px" }}
            >
              Address
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              {profile?.address}
            </Typography>
          </Stack>

          {/* City  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: "regular", fontSize: "15px" }}
            >
              City
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              {profile?.city}
            </Typography>
          </Stack>

          {/* Country  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: "regular", fontSize: "15px" }}
            >
              Country
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              {profile?.country}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    );
  }

  function LegalMentions() {
    return (
      <Paper
        elevation={0}
        sx={{
          flex: 1,
          px: "25px",
          py: isDesktop ? "40px" : "18px",
          width: isDesktop ? "500px" : "auto",
          borderRadius: "18px",
        }}
      >
        <Stack spacing={"15px"}>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              Invoice Legal Mentions
            </Typography>
            <Button
              disabled={busy}
              variant="text"
              onClick={showEditLegalMentionsDialog}
            >
              {legalEditing ? "Save" : "Edit"}
            </Button>
          </Stack>
          <PayantTextField
            multiline
            rows={4}
            value={profile?.legalMentions || ""}
            onUpdate={(e) =>
              setProfile({ ...(profile as any), legalMentions: e })
            }
            disabled={!legalEditing}
            placeholder="Please add any legal mentions required."
          />
        </Stack>
      </Paper>
    );
  }

  if (!isDesktop) {
    return (
      <Stack
        className="payant-section"
        direction="column"
        justifyContent={"start"}
        sx={{ height: "100%", width: "100%", px: "18px", overflow: "auto" }}
        spacing={"12px"}
      >
        <PersonalDetails />
        <BusinessDetails />
        <LegalMentions />
        <FragChangePassword />
      </Stack>
    );
  }

  return (
    <Stack
      className="payant-section"
      direction="row"
      justifyContent={"center"}
      alignItems="center"
      sx={{ height: "100%", width: "100%", px: "16px" }}
    >
      <Stack direction={"row"} spacing={"14px"}>
        <Stack spacing={"16px"}>
          {/* Contains two cards vertically: Personal details, and change password  */}
          <PersonalDetails />

          <FragChangePassword />
        </Stack>

        <Stack spacing={"16px"}>
          <BusinessDetails />
          <LegalMentions />
        </Stack>
      </Stack>
    </Stack>
  );
}
