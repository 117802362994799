import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { PAYANT_LOGO } from "../assets/assets";
import { Moneyfy } from "../core/helper";
import { Invoice } from "payant-lib";
import { InvoiceItem } from "payant-lib";

/**
 * Renders the invoice that will be printed or saved.
 * @returns
 */
export default function PrintableInvoice(props: { invoice: Invoice }) {
  const invoice = props.invoice;

  function renderItem(item: InvoiceItem, index: number) {
    return (
      <TableRow>
        <TableCell align="left" width="100px" className="printableBody">
          {index}
        </TableCell>
        <TableCell align="left" className="printableBody">
          {item.description}
        </TableCell>
        <TableCell align="right" className="printableBody">
          {Moneyfy(item.price)}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ width: "800px", height: "842px", p: 3 }} spacing={2}>
      <img alt="Payant Logo" src={PAYANT_LOGO} width="200px" />

      {/* Invoice details  */}
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          {/* From */}
          <Stack>
            <Stack direction="row">
              <Typography width="60px" fontSize="16px" fontWeight={700}>
                From:
              </Typography>
              <Typography width="60px" fontSize="16px">
                {invoice?.contractor?.businessName}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography
                width="60px"
                fontSize="16px"
                fontWeight="bold"
              ></Typography>
              <Typography width="60px" fontSize="16px">
                {invoice?.contractor?.address}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography
                width="60px"
                fontSize="16px"
                fontWeight="bold"
              ></Typography>
              <Typography width="60px" fontSize="16px">
                {invoice?.contractor?.country}
              </Typography>
            </Stack>

            <Stack direction="row">
              <Typography
                width="60px"
                fontSize="16px"
                fontWeight="bold"
              ></Typography>
              <Typography width="60px" fontSize="16px">
                <Typography width="60px" fontSize="16px">
                  {invoice?.contractor?.emailAddress}
                </Typography>
              </Typography>
            </Stack>
            {invoice?.contractor?.vatId && (
              <Stack direction="row">
                <Typography
                  width="60px"
                  fontSize="16px"
                  fontWeight="bold"
                ></Typography>
                <Typography width="60px" fontSize="16px">
                  <Typography width="60px" fontSize="16px">
                    {invoice?.contractor?.vatId}
                  </Typography>
                </Typography>
              </Stack>
            )}

            {invoice?.contractor?.registrationNumber && (
              <Stack direction="row">
                <Typography
                  width="60px"
                  fontSize="16px"
                  fontWeight="bold"
                ></Typography>
                <Typography width="60px" fontSize="16px">
                  <Typography width="60px" fontSize="16px">
                    {invoice?.contractor?.registrationNumber}
                  </Typography>
                </Typography>
              </Stack>
            )}
          </Stack>

          {/* To  */}
          <Stack>
            <Stack direction="row">
              <Typography width="60px" fontSize="16px" fontWeight={700}>
                To:
              </Typography>
              <Typography width="60px" fontSize="16px">
                {invoice?.client?.name}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography
                width="60px"
                fontSize="16px"
                fontWeight="bold"
              ></Typography>
              <Typography width="60px" fontSize="16px">
                {invoice?.client?.address}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography
                width="60px"
                fontSize="16px"
                fontWeight="bold"
              ></Typography>
              <Typography width="60px" fontSize="16px">
                {invoice?.client?.country}
              </Typography>
            </Stack>

            <Stack direction="row">
              <Typography
                width="60px"
                fontSize="16px"
                fontWeight="bold"
              ></Typography>
              <Typography width="60px" fontSize="16px">
                <Typography width="60px" fontSize="16px">
                  {invoice?.client?.email}
                </Typography>
              </Typography>
            </Stack>
            {invoice?.client?.vatId && (
              <Stack direction="row">
                <Typography
                  width="60px"
                  fontSize="16px"
                  fontWeight="bold"
                ></Typography>
                <Typography width="60px" fontSize="16px">
                  <Typography width="60px" fontSize="16px">
                    {invoice?.client?.vatId}
                  </Typography>
                </Typography>
              </Stack>
            )}

            {invoice?.client?.registrationNumber && (
              <Stack direction="row">
                <Typography
                  width="60px"
                  fontSize="16px"
                  fontWeight="bold"
                ></Typography>
                <Typography width="60px" fontSize="16px">
                  <Typography width="60px" fontSize="16px">
                    {invoice?.client?.registrationNumber}
                  </Typography>
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>

        <Stack>
          <Paper
            sx={{ width: "350px", py: "10px", background: "#F5F5F5" }}
            elevation={0}
          >
            <Typography
              textAlign="center"
              fontSize="18px"
              sx={{ fontWeight: 700 }}
              fontWeight={700}
              style={{ fontFamily: "'Inter'", fontWeight: 700 }}
            >
              INVOICE
            </Typography>
          </Paper>
          <Stack direction="row" justifyContent="space-between">
            <Typography>Invoice Number</Typography>
            <Typography textAlign={"end"}>{invoice?.invoiceNumber}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>Name</Typography>
            <Typography textAlign={"end"}>{invoice?.name}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>Issue Date</Typography>
            <Typography textAlign={"end"}>
              {moment(new Date(invoice?.dateCreated)).format("DD-MM-YYYY")}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography>Due Date</Typography>
            <Typography textAlign={"end"}>
              {moment(new Date(invoice?.dateCreated))
                .add(1, "day")
                .format("DD-MM-YYYY")}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight="bold">Amount</Typography>
            <Typography textAlign={"end"} fontWeight={700}>
              {Moneyfy(invoice?.total)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* Invoice Items  */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="printable" align="left" width={"100px"}>
              Item #
            </TableCell>
            <TableCell align="left" className="printable">
              Service
            </TableCell>
            <TableCell align="right" className="printable">
              Price
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoice?.items.map((invoiceItem, ind) =>
            renderItem(invoiceItem, ind + 1)
          )}
        </TableBody>
      </Table>

      {/* Legal and Totals  */}
      <Stack direction="row" justifyContent="space-between" spacing={"100px"}>
        <Stack>
          <Typography
            fontSize="16px"
            style={{ fontWeight: 700 }}
            sx={{ fontWeight: 700 }}
            fontWeight={700}
            fontFamily={"'Inter'"}
          >
            Legal Mentions
          </Typography>
          <Typography fontSize="14px" fontWeight={300}>
            {invoice?.contractor?.legalMentions}
          </Typography>
        </Stack>

        {/* Totals  */}
        <Stack>
          <Paper sx={{ p: "10px", background: "#F5F5F5" }} elevation={0}>
            <Stack
              sx={{ width: "300px" }}
              direction="row"
              justifyContent="space-between"
            >
              <Typography textAlign="center" fontSize="16px">
                Subtotal
              </Typography>
              <Typography textAlign="center" fontSize="16px">
                {Moneyfy(invoice?.total)}
              </Typography>
            </Stack>
            {/* <Stack
              sx={{ width: "300px" }}
              direction="row"
              justifyContent="space-between"
            >
              <Typography textAlign="center" fontSize="16px">
                VAT (0%)
              </Typography>
              <Typography textAlign="center" fontSize="16px">
                {invoice.fee}
              </Typography>
            </Stack> */}
            <Stack
              sx={{ width: "300px" }}
              direction="row"
              justifyContent="space-between"
            >
              <Typography textAlign="center" fontSize="20px" fontWeight={700}>
                Total
              </Typography>
              <Typography textAlign="center" fontSize="20px" fontWeight={700}>
                {Moneyfy(invoice?.total)}
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </Stack>
  );
}
