import { Favorite } from "@mui/icons-material";
import { Divider, Icon, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

/**
 * Shows the total received amount and paid out amount in the card.
 * @param props
 * @returns
 */
export default function OverviewCard(props: {
  totalReceived: string;
  totalUnlocked: string;
}) {
  // Main horizontal container, allows us to create 3 columns.
  return (
    <Stack
      direction="row"
      spacing={"24px"}
      sx={{
        width: "100%",
        height: "186px",
        borderRadius: "18px",
        background: "white",
        py: "24px",
        px: "16px",
      }}
    >
      {/* First container: Total received.  */}
      <Stack flex={1} spacing={"24px"} id="dash_sec">
        {/* Horizontal container: for the heart and label  */}
        <Stack direction="row" spacing="16px" alignItems="center">
          <Stack
            sx={{
              background: "#FFE9EA",
              width: "32px",
              minWidth: "32px",
              minHeight: "32px",
              height: "32px",
              borderRadius: "50%",
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Favorite sx={{ color: "#EF3939" }} />
          </Stack>
          <Tooltip title="This is the total amount of funds currently locked in Payant’s Smart Contract in USD.">
            <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
              Secured In Payant
            </Typography>
          </Tooltip>
        </Stack>
        <Tooltip title="This is the total amount of funds currently locked in Payant’s Smart Contract in USD.">
          <Typography
            sx={{ fontWeight: 700, fontSize: "44px", lineHeight: "53px" }}
          >
            {props.totalReceived || "..."}
          </Typography>
        </Tooltip>
      </Stack>
      {/* The divider  */}
      <Divider orientation="vertical" />
      {/* Second container: Paid Out */}
      <Stack flex={1} spacing={"24px"} id="dash_unlock">
        {/* Horizontal container: for the heart and label  */}
        <Stack direction="row" spacing="16px" alignItems="center">
          <Stack
            sx={{
              background: "#FFE9EA",
              width: "32px",
              height: "32px",
              minWidth: "32px",
              minHeight: "32px",
              borderRadius: "50%",
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Favorite sx={{ color: "#6DC881" }} />
          </Stack>
          <Tooltip title="This is the total amount of funds you can withdraw from Payant’s Smart Contract in USD.">
            <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
              Ready To Unlock
            </Typography>
          </Tooltip>
        </Stack>
        <Tooltip title="This is the total amount of funds you can withdraw from Payant’s Smart Contract in USD.">
          <Typography
            color="#80E797"
            sx={{ fontWeight: 700, fontSize: "44px", lineHeight: "53px" }}
          >
            {props.totalUnlocked || "..."}
          </Typography>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
