import {
  Delete,
  EditSharp,
  FavoriteRounded,
  FindInPage,
  InsertDriveFileOutlined,
  MoreTime,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { useSnackbar } from "notistack";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import SearchBox from "../components/searchbox";
import { GetInvoiceStatus, Moneyfy, isQuote } from "../core/helper";
import InvoiceDialog from "../dialogs/invoiceDetails";
import { Client, INVOICE_STATUS } from "payant-lib";
import { Invoice } from "payant-lib";
import LoadingIndicator from "../components/loadingIndicator";
import SexyCard from "../components/sexyCard";
import { useRecoilState } from "recoil";
import { aPageTitle } from "../states/ui";
import { RestGetInvoice, RestGetInvoices } from "../core/api";
import BusinessDetailsDialog from "../dialogs/clientDetails";
import SortIcon from "../components/sortIcon";
import DeleteQuoteDialog from "../dialogs/deleteQuoteDialog";
import PageTitle from "../components/pageTitle";

export default function SectionQuotes() {
  const [invoices, setInvoices] = React.useState<Invoice[]>([]);
  const [cardsData, setCardsData] = React.useState<{
    drafted: number;
    review: number;
    approved: number;
  }>();
  const [, setPageTitle] = useRecoilState(aPageTitle);
  const [filter, setFilter] = React.useState("");
  const [sort, setSort] = React.useState<
    "name" | "client" | "status" | "deadline" | "amount"
  >("deadline");
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const [busy, setBusy] = React.useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const theme = useTheme();
  const loc = useLocation();
  const params = new URLSearchParams(loc.search);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  const [viewMode, setViewMode] = React.useState<
    "drafted" | "review" | "approved" | "all"
  >("all");
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = {
      x: event.clientX,
      y: event.currentTarget.getBoundingClientRect().y,
    };
  };

  function toggleSort(
    sorting: "name" | "client" | "status" | "deadline" | "amount"
  ) {
    // If the specified sorting is same as previous, we toggle the order only.
    if (sort === sorting) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Change the sorting only
      setSort(sorting);
    }
  }

  function toggleViewMode(mode: "drafted" | "review" | "approved" | "all") {
    if (mode === viewMode) {
      setViewMode("all");
    } else {
      setViewMode(mode);
    }
  }

  // Returns the items for the table view. This function is used to filter and sort items based on various UI selections.
  function getItems() {
    let _invs = [];
    // Apply view mode
    switch (viewMode) {
      case "all":
        _invs = invoices;
        break;
      case "approved":
        _invs = invoices.filter((a) => a.status === INVOICE_STATUS.VALIDATED);
        break;
      case "drafted":
        _invs = invoices.filter(
          (a) =>
            a.status === INVOICE_STATUS.DRAFT ||
            a.status === INVOICE_STATUS.REFUSED
        );
        break;
      case "review":
        _invs = invoices.filter(
          (a) =>
            a.status === INVOICE_STATUS.REVIEW ||
            a.status === INVOICE_STATUS.REVIEWED
        );
        break;
    }
    // Apply sorting
    switch (sort) {
      case "amount": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.total - b.total);
        } else {
          _invs.sort((a, b) => b.total - a.total);
        }
        break;
      }
      case "deadline": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.endDate - b.endDate);
        } else {
          _invs.sort((a, b) => b.endDate - a.endDate);
        }
        break;
      }

      case "client": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.client.name.localeCompare(b.client.name));
        } else {
          _invs.sort((a, b) => b.client.name.localeCompare(a.client.name));
        }
        break;
      }

      case "name": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          _invs.sort((a, b) => b.name.localeCompare(a.name));
        }
        break;
      }

      case "status": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.status.localeCompare(b.status));
        } else {
          _invs.sort((a, b) => b.status.localeCompare(a.status));
        }
        break;
      }
    }

    // Apply filter and return
    if (Boolean(filter)) {
      return _invs.filter(
        (c) =>
          c.name.toLowerCase().includes(filter.toLowerCase()) ||
          c.id!.toString().toLowerCase().includes(filter.toLowerCase())
      );
    } else return _invs;
  }

  async function loadInvoices() {
    try {
      // load clients
      const invs = await RestGetInvoices();
      let awaitingReview = invs.filter(
        (i) =>
          i.status === INVOICE_STATUS.REVIEW ||
          i.status === INVOICE_STATUS.REVIEWED
      ).length;
      let drafted = invs.filter(
        (i) =>
          i.status === INVOICE_STATUS.DRAFT ||
          i.status === INVOICE_STATUS.REFUSED
      ).length;
      let approved = invs.filter(
        (i) => i.status === INVOICE_STATUS.VALIDATED
      ).length;
      setCardsData({
        drafted: drafted,
        review: awaitingReview,
        approved: approved,
      });

      setInvoices(
        invs
          .filter(
            (a) =>
              a.status === INVOICE_STATUS.REVIEW ||
              a.status === INVOICE_STATUS.REVIEWED ||
              a.status === INVOICE_STATUS.VALIDATED ||
              a.status === INVOICE_STATUS.DRAFT ||
              a.status === INVOICE_STATUS.REFUSED
          )
          .sort((a, b) => b.dateCreated - a.dateCreated)
      );
    } catch (err: any) {
      enqueueSnackbar(
        "Error loading proposals. Please contact administrators.",
        {
          variant: "error",
        }
      );
      console.log(err);
    }
    setBusy(false);
  }

  function showInvoiceDialog(inv: Invoice) {
    // If it's a draft, we open editor. Otherwise we show the dialog.
    if (inv.status === INVOICE_STATUS.DRAFT) {
      // Navigate to the invoice page instead, since the quotes are not yet sent.
      navigate("/portal/invoices/create", { state: { invoice: inv } });
    } else {
      const modal: any = showModal(InvoiceDialog, {
        canDismiss: true,
        closeHandler: () => modal.destroy(),
        isOpen: true,
        data: inv,
      });
    }
  }

  async function urlQueryHandler() {
    // If location has showInvoice query, we trigger invoice dialog.
    if (params.has("showInvoice")) {
      const invId = params.get("showInvoice");
      if (invId) {
        // Get inv
        const inv = await RestGetInvoice(invId);
        if (inv) {
          showInvoiceDialog(inv);
        }
      }
    }
  }

  function showClientInfoDialog(cli: Client) {
    const modal: any = showModal(BusinessDetailsDialog, {
      closeHandler: () => modal.destroy(),
      isOpen: true,
      data: cli,
      canDismiss: true,
    });
  }

  function showDeleteDialog(inv: Invoice) {
    const modal: any = showModal(DeleteQuoteDialog, {
      closeHandler: () => {
        modal.destroy();
        loadInvoices();
      },
      isOpen: true,
      data: inv,
      canDismiss: true,
    });
  }

  React.useEffect(() => {
    setBusy(true);
    setPageTitle("Proposals");
    loadInvoices();
    urlQueryHandler();
  }, []);

  function RenderInvoiceItem(invoice: Invoice) {
    const row = (
      <TableRow
        sx={{ background: "transparent" }}
        onClick={() => showInvoiceDialog(invoice)}
        onMouseMove={handleMouseMove}
      >
        <TableCell align="left">
          <Stack direction="row" spacing={"8px"} alignItems="center">
            {isDesktop && <InsertDriveFileOutlined height="10px" />}
            <span>{invoice.name}</span>
          </Stack>{" "}
        </TableCell>
        <TableCell align="left">
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{ textDecoration: "underline", fontSize: "inherit" }}
              onClick={(e) => {
                showClientInfoDialog(invoice.client);
                e.stopPropagation();
              }}
            >
              {invoice.client.name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">{GetInvoiceStatus(invoice)}</TableCell>
        {isDesktop && (
          <TableCell align="left">
            {invoice?.endDate
              ? moment(new Date(invoice!.endDate)).format("Do MMM. YYYY")
              : ""}
          </TableCell>
        )}
        <TableCell align="left">{Moneyfy(invoice.total || 0)}</TableCell>
      </TableRow>
    );

    if (isQuote(invoice)) {
      return (
        <Tooltip
          sx={{ background: "white" }}
          arrow
          enterDelay={1000}
          leaveDelay={0}
          enterNextDelay={1000}
          PopperProps={{
            anchorEl: {
              getBoundingClientRect: () => {
                return new DOMRect(
                  positionRef.current.x,
                  positionRef.current.y + 50,
                  0,
                  0
                );
              },
            },
          }}
          placement="bottom"
          title={
            <Paper sx={{ background: "white" }}>
              <Button
                color="error"
                startIcon={<Delete color="error" />}
                onClick={() => showDeleteDialog(invoice)}
              >
                DELETE
              </Button>
            </Paper>
          }
        >
          {row}
        </Tooltip>
      );
    }

    return row;
  }

  return (
    <Stack
      className="payant-section"
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        // px: "25px",
        px: isDesktop ? "24px" : "18px",
      }}
    >
      <PageTitle />
      <Stack flex={1} sx={{ height: "100%" }}>
        {/* The chards  */}

        <Grid container columns={isDesktop ? 4 : 2} spacing={"10px"}>
          <Grid item xs={1}>
            <SexyCard
              active={viewMode === "drafted"}
              onClick={() => toggleViewMode("drafted")}
              label="Drafted"
              value={cardsData?.drafted?.toString() || "0"}
              icon={<EditSharp />}
            />
          </Grid>{" "}
          <Grid item xs={1}>
            <SexyCard
              active={viewMode === "review"}
              onClick={() => toggleViewMode("review")}
              label="Awaiting Review"
              value={cardsData?.review?.toString() || "0"}
              icon={<FindInPage />}
            />
          </Grid>
          <Grid item xs={1}>
            <SexyCard
              active={viewMode === "approved"}
              onClick={() => toggleViewMode("approved")}
              label="Approved"
              value={cardsData?.approved?.toString() || "0"}
              icon={<FavoriteRounded sx={{ color: "#EF3939" }} />}
            />
          </Grid>
        </Grid>

        {/* The ssearch box and create button */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: isDesktop ? "48px" : "12px", width: "100%" }}
        >
          <SearchBox
            placeholder="Search..."
            onChange={(c) => setFilter(c.currentTarget.value)}
          />
          {/* <Button
            onClick={() => navigate("create")}
            variant="contained"
            sx={{
              width: "132px",
              fontSize: "12px",
              height: "36px",
              borderRadius: "25px",
              justifyContent: "center",
              px: "10px",
              py: "10px",
            }}
            startIcon={<AddCircleOutlineOutlined />}
          >
            Create Quote
          </Button> */}
        </Stack>
        <Box
          sx={{
            mt: isDesktop ? "24px" : "12px",
            px: isDesktop ? "20px" : "18px",
            paddingTop: isDesktop ? "15px" : "0px",
            paddingBottom: isDesktop ? "30px" : "0px",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {/* Main container  */}
          <Stack sx={{ height: "100%", overflow: "hidden" }}>
            {busy && <LoadingIndicator />}
            {!busy && (
              <Stack
                className={"payant-section-scroll-container"}
                flex={getItems().length > 0 ? 1 : "none"}
                sx={{
                  overflow: "hidden",
                }}
              >
                <TableContainer sx={{ flex: 1 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{ m: 0, p: 0, pl: isDesktop ? "48px" : 0 }}
                          onClick={() => toggleSort("name")}
                        >
                          Proposal Name{" "}
                          <SortIcon
                            order={sortOrder}
                            active={sort === "name"}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => toggleSort("client")}
                        >
                          Client Name
                          <SortIcon
                            order={sortOrder}
                            active={sort === "client"}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => toggleSort("status")}
                        >
                          Status{" "}
                          <SortIcon
                            order={sortOrder}
                            active={sort === "status"}
                          />
                        </TableCell>
                        {isDesktop && (
                          <TableCell
                            align="left"
                            onClick={() => toggleSort("deadline")}
                          >
                            Deadline
                            <SortIcon
                              order={sortOrder}
                              active={sort === "deadline"}
                            />{" "}
                          </TableCell>
                        )}
                        <TableCell
                          align="left"
                          onClick={() => toggleSort("amount")}
                        >
                          Amount
                          <SortIcon
                            order={sortOrder}
                            active={sort === "amount"}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {getItems().length > 0 && (
                      <TableBody sx={{ flex: 1 }}>
                        {getItems().map((c) => RenderInvoiceItem(c))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Stack>
            )}

            {Boolean(filter) && !busy && getItems().length === 0 && (
              <Stack flex={1} justifyContent="center" alignItems="center">
                <Search
                  color="primary"
                  sx={{ opacity: 0.5, height: "256px", width: "256px" }}
                />
                <Typography fontSize={"10px"} fontStyle="italic">
                  Your search didn't match anything. Try something else?
                </Typography>
              </Stack>
            )}

            {invoices.length === 0 && !busy && (
              <Stack flex={1} justifyContent="center" alignItems="center">
                <MoreTime
                  color="primary"
                  sx={{ opacity: 0.5, height: "256px", width: "256px" }}
                />
                <Typography fontSize={"10px"} fontStyle="italic">
                  There's nothing to see. Please create an invoice.
                </Typography>
              </Stack>
            )}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
