import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { BasicDialogProps } from "../models/DialogProps";
import { Invoice } from "payant-lib";
import { RestDeleteInvoice } from "../core/api";
import { useSnackbar } from "notistack";

export default function DeleteQuoteDialog(props: BasicDialogProps) {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const q = props.data as Invoice;
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  async function deleteQuote() {
    setIsDeleting(true);
    try {
      const resp = await RestDeleteInvoice(q.id);
      if (resp.status) {
        enqueueSnackbar("Proposal has been deleted successfully.", {
          variant: "warning",
        });
        props.closeHandler();
      } else {
        enqueueSnackbar("Error deleting Proposal. Message: " + resp.message, {
          variant: "error",
        });
      }
    } catch (err: any) {
      enqueueSnackbar(
        "Error deleting Proposal. Please contact administrators.",
        {
          variant: "error",
        }
      );
      console.log(err);
    }
    setIsDeleting(false);
  }

  return (
    <Dialog
      open={props.isOpen}
      sx={{ backdropFilter: "blur(2px)" }}
      onClose={props.closeHandler}
      fullWidth={isDesktop ? false : true}
    >
      <DialogTitle>Delete Proposal?</DialogTitle>
      <DialogContent sx={{ ...(isDesktop ? { width: "466px" } : {}) }}>
        <Typography>
          Are you sure you want to delete the proposal <strong>{q.name}</strong>{" "}
          for <strong>{q.client.name}</strong>?<br />
          <br />
          This cannot be undone!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isDeleting}
          variant="contained"
          onClick={props.closeHandler}
        >
          No
        </Button>
        <Button disabled={isDeleting} color="error" onClick={deleteQuote}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
