import axios from "axios";
import { getAuth } from "firebase/auth";
import { AppConfig } from "../config";
import { HashBcrypt } from "../core/helper";

const BASE = AppConfig.api + "/user";

/**
 * Submits the given user info the registration endpoint and returns the response (pure response).
 * @param name
 * @param email
 * @param password
 */
export async function Register(name: string, email: string, password: string) {
  // Hash the password before sending it to the api
  try {
    const hashedPassword = await HashBcrypt(password);
    const resp = await axios.post(BASE + "/register", {
      email: email,
      name: name,
      password: hashedPassword,
    });
    return resp.data as { status: boolean; code: number; message: string };
  } catch (err: any) {
    // For errors, the data is contained within error.response.data
    console.log(err);
    return err.response.data as {
      status: boolean;
      code: number;
      message: string;
    };
  }
}

/**
 * Returns the JWT (custom token) from the backend on successful login.
 * If the account is unverified, returns HTTP 400
 * @param email
 * @param password
 * @returns
 */
export async function Login(email: string, password: string) {
  // Hash the password before sending it to the api
  try {
    const hashedPassword = await HashBcrypt(password);
    const resp = await axios.post(BASE + "/login", {
      email: email,
      password: hashedPassword,
    });
    return resp.data as {
      status: boolean;
      code: number;
      message: string;
      data: any;
    };
  } catch (err: any) {
    // For errors, the data is contained within error.response.data
    console.log(err);
    return err.response.data as {
      status: boolean;
      code: number;
      message: string;
      data: any;
    };
  }
}

export async function SendVerificationEmail(email: string) {
  // Hash the password before sending it to the api
  try {
    const resp = await axios.post(BASE + "/verification", {
      email: email,
    });
    return resp.data as {
      status: boolean;
      code: number;
      message: string;
      data: any;
    };
  } catch (err: any) {
    // For errors, the data is contained within error.response.data
    console.log(err);
    return err.response.data as {
      status: boolean;
      code: number;
      message: string;
      data: any;
    };
  }
}

/**
 * Sends the password reset email.
 * @param email The email to send the reset password link to.
 * @returns
 */
export async function ResetPassword(email: string) {
  // Hash the password before sending it to the api
  try {
    const resp = await axios.post(BASE + "/reset-password", {
      email: email,
    });
    return resp.data as {
      status: boolean;
      code: number;
      message: string;
    };
  } catch (err: any) {
    // For errors, the data is contained within error.response.data
    console.log(err);
    return err.response.data as {
      status: boolean;
      code: number;
      message: string;
    };
  }
}

/**
 * Returns analytics of the current user.
 */
export async function GetAnalytics(startDate: number, endDate: number) {
  // get the current user
  const user = await getAuth().currentUser!;
  const tokenId = await user.getIdToken(true);

  const resp = await axios.post(
    BASE + "/revenue",
    {
      startDate: startDate,
      endDate: endDate,
    },
    {
      headers: {
        authorization: "bearer " + tokenId,
      },
    }
  );

  return {
    total: resp.data.data.totalRevenue,
    history: resp.data.data.data as {
      [key: string]: {
        total: number;
        count: number;
        date: number; // mm/dd/yyyy
      };
    },
  };
}
