export enum AllStandardOps {
  chainlink_price,
  call,
  context,
  context_row,
  debug,
  do_while,
  extern,
  fold_context,
  get,
  loop_n,
  read_memory,
  set,
  hash,
  erc_1155_balance_of,
  erc_1155_balance_of_batch,
  erc_20_balance_of,
  erc_20_total_supply,
  erc_20_snapshot_balance_of_at,
  erc_20_snapshot_total_supply_at,
  erc_5313_owner,
  erc_721_balance_of,
  erc_721_owner_of,
  ensure,
  block_number,
  block_timestamp,
  explode_32,
  add,
  div,
  exp,
  max,
  min,
  mod,
  mul,
  sub,
  scale_18,
  scale_18_div,
  scale_18_dynamic,
  scale_18_mul,
  scale_by,
  scale_n,
  any,
  eager_if,
  equal_to,
  every,
  greater_than,
  is_zero,
  less_than,
  saturating_add,
  saturating_mul,
  saturating_sub,
  iorderbook_v1_vault_balance,
  isale_v2_remaining_token_inventory,
  isale_v2_reserve,
  isale_v2_sale_status,
  isale_v2_token,
  isale_v2_total_reserve_received,
  iverify_v1_account_status_at_time,
  itier_v2_report,
  itier_v2_report_time_for_tier,
  saturating_diff,
  select_lte,
  update_times_for_tier_range,
  length,
}

export const Opcode = AllStandardOps;

export const RainterpreterOps = AllStandardOps;
