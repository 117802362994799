import { Cancel } from "@mui/icons-material";
import {
  IconButton,
  Paper,
  PaperProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

/**
 * The card that is used in dashboard, and various other sections such as Invoices. I am sorry I couldn't come up with a good name.
 * @returns
 */
export default function SexyCard(
  props: {
    label: string;
    icon: React.ReactNode;
    value: string;
    active?: boolean;
  } & PaperProps
) {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  return (
    <Paper
      elevation={0}
      sx={{
        height: isDesktop ? "86px" : "48px",
        minWidth: isDesktop ? "250px" : "100px",
        width: isDesktop ? "250px" : "100%",
        borderRadius: "18px",
        background: props.active ? "#938BEF" : undefined,
        color: props.active ? "#FFF" : undefined,
      }}
      {...props}
    >
      {props.active && false && (
        <Stack
          sx={{ position: "absolute", width: "100%" }}
          justifyContent={"center"}
          alignItems="end"
        >
          <IconButton size="small">
            <Cancel fontSize={"small"} htmlColor="#000" />
          </IconButton>
        </Stack>
      )}
      <Stack
        direction="row"
        sx={{ height: "100%", width: "100%", px: isDesktop ? "25px" : "14px" }}
        justifyContent={"space-between"}
        alignItems="center"
      >
        {/* The Icon and label  */}
        <Stack
          alignItems="center"
          direction="row"
          spacing={isDesktop ? "4px" : "2px"}
        >
          <Stack
            justifyContent={"center"}
            alignItems="center"
            sx={{
              color: "black",
              height: isDesktop ? "32px" : "24px",
              width: isDesktop ? "32px" : "24px",
              minHeight: isDesktop ? "32px" : "24px",
              minWidth: isDesktop ? "32px" : "24px",
              background: "#FFE9EA",
              borderRadius: "50%",
              "& svg": {
                width: "16px",
                height: "16px",
              },
            }}
          >
            {props.icon}
          </Stack>
          <Typography
            sx={{
              fontSize: isDesktop ? "14px" : "8px",
              fontWeight: 600,
              lineHeight: 16,
              px: isDesktop ? "8px" : "0px",
            }}
          >
            {props.label}
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: isDesktop ? "25px" : "10px",
            fontWeight: 700,
            lineHeight: "0px",
          }}
        >
          {props.value}
        </Typography>
      </Stack>
    </Paper>
  );
}
