import React from "react";
import {
	Alert,
	Button,
	Dialog,
	DialogContent,
	FormControl,
	IconButton,
	InputLabel,
	List,
	MenuItem,
	Select,
	Stack,
	Tooltip,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { Buffer } from "buffer";
import { useChainModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { ethers } from "ethers";
import { keccak256, solidityKeccak256 } from "ethers/lib/utils.js";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useState, useRef } from "react";
import { useAccount, useNetwork, useSigner } from "wagmi";
import {
	CHECKMARK_PURPLE,
	DOCUMENT_PURPLE,
	PENDING_PURPLE,
} from "../assets/assets";
import PayantStepper from "../components/stepper";
import PayantTextField from "../components/textfield";
import { TransparentPaper } from "../components/transPaper";
import { AppConfig } from "../config";
import {
	FbGetClient,
	FbSetClient,
	FbSetInvoice,
	FbSetTransaction,
} from "../core/firebase";
import { fiatToCoin, isInvoice, Moneyfy, printInvoice } from "../core/helper";
import { BasicDialogProps } from "../models/DialogProps";
import { Invoice, INVOICE_STATUS, MediationStatus } from "payant-lib";
import { getEventArgs, getEvents } from "../rain/events/events";
import { sourceFlowIOCancel, sourceFlowIOWithdraw } from "../rain/utils/util";
import { Transaction } from "payant-lib";
import LoadingIndicator from "../components/loadingIndicator";
import { SendInvoice } from "../core/api";
import PrintableInvoice from "../components/printableInvoice";
import { useReactToPrint } from "react-to-print";
import {
	ArrowBackRounded,
	ArrowForwardRounded,
	CloseSharp,
	KeyboardArrowDown,
} from "@mui/icons-material";
import {
	InvoiceActionKind,
	InvoiceActorKind,
} from "payant-lib/dist/models/Invoice";
import { useNavigate } from "react-router";
import { GetDisputeStatus } from "../core/kleros";
import { ContractorOutcomes } from "../models/ContractorOutcomes";

// Returns the content based on invoice statuses and states.
function GetDialogContent(props: { invoice: Invoice; closeHandler: any }) {
	const { enqueueSnackbar } = useSnackbar();
	const [busy, setBusy] = useState(false);
	const [invoice, setInvoice] = useState<Invoice>(props.invoice);
	const [selectedItem, setSelectedItem] = useState(0);
	const { address, isConnected } = useAccount();
	const { chain } = useNetwork();
	const { openConnectModal } = useConnectModal();
	const { openChainModal } = useChainModal();
	const signer = useSigner();
	const currencies = ["usdc", "usdt"];
	const networks = ["ethereum", "polygon mumbai"];
	const navigate = useNavigate();
	const [invoiceDetailsVisible, setInvoiceDetailsVisible] = useState(false);
	const [desiredOutcome, setDesiredOutcome] = useState<number>(-1);
	const [mediationReason, setMediationReason] = useState("");

	const desiredOutcomes = Object.entries(ContractorOutcomes);

	// Used to show the upload options within this dialog, but without changing state.
	const [showUploadScreen, setShowUploadScreen] = useState(false);
	const [showFeedbackScreen, setShowFeedbackScreen] = useState(false);
	const [showDisputeForm, setShowDisputeForm] = useState(false);
	async function loadDisputeInfo() {
		const resp = await GetDisputeStatus(1);
		console.log(resp);
	}
	/**
	 * Called by Upload button. Saves links of the deliverables. And also marks the invoice as delivered.
	 */
	async function saveInvoice() {
		setBusy(true);
		try {
			const _invoice = invoice;
			_invoice.dateDelivered = new Date().getTime();
			_invoice.dateUpdated = new Date().getTime();
			_invoice.status = INVOICE_STATUS.DELIVERED;
			const inv = await FbSetInvoice(_invoice);
			setInvoice(inv);
			// Send email
			await SendInvoice(_invoice.id!);
		} catch (err: any) {
			enqueueSnackbar(
				"Error saving invoice. Please contact administrators.",
				{
					variant: "error",
				}
			);
			console.log(err);
		}
		setBusy(false);
	}

	// Deploys the flow contract
	async function _deploy(_invoice: Invoice) {
		const SENTINEL_HIGH_BITS =
			"0xF000000000000000000000000000000000000000000000000000000000000000";

		const RAIN_FLOW_SENTINEL = ethers.BigNumber.from(
			keccak256([...Buffer.from("RAIN_FLOW_SENTINEL")])
		).or(SENTINEL_HIGH_BITS);

		const contractCfg = AppConfig.contractConfig.find(
			(c) => c.name === invoice.network
		);

		if (!contractCfg) {
			throw new Error("Invalid network selected.");
		}

		// Get the token address
		const tokenInfo = contractCfg.tokens.find(
			(t) => t.name === invoice.currency
		);
		// Hash invoice
		const invoiceHash = solidityKeccak256(
			["uint256[]"],
			[
				[
					// Client
					invoice.payerAddress,
					// Owner
					address,
					// Amount
					fiatToCoin(_invoice.total, tokenInfo!).toString(),
					// Date
					invoice!.endDate!.toString(),
				],
			]
		);
		const constants = [
			RAIN_FLOW_SENTINEL,
			0,
			1,
			tokenInfo!.address,
			invoiceHash,
		];
		const flowConfig = {
			flows: [
				{
					sources: [sourceFlowIOWithdraw],
					constants: constants,
				},
				{
					sources: [sourceFlowIOCancel],
					constants: constants,
				},
			],
		};

		const evaluableConfigs: any[] = [];

		for (let i = 0; i < flowConfig.flows.length; i++) {
			evaluableConfigs.push({
				constants: flowConfig.flows[i].constants,
				sources: flowConfig.flows[i].sources,
				deployer:
					contractCfg!.InterpreterCallerV1ConstructionConfigStruct
						.deployer,
			});
		}

		// Get deployed flowFactory contract
		const c = await new ethers.Contract(
			contractCfg!.flowFactory,
			AppConfig.factoryAbi,
			signer.data!
		).deployed();

		enqueueSnackbar("Waiting for transaction...");

		const txId = await c.functions.createChildTyped({
			dummyConfig: evaluableConfigs[0], // this won't be used anywhere https://github.com/ethereum/solidity/issues/13597
			config: evaluableConfigs,
		});

		// Get event data
		const flowAdr = ethers.utils.hexZeroPad(
			ethers.utils.hexStripZeros(
				(await getEventArgs(txId, "NewChild", c)).child
			),
			20 // address bytes length
		);

		// Get dispatche
		const flow = await new ethers.Contract(
			flowAdr,
			AppConfig.flowAbi,
			signer.data!
		).deployed();
		const flowInitialized = await getEvents(txId, "FlowInitialized", flow);
		const dispatchWithdraw = flowInitialized[0].evaluable;
		const dispatchCancel = flowInitialized[1].evaluable;
		return {
			address: flowAdr,
			dispatchCancel: dispatchCancel,
			dispatchWithdraw: dispatchWithdraw,
		};
	}

	async function deployInvoice() {
		try {
			// Make sure wallet is connected.
			if (!isConnected) {
				openConnectModal?.();
				return;
			}
			// Make sure we are on selected network
			if (chain && chain.name.toLowerCase() !== invoice.network) {
				// Prompt to switch network
				openChainModal?.();
				return;
			}
			setBusy(true);
			// Call deploy
			const flow = await _deploy(invoice);
			invoice.dispatchCancel = flow.dispatchCancel;
			invoice.dispatchWithdraw = flow.dispatchWithdraw;
			invoice.paymentId = flow.address;
			invoice.payeeAddress = address!;
			invoice.dateDeployed = new Date().getTime();
			invoice.dateUpdated = new Date().getTime();
			invoice.status = INVOICE_STATUS.CREATED;
			// save
			await FbSetInvoice(invoice);

			// Update the awaitingPayment for the client.
			const cli = await FbGetClient(invoice.client.id.toString());
			if (cli) {
				// Increment awaitingPayment
				cli.awaitingPayment += invoice.total;
				cli.invoiceCount = (cli.invoiceCount || 0) + 1;
				cli.totalInvoiced = (cli.totalInvoiced || 0) + invoice.total;
				cli.status = invoice.status;
				await FbSetClient(cli);
			}

			// Add the transaction
			// Add transaction to the database for contractor
			const tx: Transaction = {
				clientName: invoice!.client.name,
				clientId: invoice!.client.id,
				amount: invoice!.total,
				invoiceName: invoice!.name,
				invoiceId: invoice!.id!,
				type: "creation",
				time: new Date().getTime(),
				from: invoice!.payeeAddress!,
				to: invoice!.payerAddress!,
				id: flow.address,
				uid: invoice!.ownerId,
			};
			// add the tx to db
			await FbSetTransaction(tx);

			// Send email (in production mode only)

			if (AppConfig.mode === "PRODUCTION") {
				await SendInvoice(invoice!.id!);
			}
			enqueueSnackbar(
				"Your invoice has been created. Your client can now proceed with the payment.",
				{ variant: "success" }
			);
		} catch (err: any) {
			console.log(err);
			enqueueSnackbar(
				"Error creating your invoice. Please contact administrators.",
				{ variant: "error" }
			);
		}
		setBusy(false);
	}

	/**
	 * Called by Unlock Funds button
	 */
	async function withdraw() {
		setBusy(true);
		try {
			// const SENTINEL_HIGH_BITS =
			// 	"0xF000000000000000000000000000000000000000000000000000000000000000";

			// const RAIN_FLOW_SENTINEL = ethers.BigNumber.from(
			// 	keccak256([...Buffer.from("RAIN_FLOW_SENTINEL")])
			// ).or(SENTINEL_HIGH_BITS);

			const contractCfg = AppConfig.contractConfig.find(
				(c) => c.name === invoice.network
			);

			if (!contractCfg) {
				throw new Error("Invalid network selected.");
			}

			// Get the token address
			const tokenInfo = contractCfg.tokens.find(
				(t) => t.name === invoice.currency
			);

			// Hash invoice
			const contextWithdraw = [
				// Client
				invoice.payerAddress,
				// Owner
				invoice.payeeAddress,
				// Amount
				fiatToCoin(invoice.total, tokenInfo!).toString(),
				// Date
				invoice!.endDate!.toString(),
			];
			const invoiceHash = solidityKeccak256(
				["uint256[]"],
				[contextWithdraw]
			);
			const signedContext: any[] = [
				{
					signature: invoice.signature,
					signer: invoice.payerAddress,
					context: [invoiceHash],
				},
			];
			const flow = await new ethers.Contract(
				invoice.paymentId!,
				AppConfig.flowAbi,
				signer.data!
			);

			const tx = await flow
				.connect(signer.data!)
				.functions.flow(
					invoice.dispatchWithdraw,
					contextWithdraw,
					signedContext
				);
			enqueueSnackbar("Waiting for transaction.", { variant: "info" });
			await tx.wait();

			enqueueSnackbar(
				"Success. The funds should reach your account in a few moments.",
				{ variant: "success" }
			);

			invoice.status = INVOICE_STATUS.COMPLETE;
			invoice.dateCompleted = new Date().getTime();
			invoice.dateUpdated = new Date().getTime();
			const inv = await FbSetInvoice(invoice);
			setInvoice(inv);
			const cli = await FbGetClient(invoice!.client.id.toString());
			if (cli) {
				cli.status = invoice!.status;
				cli.dateUpdated = new Date().getTime();
				await FbSetClient(cli);
			}

			// Add transaction
			// Add transaction to the database for contractor
			const trx: Transaction = {
				clientName: invoice!.client.name,
				clientId: invoice!.client.id,
				invoiceName: invoice!.name,
				amount: invoice!.total,
				invoiceId: invoice!.id!,
				type: "withdraw",
				time: new Date().getTime(),
				from: invoice!.paymentId!,
				to: invoice!.payeeAddress!,
				id: tx.hash,
				uid: invoice!.ownerId,
			};
			// add the tx to db
			await FbSetTransaction(trx);
		} catch (err: any) {
			enqueueSnackbar(
				"Error withdrawing. Please check logs for details.",
				{
					variant: "error",
				}
			);
			console.log(err);
		}
		setBusy(false);
	}

	// Called when Submit button is clicked on mediation form.
	async function openDispute() {
		try {
			setBusy(true);
			// Set the status to dispute
			invoice.status = INVOICE_STATUS.DISPUTE;
			invoice.dateDispute = new Date().getTime();
			invoice.dateUpdated = new Date().getTime();
			invoice.timeline = [
				...(invoice.timeline ? invoice.timeline : []),
				{
					actorKind: InvoiceActorKind.Contractor,
					kind: InvoiceActionKind.Dispute,
					time: new Date().getTime(),
					details:
						"Contractor requested mediation. Reason: " +
						invoice.reason,
				},
			];
			// Add mediation info
			invoice.mediation = {
				dateRequested: new Date().getTime(),
				desiredOutcome: desiredOutcomes[desiredOutcome][1],
				initiatedBy: "contractor",
				invoiceId: invoice.id,
				status: MediationStatus.Pending,
			};
			// Save
			const inv = await FbSetInvoice(invoice);
			setInvoice(inv);
			// Send email in prodc
			if (AppConfig.mode === "PRODUCTION") {
				await SendInvoice(inv!.id!);
			}
			enqueueSnackbar(
				"Mediation has been requested. You will be notified via email if a decision has been made.",
				{ variant: "success" }
			);
		} catch (err: any) {
			enqueueSnackbar(
				"Error saving invoice, please contact administrators.",
				{
					variant: "error",
				}
			);
			console.log(err);
		}
		setBusy(false);
	}

	async function resendReviewEmail() {
		try {
			setBusy(true);
			await SendInvoice(invoice!.id!);
			enqueueSnackbar("Email sent successfully.", { variant: "success" });
		} catch (err: any) {
			enqueueSnackbar(
				"Error sending email. Please contact administrators.",
				{
					variant: "error",
				}
			);
			console.log(err);
		}
		setBusy(false);
	}

	async function openTxExplorer() {
		// Determine the network of the invoice and open block explorer. For now, let's use Polygon
		window.open(
			chain?.blockExplorers?.default.url + "/tx/" + invoice.paymentHash,
			"_blank"
		);
	}

	function RenderWorkItemButton(num: number) {
		return (
			<Button
				variant="text"
				sx={{
					color: "#000",
					fontWeight: "bold",
					px: "32px",
					borderRadius: 0,
					borderBottom:
						selectedItem === num ? "4px solid #A882F3" : "0px",
				}}
				onClick={() => setSelectedItem(num)}
			>
				Item # {num + 1}
			</Button>
		);
	}

	const triggerPrint = useReactToPrint({
		content: () => elementRef.current,
		copyStyles: true,
		//pageStyle: "font-family: 'Inter'",
		onBeforeGetContent: () => {
			setBusy(true);
		},
		onAfterPrint: () => {
			// Reset the Promise resolve so we can print again
			setBusy(false);
		},

		async print(target: any) {
			await printInvoice((invoice.name || invoice.id) + ".pdf", target);
		},
	});

	const elementRef = useRef<any>();
	function InvoiceDetails() {
		return (
			<Stack sx={{ width: "100%" }} spacing={"10px"}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography
						sx={{ cursor: "pointer" }}
						onClick={() =>
							setInvoiceDetailsVisible(!invoiceDetailsVisible)
						}
						fontSize={15}
						fontWeight="bold"
					>
						{isInvoice(invoice!)
							? "Invoice Details"
							: "Proposal Details"}{" "}
						{invoiceDetailsVisible ? "-" : "+"}
					</Typography>
					<Button disabled={busy} onClick={triggerPrint}>
						{invoice?.dispatchWithdraw
							? "Download Invoice"
							: "Download Proposal"}
					</Button>
				</Stack>
				{invoiceDetailsVisible && (
					<>
						<Stack direction="row" spacing={"10px"}>
							<PayantTextField
								label={
									isInvoice(invoice!)
										? "Invoice's Name"
										: "Proposal Name"
								}
								value={invoice.name}
								sx={{ flex: 1 }}
							/>
							<PayantTextField
								label="Client's Name"
								value={invoice.client.name}
								sx={{ flex: 1 }}
							/>
						</Stack>
						<Stack direction="row" spacing={"10px"}>
							<PayantTextField
								label="Amount"
								value={Moneyfy(invoice.total)}
								sx={{ flex: 1 }}
							/>
							<PayantTextField
								label="Deadline"
								value={moment(new Date(invoice.endDate)).format(
									"DD/MM/YYYY"
								)}
								sx={{ flex: 1 }}
							/>
						</Stack>
					</>
				)}
				<div
					style={{
						position: "absolute",
						overflow: "hidden",
						height: 0,
						width: 0,
						zIndex: -999,
					}}
				>
					<div ref={elementRef}>
						<PrintableInvoice invoice={invoice} />
					</div>
				</div>
			</Stack>
		);
	}

	function isStepValid(step: number) {
		switch (step) {
			case 2: {
				if (invoice.currency && invoice.network) return true;
				return false;
			}
			case 4: {
				// Upload files
				// Make sure all items has link value
				for (let i of invoice.items) {
					if (!i.link) {
						return false;
					}
				}
				return true;
			}
		}
		return false;
	}

	function getItemFeedback() {
		const item = invoice?.items[selectedItem].name;
		if (item) {
			const fb = Object.entries(invoice.quote?.feedback || []).find(
				(f) => f[0] === item
			);
			return fb?.[1].feedback;
		}
		return undefined;
	}

	function getMediationHeadline() {
		switch (invoice.mediation?.status) {
			case MediationStatus.Pending:
				return "Mediation In Progress";
			case MediationStatus.Escalated:
				return "Escalated to Decentralized Dispute";
			case MediationStatus.Implementing:
				return "Implementing Mediation";
			case MediationStatus.Resolved:
				return "Implementing Mediation";
		}
	}

	React.useEffect(() => {
		if (invoice.status === INVOICE_STATUS.DISPUTE) {
			loadDisputeInfo();
		}
	});

	// Shown from multiple statuses.
	if (showUploadScreen) {
		return (
			<Stack
				spacing={"15px"}
				alignItems="center"
				sx={{
					px: "30px",
					background: "#FFF",
					pt: "10px",
					pb: "24px",
					width: "100%",
				}}
			>
				<Typography color="primary" fontSize={"22px"} fontWeight="bold">
					Share Deliverables
				</Typography>
				<Stack direction="row" spacing={"10px"} sx={{ width: "100%" }}>
					<PayantTextField
						label="Invoice's Name"
						value={invoice.name}
						sx={{ flex: 1 }}
					/>
					<PayantTextField
						label="Client's Name"
						value={invoice.client.name}
						sx={{ flex: 1 }}
					/>
				</Stack>

				{/* Loop through each work item, and show item button */}
				<List component={"nav"} sx={{ width: "100%" }}>
					{invoice?.items.map((i, j) => RenderWorkItemButton(j))}
				</List>
				{/* The link to the selected item  */}
				<PayantTextField
					label="Description of Deliverables"
					multiline
					rows={5}
					maxRows={5}
					required
					value={
						invoice.items[selectedItem].deliveryDescription || ""
					}
					onUpdate={(txt) => {
						invoice.items[selectedItem].deliveryDescription = txt;
						setInvoice({ ...(invoice as any) });
					}}
					fullWidth
				/>
				<PayantTextField
					label="Link to Deliverables (optional)"
					value={invoice.items[selectedItem].link || ""}
					onUpdate={(txt) => {
						invoice.items[selectedItem].link = txt;
						setInvoice({ ...(invoice as any) });
					}}
					fullWidth
				/>

				{invoice?.items.length && invoice.items.length > 1 && (
					<Stack
						direction="row"
						alignItems={"center"}
						justifyContent="center"
					>
						<Tooltip title="Previous item">
							<IconButton
								disabled={selectedItem === 0}
								onClick={() =>
									setSelectedItem(selectedItem - 1)
								}
							>
								<ArrowBackRounded />
							</IconButton>
						</Tooltip>
						<Tooltip title="Next item">
							<IconButton
								onClick={() =>
									setSelectedItem(selectedItem + 1)
								}
								disabled={
									selectedItem + 1 >=
									(invoice?.items.length || 1)
								}
							>
								<ArrowForwardRounded />
							</IconButton>
						</Tooltip>
					</Stack>
				)}

				<Typography sx={{ cursor: "pointer", fontSize: "12px" }}>
					How to share deliverables for approval
				</Typography>

				<Button
					variant="contained"
					fullWidth
					sx={{ borderRadius: "25px" }}
					disabled={busy || !isStepValid(4)}
					onClick={() =>
						saveInvoice().then(() => setShowUploadScreen(false))
					}
				>
					{busy && <LoadingIndicator height={"30px"} />}
					{!busy && "Share Deliverables"}
				</Button>
			</Stack>
		);
	}

	// Step 1: Waiting for review.
	if (
		invoice.status === INVOICE_STATUS.REVIEW ||
		invoice.status === INVOICE_STATUS.REVIEWED
	) {
		return (
			<Stack
				spacing={"15px"}
				alignItems="center"
				sx={{
					px: "35px",
					background: "#FFF",
					py: "10px",
					pb: "24px",
				}}
			>
				<img
					src={PENDING_PURPLE}
					width="42px"
					height="42px"
					alt="Waiting Approval"
				/>
				<Typography
					color="primary"
					fontSize={"22px"}
					fontWeight="bold"
					textAlign={"center"}
				>
					Proposal Awaiting Client Approval
				</Typography>
				<Typography
					fontSize={"15px"}
					color="#8B8B8B"
					fontWeight="medium"
				>
					Your Smart Invoice for <b>{invoice.client.name}</b> has been
					sent and is awaiting review. <br />
					We will let you know as soon as your client has approved it.
				</Typography>
				<InvoiceDetails />
				<Button
					variant="contained"
					fullWidth
					sx={{ borderRadius: "25px" }}
					disabled={busy}
					onClick={resendReviewEmail}
				>
					Resend Email
				</Button>
			</Stack>
		);
	}

	// Step 1: Proposal Refused, Feedback Received.
	if (invoice.status === INVOICE_STATUS.REFUSED) {
		// Show Feedback ?
		if (showFeedbackScreen) {
			return (
				<Stack
					spacing={"15px"}
					alignItems="center"
					sx={{
						px: "30px",
						background: "#FFF",
						pt: "10px",
						pb: "24px",
					}}
				>
					<Typography
						color="primary"
						fontSize={"22px"}
						fontWeight="bold"
						textAlign={"center"}
					>
						Feedback Shared
					</Typography>
					<Typography
						fontSize={"15px"}
						color="#8B8B8B"
						fontWeight="medium"
					>
						The client did not accept the proposal and has shared a
						feedback. Please review the feedback and update the
						proposal.
					</Typography>
					{/* Each item has its own feedback */}
					<Stack
						direction={"row"}
						justifyContent={"start"}
						sx={{ width: "100%" }}
					>
						{invoice?.items.map((item, ind) =>
							RenderWorkItemButton(ind)
						)}
					</Stack>
					<PayantTextField
						fullWidth
						value={getItemFeedback()}
						label="Feedback"
						multiline
						rows={8}
					/>
					<Stack
						direction="row"
						justifyContent="center"
						alignItems="center"
						spacing={"10px"}
						sx={{ width: "100%" }}
					>
						<Button
							variant="outlined"
							sx={{
								borderRadius: "25px",
								width: "200px",
							}}
							onClick={() => setShowFeedbackScreen(false)}
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							sx={{
								borderRadius: "25px",
								width: "200px",
							}}
							onClick={() => {
								navigate("/portal/invoices/create", {
									state: {
										invoice: invoice,
									},
								});
								props.closeHandler();
							}}
						>
							Update Proposal
						</Button>
					</Stack>
				</Stack>
			);
		} else {
			return (
				<Stack
					spacing={"15px"}
					alignItems="center"
					sx={{
						px: "30px",
						background: "#FFF",
						pt: "10px",
						pb: "24px",
					}}
				>
					<Typography
						color="primary"
						fontSize={"22px"}
						fontWeight="bold"
						textAlign={"center"}
					>
						Feedback Shared
					</Typography>
					<Typography
						fontSize={"15px"}
						color="#8B8B8B"
						fontWeight="medium"
					>
						The client did not accept the proposal and has shared a
						feedback. Please review the feedback and update the
						proposal.
					</Typography>
					<InvoiceDetails />
					<Button
						variant="contained"
						fullWidth
						sx={{ borderRadius: "25px" }}
						onClick={() => {
							setShowFeedbackScreen(true);
						}}
					>
						See Feedback
					</Button>
				</Stack>
			);
		}
	}

	// Step 2: Proposal Approved, Deploy Invoice
	if (invoice.status === INVOICE_STATUS.VALIDATED) {
		return (
			<Stack
				spacing={"15px"}
				alignItems="center"
				sx={{
					px: "35px",
					background: "#FFF",
					pt: "10px",
					pb: "24px",
				}}
			>
				<img
					src={CHECKMARK_PURPLE}
					width="42px"
					height="42px"
					alt="Deploy Invoice"
				/>
				<Typography
					color="primary"
					fontSize={"22px"}
					fontWeight="bold"
					textAlign={"center"}
				>
					Proposal Approved - Create Invoice
				</Typography>
				<Typography
					fontSize={"15px"}
					color="#8B8B8B"
					fontWeight="medium"
				>
					To create your Smart Invoice, select which currency you wish
					to be paid in and the network (blockchain) you want to use.
				</Typography>
				{/* <InvoiceDetails /> */}
				<FormControl variant="filled" required fullWidth>
					<InputLabel>Currency</InputLabel>
					<Select
						required
						label="Currency"
						onChange={(c) =>
							setInvoice({
								...(invoice as any),
								currency: currencies[c.target.value as number],
							})
						}
						defaultValue={-1}
						value={currencies.indexOf(invoice.currency)}
						IconComponent={KeyboardArrowDown}
					>
						<MenuItem key={-1} value={-1} disabled>
							Select Currency...
						</MenuItem>
						{currencies.map((c, i) => (
							<MenuItem key={i} value={i}>
								{c.toUpperCase()}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl variant="filled" required fullWidth>
					<InputLabel>Network</InputLabel>
					<Select
						required
						label="Network"
						onChange={(c) =>
							setInvoice({
								...(invoice as any),
								network: networks[c.target.value as number],
							})
						}
						defaultValue={-1}
						value={networks.indexOf(invoice.network)}
						IconComponent={KeyboardArrowDown}
					>
						<MenuItem key={-1} value={-1} disabled>
							Select Network...
						</MenuItem>
						{networks.map((c, i) => (
							<MenuItem key={i} value={i}>
								{c.toUpperCase()}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Typography textAlign={"center"}>
					Not sure what to select?{" "}
					<a href="https://payant.io/">Check our guide</a>
				</Typography>
				<Button
					variant="contained"
					fullWidth
					sx={{ borderRadius: "25px", mt: "34px" }}
					disabled={busy || !isStepValid(2)}
					onClick={deployInvoice}
				>
					{busy && "Creating Invoice, Please hold-on." && (
						<LoadingIndicator height={"30px"} />
					)}
					{!busy && "Create Invoice"}
				</Button>
			</Stack>
		);
	}

	// Step 3: Invoice is unpaid, but deployed.
	if (
		invoice.status === INVOICE_STATUS.CREATED ||
		invoice.status === INVOICE_STATUS.UNPAID
	) {
		return (
			<Stack
				spacing={"15px"}
				alignItems="center"
				sx={{
					px: "35px",
					background: "#FFF",
					pt: "10px",
					pb: "24px",
				}}
			>
				<img
					src={PENDING_PURPLE}
					width="42px"
					height="42px"
					alt="Invoice Pending"
				/>
				<Typography
					color="primary"
					fontSize={"22px"}
					fontWeight="bold"
					textAlign={"center"}
				>
					Invoice Awaiting Payment
				</Typography>
				<Typography
					fontSize={"15px"}
					color="#8B8B8B"
					fontWeight="medium"
				>
					Your Smart Invoice for <b>{invoice.client.name}</b> has been
					sent and a copy has been emailed to you. <br />
					<br />
					We'll let you know as soon as the payment is locked in
					Payant.
				</Typography>
				<InvoiceDetails />
			</Stack>
		);
	}

	// Step 4: Invoice is paid, user can upload files.
	if (invoice.status === INVOICE_STATUS.PAID) {
		return (
			<Stack
				spacing={"15px"}
				alignItems="center"
				sx={{
					px: "30px",
					background: "#FFF",
					pt: "10px",
					pb: "24px",
					width: "100%",
				}}
			>
				<img
					src={CHECKMARK_PURPLE}
					width="39px"
					height="39px"
					alt="Invocie Paid"
				/>
				<Typography
					color="primary"
					fontSize={"22px"}
					fontWeight="bold"
					textAlign={"center"}
				>
					Invoice Paid - Work in Progress
				</Typography>
				<Alert color="success" sx={{ width: "100%" }}>
					<Typography fontSize={12}>
						Submitted by{" "}
						<b
							onClick={openTxExplorer}
							style={{
								textDecoration: "underline",
								cursor: "pointer",
							}}
						>
							{invoice.payerAddress}
						</b>
						<br />
						<b>
							{moment(new Date(invoice.datePaid!)).format(
								"DD/MM/YYYY HH:mm:ss"
							)}
						</b>
					</Typography>
				</Alert>
				<Typography
					fontSize={"15px"}
					color="#373737"
					fontWeight="medium"
				>
					Your Smart Invoice of {Moneyfy(invoice.total)} has been paid
					by <b>{invoice.client.name}</b>. You can now upload the
					deliverables as soon as you are ready!
				</Typography>

				<InvoiceDetails />

				<Button
					fullWidth
					variant="contained"
					sx={{
						borderRadius: "25px",
						height: "44px",
					}}
					onClick={() => setShowUploadScreen(true)}
				>
					Share Deliverables
				</Button>
			</Stack>
		);
	}

	// Step 4: Show dispute form, shown in multiple statuses (rejected, paid, etc.)
	if (invoice.status === INVOICE_STATUS.REJECTED && showDisputeForm) {
		return (
			<Stack
				spacing={"15px"}
				alignItems="center"
				sx={{
					px: "30px",
					background: "#FFF",
					pt: "10px",
					pb: "24px",
				}}
			>
				<Typography
					color="primary"
					fontSize={"22px"}
					fontWeight="bold"
					textAlign={"center"}
				>
					Mediation Request Form
				</Typography>
				<Typography
					fontSize={"15px"}
					color="#8B8B8B"
					fontWeight="medium"
				>
					You have denied the feedback from {invoice.client.name},
					please fill in this form to request Payant Mediation
				</Typography>
				<Stack sx={{ width: "100%" }}>
					<FormControl
						fullWidth
						variant="filled"
						required
						id="prop_outcome"
					>
						<InputLabel>Desired Outcome</InputLabel>
						<Select
							required
							label="Desired Outcome"
							sx={{ mt: "5px", mb: "18px" }}
							onChange={(c) => {
								setDesiredOutcome(
									parseInt(c.target.value.toString())
								);
							}}
							defaultValue={-1}
							value={desiredOutcome}
							IconComponent={KeyboardArrowDown}
						>
							<MenuItem key={-1} value={-1} disabled>
								Select your desired outcome...
							</MenuItem>
							{desiredOutcomes.map((doc, ind) => (
								<MenuItem key={ind} value={ind}>
									{doc[0]}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<PayantTextField
						fullWidth
						value={mediationReason || ""}
						onUpdate={(t) => setMediationReason(t)}
						label="Reason"
						placeholder="Please describe your complain. Share as much details as possible."
						multiline
						rows={8}
						maxRows={8}
					/>
				</Stack>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={"10px"}
					sx={{ width: "100%" }}
				>
					<Button
						variant="outlined"
						sx={{
							borderRadius: "25px",
							width: "200px",
						}}
						onClick={() => setShowDisputeForm(false)}
					>
						Cancel
					</Button>

					<Button
						disabled={desiredOutcome === -1 || !mediationReason}
						variant="contained"
						sx={{
							borderRadius: "25px",
							width: "200px",
						}}
						onClick={() => openDispute()}
					>
						Submit
					</Button>
				</Stack>
			</Stack>
		);
	}

	// Step 4: Deliverables rejected, feedback is shown.
	if (invoice.status === INVOICE_STATUS.REJECTED) {
		return (
			<Stack
				spacing={"15px"}
				alignItems="center"
				sx={{
					px: "30px",
					background: "#FFF",
					pt: "10px",
					pb: "24px",
				}}
			>
				<Typography
					color="primary"
					fontSize={"22px"}
					fontWeight="bold"
					textAlign={"center"}
				>
					Feedback Shared
				</Typography>
				<Typography
					fontSize={"15px"}
					color="#8B8B8B"
					fontWeight="medium"
				>
					You have 7 days to approve the feedback from{" "}
					{invoice.client.name}. If you approve it, you will have 30
					days to proceed with the revision. If you deny it, the
					decentralized mediation will start.
				</Typography>
				{/* Each item has its own feedback */}
				<Stack
					direction={"row"}
					justifyContent={"start"}
					sx={{ width: "100%" }}
				>
					{invoice?.items.map((item, ind) =>
						RenderWorkItemButton(ind)
					)}
				</Stack>
				<PayantTextField
					fullWidth
					value={getItemFeedback()}
					label="Feedback"
					multiline
					rows={8}
				/>
				<Stack
					spacing={"18px"}
					direction={"row"}
					justifyContent={"center"}
				>
					<Button
						variant="contained"
						sx={{
							borderRadius: "25px",
							height: "44px",
							width: "200px",
							alignSelf: "center",
						}}
						color="error"
						onClick={() => setShowDisputeForm(true)}
					>
						Deny
					</Button>
					<Button
						color="secondary"
						variant="contained"
						fullWidth
						sx={{
							color: "white",
							borderRadius: "25px",
							height: "44px",
							width: "200px",
						}}
						onClick={() => setShowUploadScreen(true)}
					>
						Approve
					</Button>
				</Stack>
			</Stack>
		);
	}

	// Step 5: Invoice is delivered, waiting for approval.
	if (invoice.status === INVOICE_STATUS.DELIVERED) {
		return (
			<Stack
				spacing={"15px"}
				alignItems="center"
				sx={{
					px: "30px",
					background: "#FFF",
					pt: "10px",
					pb: "24px",
				}}
			>
				<img
					src={DOCUMENT_PURPLE}
					width="39px"
					height="39px"
					alt="Under review"
				/>
				<Typography
					color="primary"
					fontSize={"22px"}
					fontWeight="bold"
					textAlign={"center"}
				>
					Deliverables Under Review
				</Typography>
				<Typography
					fontSize={"15px"}
					color="#8B8B8B"
					fontWeight="medium"
				>
					The deliverables are being reviewed by{" "}
					<b>{invoice.client.name}</b>. They have until{" "}
					<b>{moment().add("1", "month").format("DD/MM/YYYY")}</b> to
					review them. <br />
					<br />
					Passed this period, the funds will be automatically
					unlocked.
					<br />{" "}
				</Typography>
				<InvoiceDetails />
			</Stack>
		);
	}

	// Step 5: Invoice is in dispute
	if (invoice.status === INVOICE_STATUS.DISPUTE) {
		return (
			<Stack
				spacing={"15px"}
				alignItems="center"
				sx={{
					px: "30px",
					background: "#FFF",
					pt: "10px",
					pb: "24px",
				}}
			>
				<img
					src={DOCUMENT_PURPLE}
					width="39px"
					height="39px"
					alt="Dispute In Progress"
				/>
				<Typography
					color="primary"
					fontSize={"22px"}
					fontWeight="bold"
					textAlign={"center"}
				>
					{getMediationHeadline()}
				</Typography>
				<Typography
					fontSize={"15px"}
					color="#8B8B8B"
					fontWeight="medium"
				>
					The invoice {invoice.name} is now in Mediation. Your client
					or you have requested Payant’s help to mediate the payment
					of your invoice. You can now fill the form below to share
					with us more elements allowing us to mediate your case.
					Learn more about Payant Mediation.
				</Typography>
				<InvoiceDetails />
			</Stack>
		);
	}

	// Step 6: Delivery is approved, user can withdraw or has withdrawn already.
	if (
		invoice.status === INVOICE_STATUS.APPROVED ||
		invoice.status === INVOICE_STATUS.COMPLETE
	) {
		return (
			<Stack
				spacing={"15px"}
				alignItems="center"
				sx={{
					px: "30px",
					background: "#FFF",
					pt: "10px",
					pb: "24px",
				}}
			>
				<img
					src={CHECKMARK_PURPLE}
					width="39px"
					height="39px"
					alt="You've been paid!"
				/>
				<Typography
					color="primary"
					fontSize={"22px"}
					fontWeight="bold"
					textAlign={"center"}
				>
					{invoice.status === INVOICE_STATUS.APPROVED
						? "Funds Ready to Withdraw"
						: "Completed!"}
				</Typography>
				<Typography
					fontSize={"15px"}
					color="#8B8B8B"
					fontWeight="medium"
				>
					The deliverables have been successfully reviewed by{" "}
					<b>{invoice.client.name}</b>. The funds have been unlocked
					and you can now withdraw them.
				</Typography>
				<InvoiceDetails />
				{/* Withdraw button is only shown in step 6  */}
				{invoice.status === INVOICE_STATUS.APPROVED && (
					<Button
						variant="contained"
						color="primary"
						fullWidth
						disabled={busy}
						onClick={withdraw}
						sx={{
							marginTop: "40px",
							borderRadius: "25px",
						}}
					>
						{busy && <LoadingIndicator height={"30px"} />}
						{!busy && "Withdraw"}
					</Button>
				)}
			</Stack>
		);
	}

	return <></>;
}

export default function InvoiceDialog(props: BasicDialogProps) {
	const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

	const invoice = props.data;

	function getInvoiceStep() {
		switch (invoice.status) {
			case INVOICE_STATUS.REVIEW:
			case INVOICE_STATUS.REVIEWED:
			case INVOICE_STATUS.REFUSED:
				return 1;
			case INVOICE_STATUS.VALIDATED:
				return 2;
			case INVOICE_STATUS.CREATED:
			case INVOICE_STATUS.UNPAID:
				return 3;
			case INVOICE_STATUS.PAID:
				return 4;
			case INVOICE_STATUS.REJECTED:
			case INVOICE_STATUS.DISPUTE:
			case INVOICE_STATUS.DELIVERED:
				return 5;
			case INVOICE_STATUS.APPROVED:
				return 6;
			case INVOICE_STATUS.COMPLETE:
				return 7;
		}
	}

	const step = getInvoiceStep();

	return (
		<Dialog
			PaperComponent={TransparentPaper}
			open={props.isOpen}
			sx={{ backdropFilter: "blur(2px)" }}
			onClose={props.closeHandler}
			fullWidth={isDesktop ? false : true}
		>
			<DialogContent
				sx={{ p: 0, ...(isDesktop ? { width: "466px" } : {}) }}
			>
				<Stack
					key={step || 0}
					sx={{
						width: "100%",
					}}
				>
					<Stack
						direction="row"
						sx={{
							width: "100%",
							position: "absolute",
							p: isDesktop ? "15px" : "0px",
							mr: isDesktop ? 0 : "-12px",
							mt: isDesktop ? 0 : "-10px",
						}}
						justifyContent="end"
					>
						<Tooltip title="Close">
							<IconButton onClick={props.closeHandler}>
								<CloseSharp color="action" />
							</IconButton>
						</Tooltip>
					</Stack>
					<Stack
						sx={{
							background: "#FFF",
							width: "100%",
						}}
					>
						<PayantStepper
							sx={{
								px: isDesktop ? "45px" : "8px",
								pb: "15px",
								pt: "26px",
							}}
							stepsCount={6}
							active={step || 1}
						/>

						<GetDialogContent
							invoice={props.data}
							closeHandler={props.closeHandler}
						/>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}
