import {
	Alert,
	Button,
	FormControl,
	IconButton,
	InputLabel,
	List,
	MenuItem,
	Paper,
	Select,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
	CHECKMARK_PURPLE,
	DOCUMENT_PURPLE,
	PAYANT_LOGO_TRANSPARENT,
	PENDING_PURPLE,
} from "../assets/assets";
import PayantTextField from "../components/textfield";
import { useEffect, useState, useRef } from "react";
import { useSnackbar } from "notistack";
import {
	FbGetClient,
	FbGetProfile,
	FbSetClient,
	FbSetInvoice,
	FbSetTransaction,
} from "../core/firebase";
import PayantStepper from "../components/stepper";
import { INVOICE_STATUS, Invoice, MediationStatus } from "payant-lib";
import moment from "moment";
import { erc20ABI, useAccount, useNetwork, useSigner } from "wagmi";
import { AppConfig } from "../config";
import { arrayify, solidityKeccak256 } from "ethers/lib/utils.js";
import {
	ArrowBackRounded,
	ArrowForwardRounded,
	FileDownload,
	KeyboardArrowDown,
} from "@mui/icons-material";
import { useChainModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { ethers } from "ethers";
import {
	fiatToCoin,
	GetInvoiceStatus,
	isQuote,
	Moneyfy,
	printInvoice,
	ShortifyAddress,
	viewTransaction,
} from "../core/helper";
import { Transaction } from "payant-lib";
import { RestGetInvoice, SendInvoice } from "../core/api";
import PrintableInvoice from "../components/printableInvoice";
import { useReactToPrint } from "react-to-print";
import {
	InvoiceActionKind,
	InvoiceActorKind,
} from "payant-lib/dist/models/Invoice";
import { ClientOutcomes } from "../models/ClientOutcomes";

export default function PageInvoice() {
	const [loading, setLoading] = useState(false);
	const [busy, setBusy] = useState(false);
	const [paymentSuccess, setPaymentSuccess] = useState(false);
	const [invoice, setInvoice] = useState<Invoice>();
	const [selectedItem, setSelectedItem] = useState(0);
	const loc = useLocation();
	const params = new URLSearchParams(loc.search);
	const invoiceId = params.get("id");
	const [screen, setScreen] = useState<
		| "welcome"
		| "review"
		| "validated"
		| "paid"
		| "delivered"
		| "approved"
		| "feedback"
		| "feedback_sent"
		| "dispute"
		| "dispute_request"
	>("welcome");

	const [desiredOutcome, setDesiredOutcome] = useState<number>(-1);
	const [mediationReason, setMediationReason] = useState("");

	const desiredOutcomes = Object.entries(ClientOutcomes);
	// For redirecting
	const nav = useNavigate();

	const signer = useSigner();
	const rainbow = useConnectModal();
	const account = useAccount();
	const { chain } = useNetwork();
	const { openChainModal } = useChainModal();

	async function loadInvoice() {
		setLoading(true);
		try {
			const inv = await RestGetInvoice(invoiceId!);
			if (inv) {
				setInvoice(inv);
			} else {
				throw new Error("Try again or contact administrator.");
			}
			// Set screen based on status
			switch (inv.status) {
				case INVOICE_STATUS.REVIEW: {
					setScreen("welcome");
					// mark it as viewed if ref=cm
					if (params.has("ref") && params.get("ref") === "cm") {
						// Mark the invoice as revied.
						inv.status = INVOICE_STATUS.REVIEWED;
						inv.dateUpdated = new Date().getTime();
						inv.reviewedAt = new Date().getTime();
						const i = await FbSetInvoice(inv);
						setInvoice(i);
					}
					break;
				}
				case "approved": {
					setScreen("approved");
					break;
				}
				case "delivered": {
					setScreen("delivered");
					break;
				}
				case "paid": {
					setScreen("paid");
					break;
				}
				case "validated": {
					setScreen("validated");
					break;
				}
				case "created": {
					// If ref=cm, we set it to 'viewed'
					if (params.has("ref") && params.get("ref") === "cm") {
						// Mark the invoice as revied.
						inv.status = INVOICE_STATUS.UNPAID;
						inv.dateUpdated = new Date().getTime();
						inv.viewedAt = new Date().getTime();
						const i = await FbSetInvoice(inv);
						setInvoice(i);
					}
					setScreen("review");
					break;
				}
				case "unpaid": {
					setScreen("review");
					break;
				}
				case "complete": {
					setScreen("approved");
					break;
				}
				case INVOICE_STATUS.REJECTED: {
					setScreen("feedback_sent");
					break;
				}
				case INVOICE_STATUS.DISPUTE: {
					setScreen("dispute");
					break;
				}
				case INVOICE_STATUS.REFUSED: {
					setScreen("feedback_sent");
					break;
				}
				default: {
					setScreen("welcome");
					break;
				}
			}

			loadSupport(inv);
		} catch (err: any) {
			enqueueSnackbar("Error loading invoice. ", { variant: "error" });
			console.log(err);
		}
		setLoading(false);
	}

	/**
	 * Sends the funds to the Flow contract of invoice.
	 * @returns
	 */
	async function lockFunds() {
		if (!invoice) {
			return;
		}
		if (!account.isConnected) {
			rainbow?.openConnectModal?.();
			return;
		}
		// Make sure we are on same network as invoice
		if (chain && chain.name.toLowerCase() !== invoice?.network) {
			// Trigger chain selectioin
			openChainModal?.();
			return;
		}
		try {
			// Get the contract config
			const contractCfg = AppConfig.contractConfig.find(
				(c) => c.name === invoice.network
			);

			if (!contractCfg) {
				throw new Error("Invalid network selected.");
			}

			// Get the token address
			const tokenInfo = contractCfg.tokens.find(
				(t) => t.name === invoice.currency
			);
			if (!tokenInfo) {
				throw new Error("Invalid token selected.");
			}
			// Send the USDCC
			const erc20 = await new ethers.Contract(
				tokenInfo!.address,
				erc20ABI,
				signer!.data!
			).deployed();
			const rx = await erc20
				.connect(signer!.data!)
				.functions.transfer(
					invoice!.paymentId,
					fiatToCoin(invoice!.total, tokenInfo!)
				);

			enqueueSnackbar("Processing funds...");
			// Update invoice
			invoice!.paymentHash = rx.hash;
			invoice!.payerAddress = account!.address!;
			invoice!.dateUpdated = new Date().getTime();
			invoice!.datePaid = new Date().getTime();
			invoice!.status = "paid";

			const inv = await FbSetInvoice(invoice!);

			setInvoice(inv);

			// Update the client: set awaitingPayment and paid
			const cli = await FbGetClient(inv.client.id.toString());
			if (cli) {
				cli.awaitingPayment -= inv.total;
				cli.paid += inv.total;
				cli.status = inv.status;
				await FbSetClient(cli);
			}

			// Add transaction to the database for contractor
			const tx: Transaction = {
				invoiceName: invoice!.name,
				clientId: invoice!.client.id,
				clientName: invoice!.client.name,
				amount: invoice!.total,
				invoiceId: invoice!.id!,
				type: "deposit",
				time: new Date().getTime(),
				from: invoice!.payerAddress!,
				to: invoice!.payeeAddress!,
				id: rx.hash,
				uid: invoice!.ownerId,
			};
			// add the tx to db
			await FbSetTransaction(tx);

			// Send email
			await SendInvoice(invoice!.id!);

			setScreen("paid");

			setPaymentSuccess(true);
			enqueueSnackbar("Funds sent.", { variant: "success" });
		} catch (err: any) {
			enqueueSnackbar(
				"Error processing your request. Please contact administrators.",
				{ variant: "error" }
			);
			console.log(err);
		}
		setLoading(false);
	}

	/**
	 * Called by Unlock button. Signs a message and stores it in the invoice.
	 */
	async function unlockFunds() {
		if (!invoice) {
			return;
		}
		if (!account.isConnected) {
			rainbow?.openConnectModal?.();
			return;
		}
		// Make sure we are on same network as invoice
		if (chain && chain.name.toLowerCase() !== invoice?.network) {
			// Trigger chain selectioin
			openChainModal?.();
			return;
		}
		// Get the contract config
		const contractCfg = AppConfig.contractConfig.find(
			(c) => c.name === invoice.network
		);

		if (!contractCfg) {
			throw new Error("Invalid network selected.");
		}

		// Get the token address
		const tokenInfo = contractCfg.tokens.find(
			(t) => t.name === invoice.currency
		);
		setLoading(true);
		try {
			const invoiceHash = solidityKeccak256(
				["uint256[]"],
				[
					[
						// Client address
						account.address,
						// Contractor address
						invoice!.payeeAddress,
						// Total
						fiatToCoin(invoice.total, tokenInfo!).toString(),
						// End date
						invoice!.endDate!.toString(),
					],
				]
			);
			const msgHash = solidityKeccak256(["uint256[]"], [[invoiceHash]]);
			const signedMessage = await signer!.data!.signMessage(
				arrayify(msgHash)
			);
			// Store the msg in invoice
			invoice!.signature = signedMessage;
			invoice!.status = INVOICE_STATUS.APPROVED;
			invoice!.dateApproved = new Date().getTime();
			invoice!.dateUpdated = new Date().getTime();
			// Update invoice
			const inv = await FbSetInvoice(invoice!);
			setInvoice(inv);

			// Add transaction
			// Add transaction to the database for contractor
			const tx: Transaction = {
				clientId: invoice!.client.id,
				clientName: invoice!.client.name,
				invoiceName: invoice!.name,
				amount: invoice!.total,
				invoiceId: invoice!.id!,
				type: "unlock",
				time: new Date().getTime(),
				from: invoice!.payerAddress!,
				to: invoice!.payeeAddress!,
				id: signedMessage,
				uid: invoice!.ownerId,
			};
			// add the tx to db
			await FbSetTransaction(tx);

			// Send email
			await SendInvoice(invoice!.id!);

			setScreen("approved");
			enqueueSnackbar("Invoice has been approved. ", {
				variant: "success",
			});
		} catch (err: any) {
			enqueueSnackbar(
				"Error processing your request. Please check logs for details.",
				{ variant: "error" }
			);
			console.log(err);
		}
		setLoading(false);
	}

	/**
	 * Submits the given feedback to the contractor. The same function is used to submit feedback for proposal as well as for deliverables.
	 * @returns
	 */
	async function submitFeedback() {
		if (!invoice) {
			return;
		}
		try {
			setBusy(true);
			// Save the reason.
			// If the current status is delivered, we submit the feedback with rejection.
			if (invoice.status === INVOICE_STATUS.DELIVERED) {
				invoice.status = INVOICE_STATUS.REJECTED;
				invoice.dateRejected = new Date().getTime();
				invoice.dateUpdated = new Date().getTime();
				// Add item to timeline
				invoice.timeline = [
					...(invoice.timeline ? invoice.timeline : []),
					{
						actorKind: InvoiceActorKind.Client,
						kind: InvoiceActionKind.Rejected,
						time: new Date().getTime(),
						details: invoice.delivery.feedback,
					},
				];
			} else {
				// Submit for proposal
				invoice.status = INVOICE_STATUS.REFUSED;
				invoice.dateUpdated = new Date().getTime();
				// Add item to timeline
				invoice.timeline = [
					...(invoice.timeline ? invoice.timeline : []),
					{
						actorKind: InvoiceActorKind.Client,
						kind: InvoiceActionKind.Refused,
						time: new Date().getTime(),
						details: invoice.quote.feedback,
					},
				];
			}
			// Save
			await FbSetInvoice(invoice);
			// Send email
			if (AppConfig.mode === "PRODUCTION") {
				await SendInvoice(invoice!.id!);
			}
			enqueueSnackbar(
				"Invoice has been saved. The contractor will be notified via email.",
				{ variant: "success" }
			);
			setScreen("feedback_sent");
		} catch (err: any) {
			enqueueSnackbar(
				"Error saving invoice, please contact administrators.",
				{
					variant: "error",
				}
			);
			console.log(err);
		}
		setBusy(false);
	}

	/**
	 * Accepts the contract terms.
	 */
	async function validateInvoice() {
		if (!invoice) {
			return;
		}
		if (!account.isConnected) {
			rainbow?.openConnectModal?.();
			return;
		}
		// We dont have to confirm network here as we are only collecting address.
		// if (chain && chain.name.toLowerCase() !== invoice?.network) {
		//   // Trigger chain selectioin
		//   openChainModal?.();
		//   return;
		// }
		setLoading(true);
		try {
			// Store the msg in invoice
			invoice!.payerAddress = account.address;
			invoice!.status = "validated";
			invoice!.dateValidated = new Date().getTime();
			invoice!.dateUpdated = new Date().getTime();
			// Update invoice
			const inv = await FbSetInvoice(invoice!);
			setInvoice(inv);
			// Send email
			await SendInvoice(invoice!.id!);

			enqueueSnackbar(
				"The proposal has been approved. You will be notified when the invoice has been created.",
				{ variant: "success" }
			);
			setScreen("validated");
		} catch (err: any) {
			enqueueSnackbar(
				"Error processing your request. Please contact administrators.",
				{ variant: "error" }
			);
			console.log(err);
		}
		setLoading(false);
	}

	function getStepVisual() {
		switch (invoice?.status) {
			case INVOICE_STATUS.REVIEWED:
			case INVOICE_STATUS.REVIEW:
			case INVOICE_STATUS.REFUSED:
				return 1;
			case INVOICE_STATUS.VALIDATED:
				return 2;
			case INVOICE_STATUS.UNPAID:
			case INVOICE_STATUS.CREATED:
				return 3;
			case "paid":
				return 4;
			case INVOICE_STATUS.DELIVERED:
			case INVOICE_STATUS.REJECTED:
			case INVOICE_STATUS.DISPUTE:
				return 5;
			case "approved":
				return 6;
			case "complete":
				return 7;
			default:
				return 1;
		}
	}

	async function loadSupport(_invoice: any) {
		try {
			const prof = await FbGetProfile();
			if (!prof) {
				// Here we show the bubbble
				(window as any).Intercom("boot", {
					api_base: "https://api-iam.intercom.io",
					app_id: "xrmsb03k",
					...(_invoice
						? {
								invoiceId: _invoice.id,
								invoiceNumber: _invoice.invoiceNumber,
								invoiceContractor:
									_invoice.contractor.emailAddress,
								invoiceClient: _invoice.client.email,
								invoiceStatus: _invoice.status,
						  }
						: {}),
				});
			}
		} catch (err: any) {}
	}

	async function openDispute() {
		try {
			if (!invoice) return;
			setBusy(true);
			// Set the status to dispute
			invoice.status = INVOICE_STATUS.DISPUTE;
			invoice.dateDispute = new Date().getTime();
			invoice.dateUpdated = new Date().getTime();
			invoice.timeline = [
				...(invoice.timeline ? invoice.timeline : []),
				{
					actorKind: InvoiceActorKind.Client,
					kind: InvoiceActionKind.Dispute,
					time: new Date().getTime(),
					details:
						"Client requested mediation. Reasons: " +
						invoice.reason,
				},
			];
			// Add dispute info
			invoice.mediation = {
				dateRequested: new Date().getTime(),
				desiredOutcome: desiredOutcomes[desiredOutcome][1],
				initiatedBy: "client",
				invoiceId: invoice.id,
				status: MediationStatus.Pending,
			};
			// Save
			const inv = await FbSetInvoice(invoice);
			setInvoice(inv);
			// Send email in prodc
			if (AppConfig.mode === "PRODUCTION") {
				await SendInvoice(inv!.id!);
			}
			enqueueSnackbar(
				"Request for mediation has been sent. You will be notified via email when a decision has been made.",
				{ variant: "success" }
			);
			setScreen("dispute");
		} catch (err: any) {
			enqueueSnackbar(
				"Error saving invoice, please contact administrators.",
				{
					variant: "error",
				}
			);
			console.log(err);
		}
		setBusy(false);
	}

	const elementRef = useRef<any>();
	const triggerPrint = useReactToPrint({
		content: () => elementRef.current,
		copyStyles: true,
		//pageStyle: "font-family: 'Inter'",
		onBeforeGetContent: () => {
			setBusy(true);
		},
		onAfterPrint: () => {
			// Reset the Promise resolve so we can print again
			setBusy(false);
		},

		async print(target: any) {
			await printInvoice((invoice!.name || invoice!.id) + ".pdf", target);
		},
	});

	useEffect(() => {
		if (Boolean(invoiceId) === false) {
			// Redirect
			nav("/");
		}
		// Load invoice
		loadInvoice();
	}, []);

	// For errors and msgs
	const { enqueueSnackbar } = useSnackbar();

	function RenderWorkItemButton(num: number) {
		return (
			<Button
				variant="text"
				sx={{
					color: "#000",
					fontWeight: "bold",
					px: "32px",
					borderRadius: 0,
					borderBottom:
						selectedItem === num ? "4px solid #A882F3" : "0px",
				}}
				onClick={() => setSelectedItem(num)}
			>
				Item # {num + 1}
			</Button>
		);
	}

	// Gets the item feedback from invoice.quote or invoice.delivery
	function getItemFeedback() {
		const item = invoice?.items[selectedItem].name;
		if (item) {
			// If its a quote, we get it from invoice.quote
			if (isQuote(invoice!)) {
				const fb = Object.entries(invoice.quote?.feedback || []).find(
					(f) => f[0] === item
				);
				return fb?.[1].feedback;
			} else {
				// Otherwise we get it from invoice.delivery
				const fb = Object.entries(
					invoice.delivery?.feedback || []
				).find((f) => f[0] === item);
				return fb?.[1].feedback;
			}
		}
		return undefined;
	}

	// Sets the item feedback in invoice.quote or invoice.delivery
	function setItemFeedback(c: string) {
		if (isQuote(invoice!)) {
			setInvoice({
				...(invoice as any),
				quote: {
					...(invoice?.quote ? invoice.quote : {}),
					feedback: {
						...(invoice?.quote ? invoice.quote.feedback : {}),
						[`${invoice?.items[selectedItem].name}`]: {
							feedback: c,
							...invoice?.items[selectedItem],
						},
					},
				},
			});
		} else {
			// It is an invoice. Here we set feedback on delivery.
			setInvoice({
				...(invoice as any),
				delivery: {
					...(invoice?.delivery ? invoice.delivery : {}),
					feedback: {
						...(invoice?.delivery ? invoice.delivery.feedback : {}),
						[`${invoice?.items[selectedItem].name}`]: {
							feedback: c,
							...invoice?.items[selectedItem],
						},
					},
				},
			});
		}
	}

	function getMediationHeadline() {
		switch (invoice?.mediation!.status) {
			case MediationStatus.Pending:
				return "Mediation In Progress";
			case MediationStatus.Escalated:
				return "Escalated to Decentralized Dispute";
			case MediationStatus.Implementing:
				return "Implementing Mediation";
			case MediationStatus.Resolved:
				return "Implementing Mediation";
		}
	}

	if (loading) {
		return (
			<Stack
				sx={{ width: "100%", height: "100%" }}
				justifyContent="center"
				alignItems="center"
			>
				{/* Paper container  */}
				<Paper sx={{ width: "299px", py: "40px" }}>
					<Stack spacing="10px">
						<img
							src={PAYANT_LOGO_TRANSPARENT}
							alt="Payant Logo"
							height="30px"
						/>
						<Typography
							textAlign="center"
							fontSize="20px"
							mt="10px"
							fontWeight="bold"
							color="primary"
						>
							Loading, please wait.
						</Typography>
					</Stack>
				</Paper>
			</Stack>
		);
	}

	// If we are main screen
	if (screen === "welcome" && invoice) {
		return (
			<Stack
				sx={{ width: "100%", height: "100%", background: "#938BEF1A" }}
				justifyContent="center"
				alignItems="center"
			>
				{/* Paper container  */}
				<Paper
					elevation={0}
					sx={{
						width: "299px",
						py: "40px",
						px: "20px",
						borderRadius: "18px",
					}}
				>
					<Stack spacing="10px">
						<img
							src={PAYANT_LOGO_TRANSPARENT}
							alt="Payant Logo"
							height="30px"
						/>
						<Typography
							textAlign="center"
							fontSize="20px"
							mt="10px"
							fontWeight={600}
						>
							You've received a Proposal!
						</Typography>
						<PayantTextField
							value={invoice?.name || ""}
							label="Proposal Name"
						/>
						<PayantTextField
							value={invoice?.contractor?.businessName || ""}
							label="Contractor"
						/>
						<PayantTextField
							value={Moneyfy(invoice!.total)}
							label="Total Amount"
						/>
						<PayantTextField
							value={moment(new Date(invoice!.endDate)).format(
								"Do MMM. YYYY"
							)}
							label="Deadline"
						/>
						<Button
							variant="contained"
							onClick={() => setScreen("review")}
							sx={{
								my: "10px",
								width: "150px",
								borderRadius: "25px",
								alignSelf: "center",
							}}
						>
							Review Proposal
						</Button>

						<Stack
							direction={"row"}
							sx={{ width: "100%" }}
							justifyContent="center"
							alignItems="center"
							spacing={1}
						>
							<Typography fontSize="12px">
								First time with payant?
							</Typography>
							<Typography fontSize="12px" color="#4E00E4">
								How it works.
							</Typography>
						</Stack>
					</Stack>
				</Paper>
			</Stack>
		);
	}

	// Invoice couldn't be loaded.
	if (!invoice && !loading) {
		return (
			<Stack
				sx={{ width: "100%", height: "100%" }}
				justifyContent="center"
				alignItems="center"
			>
				{/* Paper container  */}
				<Paper sx={{ width: "299px", py: "40px" }}>
					<Stack spacing="10px">
						<img
							src={PAYANT_LOGO_TRANSPARENT}
							alt="Payant Logo"
							height="30px"
						/>
						<Typography
							textAlign="center"
							fontSize="20px"
							mt="10px"
							fontWeight="bold"
							color="error"
						>
							Error loading invoice.
						</Typography>
					</Stack>
				</Paper>
			</Stack>
		);
	}

	return (
		<Stack
			sx={{ width: "100%", height: "100%", background: "#938BEF1A" }}
			justifyContent="center"
			alignItems="center"
		>
			<Paper
				sx={{
					width: "594px",
					p: "24px",
					pt: "24px",
					borderRadius: "18px",
					background: "white",
				}}
				elevation={0}
			>
				<PayantStepper
					hidelast
					active={getStepVisual()}
					stepsCount={6}
				/>
				{/* Review screen, shows invoice info, and Lock and Validate button.  */}
				{screen === "review" && (
					<Stack spacing={"10px"}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography fontWeight={600} fontSize="16px">
								{isQuote(invoice!)
									? "Review Proposal"
									: "Review Invoice"}
							</Typography>
							<Button
								disabled={busy}
								onClick={triggerPrint}
								variant="text"
								endIcon={<FileDownload />}
							>
								{isQuote(invoice!)
									? "Download Proposal"
									: "Download Invoice"}
							</Button>
						</Stack>
						<Stack
							direction="row"
							spacing={"10px"}
							sx={{ mt: "15px" }}
						>
							<PayantTextField
								fullWidth
								value={invoice?.client.name || ""}
								label="Client's Name"
							/>
							<PayantTextField
								fullWidth
								value={invoice?.name || ""}
								label={
									isQuote(invoice!)
										? "Proposal Name"
										: "Invoice's Name"
								}
							/>
						</Stack>
						<Stack
							direction="row"
							spacing={"10px"}
							sx={{ mt: "15px" }}
						>
							<PayantTextField
								fullWidth
								value={GetInvoiceStatus(invoice!)}
								label="Status"
							/>

							<PayantTextField
								fullWidth
								value={
									invoice?.endDate
										? moment(
												new Date(invoice!.endDate)
										  ).format("Do MMM. YYYY")
										: ""
								}
								label="Deadline"
							/>
						</Stack>
						<List component={"nav"}>
							{invoice?.items.map((i, j) =>
								RenderWorkItemButton(j)
							)}
						</List>
						<Stack direction="row" spacing={"10px"}>
							<PayantTextField
								fullWidth
								value={invoice?.items[selectedItem]?.name || ""}
								label="Item Name"
							/>

							<PayantTextField
								fullWidth
								value={
									"$" + invoice?.items[selectedItem]?.price ||
									"$0"
								}
								label="Item Price"
							/>
						</Stack>
						<PayantTextField
							key={selectedItem}
							value={
								invoice?.items[selectedItem]?.description || ""
							}
							rows={10}
							maxRows={10}
							multiline
							label="Item Description"
						/>
						{invoice?.items.length && invoice.items.length > 1 && (
							<Stack
								direction="row"
								alignItems={"center"}
								justifyContent="center"
							>
								<Tooltip title="Previous item">
									<IconButton
										disabled={selectedItem === 0}
										onClick={() =>
											setSelectedItem(selectedItem - 1)
										}
									>
										<ArrowBackRounded />
									</IconButton>
								</Tooltip>
								<Tooltip title="Next item">
									<IconButton
										onClick={() =>
											setSelectedItem(selectedItem + 1)
										}
										disabled={
											selectedItem + 1 >=
											(invoice?.items.length || 1)
										}
									>
										<ArrowForwardRounded />
									</IconButton>
								</Tooltip>
							</Stack>
						)}
						<Stack direction="row" spacing={"10px"}>
							<PayantTextField
								fullWidth
								value={invoice?.vat || "0"}
								label="VAT %"
							/>

							<PayantTextField
								fullWidth
								value={"$" + invoice?.total || ""}
								label="Total Amount"
							/>
						</Stack>

						{/* If we are in validation, we show validate button  */}
						{(invoice?.status === INVOICE_STATUS.REVIEWED ||
							invoice?.status === INVOICE_STATUS.REVIEW) && (
							<>
								<Stack
									direction="row"
									justifyContent="center"
									alignItems="center"
									spacing={"10px"}
									sx={{ width: "100%" }}
								>
									<Button
										disabled={busy}
										variant="outlined"
										sx={{
											borderRadius: "25px",
											height: "44px",
											width: "200px",
											alignSelf: "center",
										}}
										onClick={() => setScreen("feedback")}
									>
										Give Feedback
									</Button>
									<Button
										disabled={busy}
										variant="contained"
										sx={{
											borderRadius: "25px",
											width: "200px",
											height: "44px",
										}}
										onClick={validateInvoice}
									>
										Approve Proposal
									</Button>
								</Stack>
							</>
						)}

						{/* If we are in unpaid state, we show unpaid button  */}
						{(invoice?.status === "unpaid" ||
							invoice?.status === INVOICE_STATUS.CREATED) && (
							<Button
								disabled={busy}
								variant="contained"
								fullWidth
								sx={{ borderRadius: "25px", height: "44px" }}
								onClick={lockFunds}
							>
								{account.isConnected
									? "Secure Funds in Payant"
									: "Connect Wallet"}
							</Button>
						)}
					</Stack>
				)}

				{screen === "feedback" && (
					<Stack spacing={"15px"} sx={{ pt: "24px" }}>
						<Typography
							textAlign="center"
							fontWeight={600}
							fontSize="18px"
						>
							Share Feedback
						</Typography>
						<Typography fontSize="14px">
							Add your feedback for {invoice?.contractor.fullName}{" "}
							with specific details, you can upload links to a
							google drive with screenshots, etc.
						</Typography>
						{/* Each item has its own feedback */}
						<Stack direction={"row"}>
							{invoice?.items.map((item, ind) =>
								RenderWorkItemButton(ind)
							)}
						</Stack>
						<PayantTextField
							key={selectedItem}
							value={getItemFeedback()}
							onUpdate={(c) => setItemFeedback(c)}
							label="Feedback"
							required
							multiline
							rows={8}
							maxRows={8}
						/>
						<Stack
							direction="row"
							justifyContent={"center"}
							alignItems="center"
							spacing={"10px"}
						>
							{!busy && (
								<Button
									variant="outlined"
									disabled={busy}
									sx={{
										borderRadius: "25px",
										width: "200px",
										height: "44px",
									}}
									onClick={loadInvoice}
								>
									Back
								</Button>
							)}

							<Button
								variant="contained"
								fullWidth
								disabled={busy}
								sx={{
									borderRadius: "25px",
									width: "200px",
									height: "44px",
								}}
								onClick={submitFeedback}
							>
								{busy ? "Please wait..." : "Send"}
							</Button>
						</Stack>
					</Stack>
				)}

				{/* Shows the form with client to fill in the reason for opening dispute, removed in #150  */}
				{screen === "dispute_request" && (
					<Stack spacing={"15px"} sx={{ pt: "24px" }}>
						<Typography
							textAlign="center"
							fontWeight={600}
							fontSize="18px"
						>
							Mediation Request Form
						</Typography>
						<Typography fontSize="14px">
							Please fill in this form to request Payant Mediation
						</Typography>
						<Stack sx={{ width: "100%" }}>
							<FormControl
								fullWidth
								variant="filled"
								required
								id="prop_outcome"
							>
								<InputLabel>Desired Outcome</InputLabel>
								<Select
									required
									label="Desired Outcome"
									sx={{ mt: "5px", mb: "18px" }}
									onChange={(c) => {
										setDesiredOutcome(
											parseInt(c.target.value.toString())
										);
									}}
									defaultValue={-1}
									value={desiredOutcome}
									IconComponent={KeyboardArrowDown}
								>
									<MenuItem key={-1} value={-1} disabled>
										Select your desired outcome...
									</MenuItem>
									{desiredOutcomes.map((doc, ind) => (
										<MenuItem key={ind} value={ind}>
											{doc[0]}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<PayantTextField
								fullWidth
								value={mediationReason || ""}
								onUpdate={(t) => setMediationReason(t)}
								label="Reason"
								placeholder="Please describe your complain. Share as much details as possible."
								multiline
								rows={8}
								maxRows={8}
							/>
						</Stack>
						<Stack
							direction="row"
							justifyContent="center"
							alignItems="center"
							spacing={"10px"}
							sx={{ width: "100%" }}
						>
							<Button
								disabled={busy}
								variant="outlined"
								sx={{
									borderRadius: "25px",
									width: "200px",
								}}
								onClick={loadInvoice}
							>
								Cancel
							</Button>

							<Button
								disabled={
									desiredOutcome === -1 ||
									!mediationReason ||
									busy
								}
								variant="contained"
								sx={{
									borderRadius: "25px",
									width: "200px",
								}}
								onClick={() => openDispute()}
							>
								Submit
							</Button>
						</Stack>
					</Stack>
				)}

				{screen === "feedback_sent" && (
					<Stack spacing={"15px"} sx={{ pt: "24px" }}>
						<img
							height="42px"
							width="100%"
							src={CHECKMARK_PURPLE}
							alt="Feedback Shared"
						/>
						<Typography
							textAlign="center"
							fontWeight={600}
							fontSize="18px"
						>
							Feedback Shared
						</Typography>
						<Typography fontSize="14px">
							The feedback has been sent to{" "}
							{invoice?.contractor.fullName}. Your contractor now
							has 7 days to approve the feedback.
						</Typography>
						{/* Each item has its own feedback */}
						<Stack direction={"row"}>
							{invoice?.items.map((item, ind) =>
								RenderWorkItemButton(ind)
							)}
						</Stack>
						<PayantTextField
							key={selectedItem}
							value={getItemFeedback()}
							label="Feedback"
							multiline
							rows={8}
							maxRows={8}
						/>
						<Button
							variant="contained"
							fullWidth
							disabled={busy}
							sx={{ borderRadius: "25px", height: "44px" }}
							onClick={() => setScreen("review")}
						>
							Go Back to Status
						</Button>
					</Stack>
				)}

				{/* Shows that invoice is waiting for deployment */}
				{screen === "validated" && (
					<Stack
						alignItems="center"
						spacing={"15px"}
						justifyContent="center"
					>
						<img
							height="42px"
							width="42px"
							src={PENDING_PURPLE}
							alt="Invoice Validated"
						/>
						<Typography fontWeight={600} fontSize="16px">
							Awaiting Creation
						</Typography>
						<Typography fontSize="14px" fontWeight={400}>
							The proposal has been approved and is awaiting
							creation of invoice. We will let you know when{" "}
							{invoice?.contractor.businessName} has created the
							invoice.
						</Typography>
						<Button
							variant="contained"
							fullWidth
							sx={{ borderRadius: "25px", height: "44px" }}
							onClick={() => setScreen("review")}
						>
							Proposal Details
						</Button>
					</Stack>
				)}

				{/* Shown only when payment is made  */}
				{paymentSuccess && (
					<Stack
						alignItems="center"
						spacing={"15px"}
						sx={{ pt: "24px" }}
						justifyContent="center"
					>
						<img
							height="39px"
							width="39px"
							src={CHECKMARK_PURPLE}
							alt="Invoice Paid"
						/>
						<Typography fontWeight={600} fontSize="16px">
							Payment Received
						</Typography>
						<Typography fontSize="14px" fontWeight={400}>
							Your Smart Invoice of ${invoice?.total} has been
							paid. The funds have been locked in Payant's Smart
							Contract. We will send you a notification once the
							contractor has uploaded the deliverables.
						</Typography>
						<Alert
							sx={{
								width: "100%",
								fontWeight: "bold",
								alignItems: "center",
							}}
							color="success"
						>
							Transaction:{" "}
							{ShortifyAddress(invoice!.paymentHash!)}
							<Button
								onClick={() =>
									viewTransaction(
										invoice!.paymentHash!,
										invoice!.network
									)
								}
								variant="text"
							>
								Open in Block Explorer
							</Button>
						</Alert>
						<Button
							variant="contained"
							fullWidth
							sx={{ borderRadius: "25px", height: "44px" }}
							onClick={() => {
								setScreen("review");
								setPaymentSuccess(false);
							}}
						>
							Invoice Details
						</Button>
					</Stack>
				)}

				{/* Shown when invoice state is already in paid state*/}
				{screen === "paid" && !paymentSuccess && (
					<Stack
						alignItems="center"
						spacing={"15px"}
						sx={{ pt: "24px" }}
						justifyContent="center"
					>
						<img
							height="42px"
							width="42px"
							src={PENDING_PURPLE}
							alt="Invoice Paid"
						/>
						<Typography fontWeight={600} fontSize="18px">
							Awaiting Deliverables
						</Typography>
						<Typography fontSize="14px">
							We've received your payment for the invoice{" "}
							{invoice?.name}. Once your contractor has uploaded
							the deliverables, you'll receive a notification.
						</Typography>
						<Button
							variant="contained"
							fullWidth
							sx={{ borderRadius: "25px", height: "44px" }}
							onClick={() => setScreen("review")}
						>
							Invoice Details
						</Button>
					</Stack>
				)}

				{/* Delivered state, shows deliverables info   */}
				{screen === "delivered" && (
					<Stack spacing={"15px"} sx={{ pt: "24px" }}>
						<img
							height="42px"
							width="100%"
							src={DOCUMENT_PURPLE}
							alt="Review Deliverables"
						/>
						<Typography
							textAlign="center"
							fontWeight={600}
							fontSize="18px"
						>
							Review Deliverables
						</Typography>
						<Typography fontSize="14px">
							Your contract has just uploaded the deliverables.
							You have until{" "}
							<strong>
								{moment(new Date(invoice!.dateDelivered!))
									.add(14, "days")
									.format("DD/MM/YYYY")}
							</strong>{" "}
							to review them. Afterwards, funds will be
							automatically unlocked.
						</Typography>
						<List component={"nav"}>
							{invoice?.items.map((i, j) =>
								RenderWorkItemButton(j)
							)}
						</List>
						<></>
						{invoice?.items[selectedItem]?.deliveryDescription && (
							<PayantTextField
								rows={5}
								key={selectedItem}
								value={
									invoice?.items[selectedItem]
										?.deliveryDescription || ""
								}
								label="Description of Deliverables"
							/>
						)}
						{invoice?.items[selectedItem]?.link && (
							<PayantTextField
								key={selectedItem + 1}
								value={invoice?.items[selectedItem]?.link || ""}
								label="Link to Delierables"
							/>
						)}
						<Typography fontSize="10px" sx={{ color: "#8B8B8B" }}>
							Please make sure to review deliverables with caution
							before approving. Canceling an approved invoice is
							not possible.
						</Typography>
						<Stack
							direction="row"
							justifyContent="center"
							alignItems="center"
							spacing={"10px"}
							sx={{ width: "100%" }}
						>
							<Button
								disabled={busy}
								variant="outlined"
								sx={{
									borderRadius: "25px",
									width: "200px",
									alignSelf: "center",
									height: "44px",
								}}
								onClick={() => setScreen("feedback")}
							>
								Give Feedback
							</Button>
							<Button
								disabled={busy}
								variant="contained"
								sx={{
									borderRadius: "25px",
									width: "200px",
									height: "44px",
								}}
								onClick={unlockFunds}
							>
								Approve Deliverables
							</Button>
						</Stack>
						<Stack
							direction="row"
							justifyContent="center"
							alignItems="center"
							spacing={"10px"}
							sx={{ width: "100%" }}
						>
							<Button
								disabled={busy}
								variant="text"
								sx={{ borderRadius: "25px", width: "200px" }}
								onClick={() => setScreen("dispute_request")}
							>
								Request Mediation
							</Button>
						</Stack>
					</Stack>
				)}

				{/* Approved state */}
				{screen === "approved" && (
					<Stack
						alignItems="center"
						sx={{ pt: "24px" }}
						spacing={"15px"}
						justifyContent="center"
					>
						<img
							height="39px"
							width="39px"
							src={CHECKMARK_PURPLE}
							alt="Approved"
						/>
						<Typography fontWeight={600} fontSize="18px">
							Deliverables Approved!
						</Typography>
						<Typography fontSize="14px" fontWeight={400}>
							The deliverables have been successfully reviewed.
							The funds have been unlocked and you contractor can
							now withdraw them.
						</Typography>
					</Stack>
				)}

				{screen === "dispute" && (
					<Stack
						alignItems="center"
						sx={{ pt: "24px" }}
						spacing={"15px"}
						justifyContent="center"
					>
						<img
							src={DOCUMENT_PURPLE}
							width="39px"
							height="39px"
							alt="Invoice in Dispute"
						/>
						<Typography fontWeight={600} fontSize="18px">
							{getMediationHeadline()}
						</Typography>
						<Typography fontSize="14px" fontWeight={400}>
							The invoice {invoice?.name} is now in Mediation.
							Your client or you have requested Payant’s help to
							mediate the payment of your invoice. You can now
							fill the form below to share with us more elements
							allowing us to mediate your case. Learn more about
							Payant Mediation.
						</Typography>
						<Button
							variant="contained"
							fullWidth
							disabled={busy}
							sx={{ borderRadius: "25px", height: "44px" }}
							onClick={() => setScreen("review")}
						>
							View Invoice
						</Button>
					</Stack>
				)}
			</Paper>

			<div
				style={{
					position: "absolute",
					overflow: "hidden",
					height: 0,
					width: 0,
					zIndex: -999,
				}}
			>
				<div ref={elementRef}>
					<PrintableInvoice invoice={invoice!} />
				</div>
			</div>
		</Stack>
	);
}
