//import bcrypt from "bcryptjs";

import axios from "axios";
import { AppConfig } from "../config";
import { Invoice, INVOICE_STATUS } from "payant-lib";
import jsPDF from "jspdf";
import { FbGetInvoiceCount } from "./firebase";
import moment from "moment";

/**
 * Hashes the given string using bcrypt.
 * @param msg
 */
export async function HashBcrypt(msg: string) {
  //const hash = await bcrypt.hash(msg, 8);
  return msg;
}

export function GetInvoiceLink(id: string) {
  return AppConfig.mode === "PRODUCTION"
    ? "https://staging.app.payant.io/#/invoice?id=" + id
    : "http://localhost:3000/#/invoice?id=" + id;
}

/**
 * Formats the specified amount in float to a string, with decimals and commas.
 * @param amount
 */
export function Moneyfy(
  amount: number,
  fractions: number = 0,
  currency: string = "USD"
) {
  return new Intl.NumberFormat("en-us", {
    currency: currency,
    style: "currency",
    maximumFractionDigits: fractions,
  }).format(amount);
}

export async function getEthPrice() {
  const resp = await axios.get(
    "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD"
  );
  return parseFloat(resp.data["USD"]);
}

/**
 * Converts the price in fiat currency to its crypto equivalent.
 */
export function fiatToCoin(
  fiat: number,
  tokenInfo: { name: string; decimals: number; address: string }
) {
  return fiat * 10 ** tokenInfo.decimals;
}

/**
 * Convert address of blockchain into short 10 letters stromg
 * @param addr
 */
export function ShortifyAddress(addr: string) {
  return addr.substring(0, 6) + ".." + addr.substring(addr.length - 4);
}

export function GetInvoiceStatusColor(inv: Invoice) {
  switch (inv.status) {
    case INVOICE_STATUS.CREATED:
      return "#000";
    case INVOICE_STATUS.REVIEW:
      return "#ffa500";
    case INVOICE_STATUS.VALIDATED:
      return "#bc0ebc";
    case INVOICE_STATUS.UNPAID:
      return "#ffa500";
    case INVOICE_STATUS.PAID:
      return "#bc0ebc";
    case INVOICE_STATUS.DELIVERED:
      return "#ffa500";
    case INVOICE_STATUS.APPROVED:
      return "#bc0ebc";
    case INVOICE_STATUS.COMPLETE:
      return "#0F0";
    default:
      return "#000";
  }
}

/**
 * Gives a breif text of invoice's status, rather than using enum values.
 * @param inv
 */
export function GetInvoiceStatus(inv: Invoice) {
  switch (inv.status as INVOICE_STATUS) {
    case INVOICE_STATUS.DRAFT:
      return "Draft";
    case INVOICE_STATUS.REVIEW:
      return "Proposal Waiting Client Approval";
    case INVOICE_STATUS.REVIEWED:
      return "Proposal Viewed";
    case INVOICE_STATUS.VALIDATED:
      return "Proposal Approved by Client";
    case INVOICE_STATUS.CREATED:
      return "Invoice Sent to Client";
    case INVOICE_STATUS.UNPAID:
      return "Invoice Viewed - Awaiting Client Payment";
    case INVOICE_STATUS.PAID:
      return "Invoice Approved - Upload Deliverables";
    case INVOICE_STATUS.DELIVERED:
      return "Deliverables Under Review";
    case INVOICE_STATUS.APPROVED:
      return "Funds Ready To Unlock";
    case INVOICE_STATUS.COMPLETE:
      return "Completed";

    case INVOICE_STATUS.DISPUTE:
      return "In Dispute";
    case INVOICE_STATUS.EXPIRED:
      return "Expired";
    case INVOICE_STATUS.REJECTED:
      return "Requested Changes";
    case INVOICE_STATUS.REFUSED:
      return "Proposal Refused";
  }
  return inv?.status?.toUpperCase() || "";
}

export async function printInvoice(fileName: string, target: any) {
  const p = new jsPDF({
    orientation: "portrait",
    unit: "px",
    putOnlyUsedFonts: true,
  });
  //p.setFont("Inter");
  const w = p.html(target.contentDocument!.getElementsByTagName("html")[0]!, {
    filename: fileName,
    image: {
      type: "webp",
      quality: 1,
    },
    fontFaces: [
      {
        family: "Inter",
        style: "normal",
        src: [{ format: "truetype", url: "/fonts/static/Inter-Regular.ttf" }],
      },
      {
        family: "Inter",
        style: "normal",
        weight: 700,
        src: [{ format: "truetype", url: "/fonts/static/Inter-Bold.ttf" }],
      },
      {
        family: "Inter",
        style: "normal",
        weight: 300,
        src: [{ format: "truetype", url: "/fonts/static/Inter-Light.ttf" }],
      },
    ],
    width: 800,
    html2canvas: {
      scale: 0.5,
      width: 800,
    },
    margin: [0, 15, 0, 15],
  });
  await w.save(fileName);
}

/**
 * Returns true if the invoice is a quote.
 */
export function isQuote(invoiceOrQuote: Invoice) {
  return (
    invoiceOrQuote.status === INVOICE_STATUS.DRAFT ||
    invoiceOrQuote.status === INVOICE_STATUS.REVIEW ||
    invoiceOrQuote.status === INVOICE_STATUS.REVIEWED ||
    invoiceOrQuote.status === INVOICE_STATUS.VALIDATED ||
    invoiceOrQuote.status === INVOICE_STATUS.REFUSED
  );
}

/**
 * Returns true if the invoice is not a quote.
 * @param invoiceOrQuote
 * @returns
 */
export function isInvoice(invoiceOrQuote: Invoice) {
  return (
    invoiceOrQuote.status !== INVOICE_STATUS.DRAFT &&
    invoiceOrQuote.status !== INVOICE_STATUS.REVIEW &&
    invoiceOrQuote.status !== INVOICE_STATUS.REVIEWED &&
    invoiceOrQuote.status !== INVOICE_STATUS.VALIDATED &&
    invoiceOrQuote.status !== INVOICE_STATUS.REFUSED
  );
}

/**
 * Returns true if the invoice is 'active'.
 * @param invoice
 */
export function isActiveInvoice(invoice: Invoice) {
  return (
    invoice.status === INVOICE_STATUS.PAID ||
    invoice.status === INVOICE_STATUS.UNPAID ||
    invoice.status === INVOICE_STATUS.CREATED ||
    invoice.status === INVOICE_STATUS.DELIVERED ||
    invoice.status === INVOICE_STATUS.REJECTED ||
    invoice.status === INVOICE_STATUS.DISPUTE
  );
}

/**
 * Generates a unique invoice number for a specific contractor.
 */
export async function GenerateInvoiceNumber(uid: string) {
  // [DDMMYYYY]-[NUM]
  const y = moment().format("DDMMYYYY");
  const cnt = await FbGetInvoiceCount();
  return `${y}-${cnt + 1}`;
}

export async function viewTransaction(addr: string, network: string) {
  if (network === "polygon mumbai") {
    window.open("https://mumbai.polygonscan.com/tx/" + addr, "_blank");
  }
}
