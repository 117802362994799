import {
  Paper,
  Stack,
  StackProps,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
  StepProps,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#4E00E4",
      height: "4px",
      borderRadius: "15px",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    background: "#D4D4D4",
    height: "4px",
    borderRadius: "15px",
    border: "none",
  },
}));

const QontoStepIconRoot = styled("div")<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState }) => ({
  display: "flex",
  justifyContent: "center",
  height: "21px",
  width: "21px",
  borderRadius: "50%",
  alignItems: "center",
  color: "#7F7F88",
  backgroundColor: "#F0F0F0",
  ...(ownerState.active && {
    background: "#938BEF",
    color: "#FFFFFF",
  }),
  ...(ownerState.completed && {
    background: "#5D18EB",
    color: "#FFFFFF",
  }),
}));

function QontoStepIcon(props: StepIconProps & StepProps) {
  const { active, completed, className, icon } = props;

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      <Typography fontSize={12}>{icon?.toString()}</Typography>
    </QontoStepIconRoot>
  );
}

export default function PayantStepper(
  props: {
    children?: any[];
    active: number;
    hidelast?: boolean;
    stepsCount?: number;
  } & StackProps
) {
  function GetSteps(max: number) {
    const li: React.ReactElement[] = [];
    for (let i = 1; i <= max; i++) {
      li.push(
        <Step key={i} sx={{ mx: 0, p: 0 }}>
          <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
        </Step>
      );
    }
    return li;
  }
  const currentStep = props.active;

  function GetCurrentChild() {
    return props.children!.at(currentStep - 1);
  }

  return (
    <Stack justifyContent="center" alignItems={"center"} {...props}>
      <Stepper
        variant="elevation"
        sx={{
          width: "100%",
          my: "0px",
          mx: 0,
          p: 0,
        }}
        activeStep={currentStep - 1}
        connector={<QontoConnector sx={{ m: 0, mr: "8px", p: 0 }} />}
        orientation="horizontal"
      >
        {GetSteps(props.stepsCount || props.children?.length || 1)}
      </Stepper>
      {props.children && props.children.length > 0 && (
        <Paper sx={{ width: "100%", flex: 1, p: 0, background: "#FFFFFF" }}>
          <GetCurrentChild />
        </Paper>
      )}
    </Stack>
  );
}
