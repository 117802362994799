import { Favorite } from "@mui/icons-material";
import {
  Button,
  Icon,
  Paper,
  PaperProps,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import Chart from "react-google-charts";
import { Moneyfy } from "../core/helper";
import { GetAnalytics } from "../rest/user";
import LoadingIndicator from "./loadingIndicator";

export default function RevenueChart(props: PaperProps) {
  const [totalRevenue, setTotalRevenue] = React.useState(0);
  const [busy, setBusy] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);
  const [chartData, setChartData] = React.useState<any[]>([]);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [days, setDays] = React.useState(30); //7months
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  function createTooltip(content: string) {
    return `<div id='tooltip'>${content}</div>`;
  }

  async function load() {
    setBusy(true);
    try {
      const _data = [
        [
          "Month",
          "Revenue",
          // { id: "iBottom", type: "number", role: "interval" },
          // { id: "iTop", type: "number", role: "interval" },
          { type: "string", role: "tooltip", p: { html: true } },
        ],
      ] as any;
      // Load chart data
      const analytics = await GetAnalytics(
        moment().subtract(5, "months").startOf("month").toDate().getTime(),
        new Date().getTime()
      );
      const _dates = Object.values(analytics.history);
      let _totalRev = 0;
      let currMonth = moment().subtract(5, "months").startOf("month");
      while (currMonth.isSameOrBefore(moment(), "month")) {
        // We process the currMath
        const mLabel = currMonth.format("MMM");
        // Get the total sales between currMonth and end of it's month
        const tStart = moment(currMonth).toDate().getTime();
        const tEnd = moment(currMonth).endOf("month").toDate().getTime();

        const mKeys = _dates.filter((d) => d.date >= tStart && d.date <= tEnd);

        let mTotal = 0;
        for (let _date of mKeys) {
          mTotal += _date.total;
        }
        _data.push([
          mLabel,
          mTotal,
          createTooltip(
            `You earned ${Moneyfy(mTotal)} in ${currMonth.format("MMMM")}.`
          ),
        ]);
        _totalRev += mTotal;
        // Increment month
        currMonth = currMonth.add(1, "month");
      }
      // Add an extra value. This is required for our gradient to work.
      _data.push(["", 0, ""]);
      setChartData(_data);
      setRefresh(refresh + 1);
      setTotalRevenue(_totalRev);
    } catch (err: any) {
      console.log(err);
      enqueueSnackbar(
        "Error loading chart data. Please contact administrators.",
        { variant: "error" }
      );
    }
    setBusy(false);
  }

  function onChartLoaded(containerId: any) {
    // Get the chart container
    if (!containerId) return;
    const container = document.getElementById(containerId);
    if (!container) {
      enqueueSnackbar("Container not found.");
      return;
    }
    var observer = new MutationObserver(function () {
      container
        .getElementsByTagName("svg")[0]
        .setAttribute("xmlns", "http://www.w3.org/2000/svg");
      Array.prototype.forEach.call(
        container.getElementsByTagName("path"),
        function (rect) {
          rect.setAttribute("fill", "url(#my-gradient) #6dc881");
          rect.setAttribute("fill-opacity", "1");
        }
      );
    });
    observer.observe(container, {
      childList: true,
      subtree: true,
    });
  }

  React.useEffect(() => {
    load();
  }, [days]);

  return (
    <Paper
      id="dash_rev"
      {...props}
      sx={{
        height: "304px",
        minHeight: "304px",
        background: "white",
        borderRadius: "18px",
        ...props.sx,
      }}
      elevation={0}
    >
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          pt: "24px",
          fontFamily: "'Inter'",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          sx={{ px: "28px" }}
        >
          <Stack direction="row" alignItems="center" spacing={"8px"}>
            <Stack
              sx={{
                background: "#FFE9EA",
                width: "32px",
                height: "32px",
                borderRadius: "50%",
              }}
              alignItems="center"
              justifyContent="center"
            >
              <Favorite sx={{ color: "#6DC881" }} />
            </Stack>
            <Typography
              variant="h4"
              sx={{
                fontSize: "15px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Revenues
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={"2px"}
            sx={{ display: "none" }}
          >
            <Button
              sx={{ height: "20px", minWidth: "30px", width: "30px" }}
              variant={days === 3 ? "contained" : "outlined"}
              onClick={() => setDays(3)}
            >
              3d
            </Button>
            <Button
              sx={{ height: "20px", minWidth: "30px", width: "30px" }}
              variant={days === 7 ? "contained" : "outlined"}
              onClick={() => setDays(7)}
            >
              7d
            </Button>
            <Button
              sx={{ height: "20px", minWidth: "30px", width: "30px" }}
              variant={days === 14 ? "contained" : "outlined"}
              onClick={() => setDays(14)}
            >
              14d
            </Button>
            <Button
              sx={{ height: "20px", minWidth: "30px", width: "30px" }}
              variant={days === 30 ? "contained" : "outlined"}
              onClick={() => setDays(30)}
            >
              30d
            </Button>
            <Button
              sx={{ height: "20px", minWidth: "30px", width: "30px" }}
              variant={days === 90 ? "contained" : "outlined"}
              onClick={() => setDays(90)}
            >
              3m
            </Button>
            <Button
              sx={{ height: "20px", minWidth: "30px", width: "30px" }}
              variant={days === 90 + 90 ? "contained" : "outlined"}
              onClick={() => setDays(90 + 90)}
            >
              6m
            </Button>
            <Button
              sx={{ height: "20px", minWidth: "30px", width: "30px" }}
              variant={days === 360 ? "contained" : "outlined"}
              onClick={() => setDays(360)}
            >
              1y
            </Button>
          </Stack>
        </Stack>
        {busy && <LoadingIndicator />}
        {totalRevenue > 0 && (
          <Chart
            chartEvents={[
              {
                eventName: "animationfinish",
                callback(eventCallbackArgs) {
                  onChartLoaded(
                    eventCallbackArgs.chartWrapper.getContainerId()
                  );
                },
              },
              {
                eventName: "statechange",
                callback(eventCallbackArgs) {
                  onChartLoaded(
                    eventCallbackArgs.chartWrapper.getContainerId()
                  );
                },
              },
              {
                eventName: "ready",
                callback(eventCallbackArgs) {
                  onChartLoaded(
                    eventCallbackArgs.chartWrapper.getContainerId()
                  );
                },
              },
            ]}
            key={refresh}
            chartType="LineChart"
            width="100%"
            height="100%"
            data={chartData}
            options={{
              tooltip: { isHtml: true },
              curveType: "function",
              legend: "none",
              backgroundColor: "transparent",
              colors: ["#6DC881"],
              lineWidth: 2,
              //
              vAxis: {
                width: "100px",
                format: "currency",
                gridlines: { count: 0 },
                textStyle: {
                  fontName: "Inter",
                  fontSize: "12",
                  color: "#7C828A",
                },
                minValue: 0,
                baselineColor: "transparent",
                viewWindow: {
                  min: 0,
                },
                viewWindowMode: "pretty",
              },
              hAxis: {
                textStyle: {
                  fontName: "Inter",
                  fontSize: "12",
                  color: "#7C828A",
                },
                viewWindowMode: "pretty",
              },
              chartArea: {
                // leave room for y-axis labels
              },
            }}
          />
        )}
        {totalRevenue <= 0 && (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", height: "100%" }}
          >
            {!busy && (
              <Typography textAlign="center">
                Complete your first invoice to see your revenues.
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
    </Paper>
  );
}
