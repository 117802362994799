import {
  Button,
  IconButton,
  List,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { PAYANT_ICON } from "../assets/assets";
import { AppConfig } from "../config";
import {
  FbGetUser,
  FbSetClient,
  FbSetInvoice,
  FbSetProfile,
} from "../core/firebase";
import { BasicDialogProps } from "../models/DialogProps";
import { INVOICE_STATUS, Invoice } from "payant-lib";
import PayantStepper from "../components/stepper";
import PayantTextField from "../components/textfield";
import { DatePicker } from "@mui/x-date-pickers";
import { Moneyfy, printInvoice } from "../core/helper";
import {
  ArrowBackRounded,
  ArrowBackSharp,
  ArrowForwardRounded,
  CloseSharp,
  Delete,
  FileDownload,
} from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import PrintableInvoice from "../components/printableInvoice";
import { SendInvoice } from "../core/api";
import HowPayantWorksStepper from "../components/howItWorksProgressBar";

export default function Onboarding(props: BasicDialogProps) {
  const [client, setClient] = useState<any>();
  const [busy, setBusy] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [step, setStep] = useState(2);
  const [selectedItem, setSelectedItem] = useState(0); // out of 3
  const [invoice, setInvoice] = React.useState<Invoice>();

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  const [endDate, setEndDate] = React.useState<any>(
    moment().format("MM/DD/YYYY")
  );
  const [profile, setProfile] = React.useState<any>({});

  /**
   * Validates the business profile for step 1
   */
  function validateProfile() {
    if (!profile.businessName) {
      enqueueSnackbar("Please enter your business name.", { variant: "error" });
      return;
    }
    if (!profile.address) {
      enqueueSnackbar("Please enter your business address.", {
        variant: "error",
      });
      return;
    }
    if (!profile.city) {
      enqueueSnackbar("Please enter your city.", { variant: "error" });
      return;
    }
    if (!profile.country) {
      enqueueSnackbar("Please enter your business country.", {
        variant: "error",
      });
      return;
    }
    setStep(6);
  }

  async function saveEx() {
    if (!profile.businessName) {
      enqueueSnackbar("Please enter your business name.", { variant: "error" });
      return;
    }
    if (!profile.address) {
      enqueueSnackbar("Please enter your business address.", {
        variant: "error",
      });
      return;
    }
    if (!profile.city) {
      enqueueSnackbar("Please enter your city.", { variant: "error" });
      return;
    }
    if (!profile.country) {
      enqueueSnackbar("Please enter your business country.", {
        variant: "error",
      });
      return;
    }
    try {
      setBusy(true);

      // profile must be valid
      const r = await FbSetProfile({
        address: profile.address,
        boarded: true,
        businessName: profile.businessName,
        city: profile.city,
        country: profile.country,
        dateRegistered: new Date().getTime(),
        ...(profile.registrationNumber
          ? { registrationNumber: profile.registrationNumber }
          : {}),
        ...(profile.vatId ? { vatId: profile.vatId } : {}),
        ...(profile.taxId ? { taxId: profile.taxId } : {}),
        ...(profile.legalMentions
          ? { legalMentions: profile.legalMentions }
          : {}),
      } as any);
      if (!Boolean(r)) {
        throw new Error("Error creating profile.");
      }
      enqueueSnackbar("Business details have been updated.", {
        variant: "success",
      });
      enqueueSnackbar("Welcome to Payant.");
      props.closeHandler();
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please contact administrators.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  /**
   * Creates the profile.
   */
  async function createProfile() {
    // profile must be valid
    const r = await FbSetProfile({
      address: profile.address,
      boarded: true,
      businessName: profile.businessName,
      city: profile.city,
      country: profile.country,
      dateRegistered: new Date().getTime(),
      ...(profile.registrationNumber
        ? { registrationNumber: profile.registrationNumber }
        : {}),
      ...(profile.vatId ? { vatId: profile.vatId } : {}),
      ...(profile.taxId ? { taxId: profile.taxId } : {}),
      ...(profile.legalMentions
        ? { legalMentions: profile.legalMentions }
        : {}),
    } as any);
    if (!Boolean(r)) {
      throw new Error("Error creating profile.");
    }
  }

  /**
   * Validates the client details.
   */
  function validateClient() {
    if (!client?.name) {
      enqueueSnackbar("Please enter your client's name.", { variant: "error" });
      return;
    }
    if (!client?.address) {
      enqueueSnackbar("Please enter your client's address.", {
        variant: "error",
      });
      return;
    }
    if (!client?.city) {
      enqueueSnackbar("Please enter your client's city.", { variant: "error" });
      return;
    }
    if (!client?.country) {
      enqueueSnackbar("Please enter your client's country.", {
        variant: "error",
      });
      return;
    }
    if (!client?.email) {
      enqueueSnackbar("Please enter your client's email.", {
        variant: "error",
      });
      return;
    }
    setStep(step + 1);
  }

  /**
   * Creates a new client.
   * @returns
   */
  async function createClient() {
    // add client
    // Validate some fields
    if (!client) {
      enqueueSnackbar("Error: Please enter information about client.", {
        variant: "error",
      });
      return;
    }
    if (!client.name) {
      enqueueSnackbar("Error: Please enter the name of the client.", {
        variant: "error",
      });
      return;
    }
    if (!client.email) {
      enqueueSnackbar("Error: Please enter the email of the client.", {
        variant: "error",
      });
      return;
    }
    // Create the client
    const resp = await FbSetClient(client);
    setClient(resp);
  }

  /**
   * Validates the invoice items as well as calculates the totals
   * @returns
   */
  function validateInvoice() {
    // Make sure the invoice has valid dates.
    if (!invoice?.items || invoice.items.length === 0) {
      enqueueSnackbar("Please add at least 1 item to the invoice.", {
        variant: "error",
      });
      return;
    }
    // Check dates, prices, and calculate totals.
    const dEnd = moment(endDate, "DD-MM-YYYY");

    if (!dEnd.isValid()) {
      enqueueSnackbar("Invalid end date.", {
        variant: "error",
      });
      return;
    }

    for (let item of invoice.items) {
      if (!item.price) {
        enqueueSnackbar("Please enter a price for all the items.", {
          variant: "error",
        });
        return;
      }
    }
    invoice.total = getTotal();
    // pass
    setInvoice({
      ...invoice,
      fee: 0,
      endDate: dEnd.toDate().getTime(),
    });
    // Go to next step
    setStep(step + 1);
  }

  /**
   * Creates the invoice
   */
  async function createInvoice() {
    if (!invoice) {
      throw new Error("Internal error. Unable to create invoice. ");
    }
    // Send the create request to backend.
    invoice.ownerId = FbGetUser()!.uid;
    invoice.dateCreated = new Date().getTime();
    invoice.dateUpdated = new Date().getTime();
    invoice.status = INVOICE_STATUS.REVIEW;
    invoice.client = client;
    invoice.contractor = profile;
    const inv = await FbSetInvoice(invoice!);
    setInvoice(inv);
    // Send the quote
    if (AppConfig.mode === "PRODUCTION") {
      await SendInvoice(inv.id);
    }
  }

  /**
   * Saves everything after validation
   */
  async function save() {
    setBusy(true);
    try {
      // Validate
      await validateProfile();
      // save profile
      await createProfile();
      // save client
      await createClient();
      // save invoice
      await createInvoice();
      // set to step 6
      setStep(step + 1);
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please contact administrators.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  function getTotal() {
    let i = 0;
    for (let c of invoice!.items) {
      i += c.price || 0;
    }
    // Add VAT
    if (i === 0) return 0;
    // 1. Find the % of total
    const perc = (i * (invoice?.vat || 0)) / 100;
    // 2. Add it to total
    return i + perc;
  }

  const elementRef = React.useRef<any>();
  const triggerPrint = useReactToPrint({
    content: () => elementRef.current,
    copyStyles: true,
    //pageStyle: "font-family: 'Inter'",
    onBeforeGetContent: () => {
      setBusy(true);
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      setBusy(false);
    },

    async print(target: any) {
      await printInvoice((invoice!.name || invoice!.id) + ".pdf", target);
    },
  });

  /**
   * Deletes the item from invoice.
   * @param itemNumber
   */
  function removeItem(itemNumber: number) {
    if (invoice?.items.length && invoice.items.length > 1) {
      invoice?.items.splice(itemNumber, 1);
      setInvoice(invoice);
      // If the selected item was the last item, we set selected item to -1
      if (selectedItem === invoice.items.length) {
        setSelectedItem(selectedItem - 1);
      }
    }
  }

  useEffect(() => {
    setInvoice({ items: [{}] } as any);
  }, []);

  function RenderWorkItemButton(num: number, allowDelete: Boolean = false) {
    return (
      <Button
        variant="text"
        sx={{
          mx: "1px",
          my: "1px",
          borderRadius: "0px",
          borderBottom: selectedItem === num ? `4px solid #A882F3` : "0px",
        }}
        onClick={() => setSelectedItem(num)}
      >
        Item # {num + 1}
        {selectedItem !== num && allowDelete && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              removeItem(num);
            }}
            variant="contained"
            sx={{
              zIndex: 10,
              p: 0,
              my: 0,
              mx: "4px",
              minWidth: "24px",
              width: "24px",
              minHeight: "24px",
              height: "24px",
            }}
          >
            <Tooltip title="Delete item">
              <Delete sx={{ width: "18px", height: "18px" }} />
            </Tooltip>
          </Button>
        )}
      </Button>
    );
  }

  return (
    // The parent stack holds the stepper for showing progress
    <Stack key={step}>
      <Stack
        direction="row"
        sx={{
          width: "100%",
          position: "absolute",
          p: "15px",
        }}
        justifyContent="space-between"
      >
        <Tooltip title="Back">
          <IconButton
            sx={{ opacity: step === 0 || step === 1 || step === 6 ? 0 : 1 }}
            disabled={step === 1 || step === 6}
            onClick={() => setStep(step - 1)}
          >
            <ArrowBackSharp color="action" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Close">
          <IconButton onClick={props.closeHandler}>
            <CloseSharp color="action" />
          </IconButton>
        </Tooltip>
      </Stack>
      {/* Step one: welcome msg  */}

      {step === 0 && (
        <Stack
          spacing={"36px"}
          alignItems="center"
          sx={{ px: "45px", background: "#FFF", py: "35px" }}
        >
          <img src={PAYANT_ICON} width="60px" height="60px" alt="Payant Logo" />
          <Typography fontSize={"18px"} fontWeight={600}>
            Welcome to Payant!
          </Typography>
          <Typography
            fontSize={"14px"}
            textAlign="center"
            color="#8B8B8B"
            fontWeight={400}
          >
            You're just a few steps away from creating your first smart invoice!
          </Typography>
          <Button
            variant="contained"
            sx={{ borderRadius: "25px" }}
            onClick={() => setStep(step + 1)}
          >
            Get Started
          </Button>
        </Stack>
      )}

      {step === 1 && (
        <Stack
          spacing={isDesktop ? "36px" : "12px"}
          alignItems="center"
          sx={{
            px: isDesktop ? "35px" : "12px",
            background: "#FFF",
            py: "35px",
          }}
        >
          {/* <img src={PAYANT_ICON} width="60px" height="60px" alt="Payant Logo" /> */}
          <Typography fontSize={"16px"} fontWeight={600}>
            How Payant Works
          </Typography>

          <HowPayantWorksStepper
            sx={{ width: "100%" }}
            activeStep={5}
            labels={[
              "Create a Proposal, it’s then sent to your client for approval.",
              "Once approved, you create a Smart Invoice, on-chain.",
              "Your client deposits the funds in the Smart Invoice.",
              "You do the work and share it with your client.",
              "Your client approves your work. The funds are unlocked.",
              "Withdraw your funds!",
            ]}
          />

          <Button
            variant="contained"
            sx={{ borderRadius: "25px" }}
            onClick={() => setStep(step + 1)}
          >
            Create First Invoice
          </Button>
        </Stack>
      )}

      {/* Step two: Business details  */}
      {step === 2 && (
        <Stack
          id="onb_biz"
          sx={{
            px: isDesktop ? "35px" : "12px",
            background: "#FFFFFF",
            pb: "24px",
            pt: "24px",
          }}
        >
          <PayantStepper
            stepsCount={2}
            active={1}
            sx={{ p: 0, m: 0, px: "24px", width: "100%" }}
          />
          <div style={{ marginTop: "8px" }} />
          <Typography fontSize={"16px"} textAlign="center" fontWeight={600}>
            Add Business Details
          </Typography>
          <Typography
            fontWeight={400}
            fontSize="12px"
            sx={{ alignSelf: "center", textAlign: "center" }}
          >
            Create your business profile that will be added to all your
            invoices.
          </Typography>
          <PayantTextField
            required
            label="Legal Business Name"
            sx={{ mt: "15px" }}
            value={profile?.businessName}
            onUpdate={(txt) => setProfile({ ...profile, businessName: txt })}
          />
          <PayantTextField
            required
            label="Address"
            sx={{ mt: "10px" }}
            value={profile?.address}
            onUpdate={(txt) => setProfile({ ...profile, address: txt })}
          />
          <PayantTextField
            required
            label="City"
            sx={{ mt: "10px" }}
            value={profile?.city}
            onUpdate={(txt) => setProfile({ ...profile, city: txt })}
          />
          <PayantTextField
            required
            label="Country"
            sx={{ mt: "10px" }}
            value={profile?.country}
            onUpdate={(txt) => setProfile({ ...profile, country: txt })}
          />
          <PayantTextField
            label="Registration Number (optional)"
            sx={{ mt: "10px" }}
            value={profile?.registrationNumber}
            onUpdate={(txt) =>
              setProfile({ ...profile, registrationNumber: txt })
            }
          />
          <PayantTextField
            label="Tax ID (optional)"
            sx={{ mt: "10px" }}
            value={profile?.taxId}
            onUpdate={(txt) => setProfile({ ...profile, taxId: txt })}
          />
          <PayantTextField
            label="VAT ID (optional)"
            sx={{ mt: "10px" }}
            value={profile?.vatId}
            onUpdate={(txt) => setProfile({ ...profile, vatId: txt })}
          />
          <PayantTextField
            id="onb_legal"
            label="Legal Mentions (optional)"
            multiline
            maxRows={2}
            rows={2}
            sx={{ mt: "10px" }}
            value={profile?.legalMentions}
            onUpdate={(txt) => setProfile({ ...profile, legalMentions: txt })}
          />
          <Button
            id="onb_biz_save"
            disabled={busy}
            variant="contained"
            sx={{ mt: "24px", borderRadius: "25px" }}
            onClick={saveEx}
          >
            {busy ? "Please wait..." : "Save"}
          </Button>
        </Stack>
      )}

      {/* Step three: First client  */}
      {step === 3 && (
        <Stack
          spacing={"10px"}
          sx={{
            px: isDesktop ? "35px" : "12px",
            background: "#FFFFFF",
            pb: "35px",
            pt: "24px",
          }}
        >
          <PayantStepper
            stepsCount={5}
            active={2}
            sx={{ p: 0, m: 0, px: "24px", width: "100%" }}
          />
          <div style={{ marginTop: "8px" }} />
          <Typography
            fontWeight={600}
            textAlign="center"
            sx={{ mx: "3px" }}
            fontSize="16px"
          >
            Add First Client
          </Typography>

          <Typography
            fontWeight={400}
            fontSize="12px"
            sx={{ alignSelf: "center", textAlign: "center" }}
          >
            Time to create your first client, add its details below.
          </Typography>

          <PayantTextField
            required
            value={client?.name}
            onChange={(c) =>
              setClient({ ...(client as any), name: c.currentTarget.value })
            }
            label="Name"
          />
          <PayantTextField
            required
            label="Email"
            value={client?.email}
            onChange={(c) =>
              setClient({ ...(client as any), email: c.currentTarget.value })
            }
          />
          <PayantTextField
            required
            label="Address"
            value={client?.address}
            onChange={(c) =>
              setClient({ ...(client as any), address: c.currentTarget.value })
            }
          />
          <PayantTextField
            required
            label="City"
            value={client?.city}
            onChange={(c) =>
              setClient({ ...(client as any), city: c.currentTarget.value })
            }
          />
          <PayantTextField
            required
            label="Country"
            value={client?.country}
            onChange={(c) =>
              setClient({ ...(client as any), country: c.currentTarget.value })
            }
          />
          <PayantTextField
            label="Registration Number (optional)"
            value={client?.registrationNumber}
            onChange={(c) =>
              setClient({
                ...(client as any),
                registrationNumber: c.currentTarget.value,
              })
            }
          />
          <PayantTextField
            label="TAX ID (Optional)"
            value={client?.taxId}
            onChange={(c) =>
              setClient({ ...(client as any), taxId: c.currentTarget.value })
            }
          />
          <PayantTextField
            label="VAT ID (Optional)"
            value={client?.vatId}
            onChange={(c) =>
              setClient({ ...(client as any), vatId: c.currentTarget.value })
            }
          />
          <Button
            variant="contained"
            sx={{ mt: "40px", borderRadius: "25px" }}
            disabled={busy}
            onClick={validateClient}
          >
            Next
          </Button>
        </Stack>
      )}

      {/* Step four: Invoice and its items:  */}
      {step === 4 && (
        <Stack
          spacing={"10px"}
          key={selectedItem}
          sx={{
            px: isDesktop ? "35px" : "12px",
            background: "#FFFFFF",
            pb: "35px",
            pt: "24px",
          }}
        >
          <PayantStepper
            stepsCount={5}
            active={3}
            sx={{ p: 0, m: 0, px: "24px", width: "100%" }}
          />
          <div style={{ marginTop: "8px" }} />
          <Typography textAlign="center" fontWeight={600} fontSize="16px">
            Create First Proposal
          </Typography>
          <Typography
            fontWeight={400}
            fontSize="12px"
            sx={{ alignSelf: "center", textAlign: "center" }}
          >
            Create your first proposal to get your client's approval.
          </Typography>
          <Stack direction="row" spacing={"10px"}>
            <div style={{ flex: 1 }}>
              <PayantTextField
                required
                fullWidth
                value={invoice?.name}
                onUpdate={(txt) =>
                  setInvoice({ ...(invoice as any), name: txt })
                }
                label="Proposal Name"
              />
            </div>
            <div style={{ flex: 1 }}>
              <DatePicker
                label="Due Date"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => (
                  <PayantTextField
                    fullWidth
                    required
                    value={endDate}
                    onBlur={(e) => setEndDate(e.currentTarget.value)}
                    // onUpdate={(xt) => setStartDate(xt)}
                    label="Due Date"
                    placeholder="DD-MM-YYYY"
                    sx={{ flex: 1 }}
                    {...params}
                  />
                )}
              />
            </div>
          </Stack>
          <Stack
            direction="row"
            sx={{ width: "100%" }}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <List sx={{ flex: 1 }} component={"nav"}>
              {invoice?.items.map((i, j) => RenderWorkItemButton(j))}
            </List>
            <Button
              sx={{ my: "1px" }}
              onClick={() => {
                invoice?.items.push({} as any);
                setInvoice({ ...(invoice as any) });
              }}
              variant="text"
            >
              Add Item
            </Button>
          </Stack>
          <Stack direction={"row"} spacing={"10px"}>
            <PayantTextField
              fullWidth
              value={invoice?.items[selectedItem]?.name}
              onUpdate={(txt) => {
                invoice!.items[selectedItem].name = txt;
                setInvoice({ ...(invoice as any) });
              }}
              label="Item Name"
              required
            />
            <PayantTextField
              fullWidth
              required
              value={invoice?.items[selectedItem]?.price?.toString()}
              onUpdate={(txt) => {
                if (parseFloat(txt) > 0) {
                  invoice!.items[selectedItem].price = parseFloat(txt);
                } else {
                  invoice!.items[selectedItem].price = 0;
                }
                setInvoice({ ...(invoice as any) });
              }}
              InputProps={{ endAdornment: <Typography>$</Typography> }}
              label="Item Price"
              placeholder="$24"
              sx={{ marginTop: "10px" }}
            />
          </Stack>

          <PayantTextField
            key={selectedItem}
            value={invoice?.items[selectedItem]?.description}
            onUpdate={(txt) => {
              invoice!.items[selectedItem].description = txt;
              setInvoice({ ...(invoice as any) });
            }}
            rows={5}
            maxRows={5}
            multiline
            label="Item Description"
          />
          <Stack direction="row" spacing={"10px"}>
            <PayantTextField
              fullWidth
              required
              value={invoice?.vat?.toString()}
              onUpdate={(txt) => {
                if (parseFloat(txt) > 0) {
                  invoice!.vat = parseFloat(txt);
                } else {
                  invoice!.vat = 0;
                }
                setInvoice({ ...(invoice as any) });
              }}
              InputProps={{ endAdornment: <Typography>%</Typography> }}
              label="VAT %"
            />
            <PayantTextField
              value={Moneyfy(getTotal())}
              InputProps={{ endAdornment: <Typography>$</Typography> }}
              label="Total"
            />
          </Stack>
          <Button
            variant="contained"
            sx={{ mt: "20px", borderRadius: "25px" }}
            onClick={validateInvoice}
          >
            Next
          </Button>
        </Stack>
      )}

      {/* Step five: review  */}
      {step === 5 && (
        <Stack
          sx={{
            px: isDesktop ? "35px" : "12px",
            background: "#FFFFFF",
            pb: "35px",
            pt: "24px",
          }}
          spacing={"15px"}
        >
          <PayantStepper
            stepsCount={5}
            active={4}
            sx={{ p: 0, m: 0, px: "24px", width: "100%" }}
          />
          <div style={{ marginTop: "8px" }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight={600} fontSize="16px">
              Review Proposal
            </Typography>
            <Button
              disabled={busy}
              onClick={triggerPrint}
              variant="text"
              endIcon={<FileDownload />}
            >
              Download Proposal
            </Button>
          </Stack>
          <Stack direction="row" spacing={"24px"} sx={{ mt: "15px" }}>
            <PayantTextField
              fullWidth
              value={client?.name || ""}
              label="Client's Name"
            />
            <PayantTextField
              fullWidth
              value={invoice?.name || ""}
              label="Proposal Name"
            />
          </Stack>
          <Stack direction="row" spacing={"24px"} sx={{ mt: "15px" }}>
            <PayantTextField
              fullWidth
              value={Moneyfy(invoice?.total || 0)}
              label="Total"
            />
            <PayantTextField
              fullWidth
              value={
                invoice?.endDate
                  ? moment(new Date(invoice!.endDate)).format("Do MMM. YYYY")
                  : ""
              }
              label="Deadline"
            />
          </Stack>
          <List component={"nav"}>
            {invoice?.items.map((i, j) => RenderWorkItemButton(j, false))}
          </List>

          <Stack direction="row" spacing={"10px"}>
            <PayantTextField
              fullWidth
              value={invoice?.items[selectedItem]?.name || ""}
              label="Item Name"
            />

            <PayantTextField
              fullWidth
              value={"$" + invoice?.items[selectedItem]?.price || "$0"}
              label="Item Price"
            />
          </Stack>
          <PayantTextField
            value={invoice?.items[selectedItem]?.description || ""}
            rows={6}
            maxRows={6}
            multiline
            label="Item Description"
          />
          {invoice?.items.length && invoice.items.length > 1 && (
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="center"
            >
              <Tooltip title="Previous item">
                <IconButton
                  disabled={selectedItem === 0}
                  onClick={() => setSelectedItem(selectedItem - 1)}
                >
                  <ArrowBackRounded />
                </IconButton>
              </Tooltip>
              <Tooltip title="Next item">
                <IconButton
                  onClick={() => setSelectedItem(selectedItem + 1)}
                  disabled={selectedItem + 1 >= (invoice?.items.length || 1)}
                >
                  <ArrowForwardRounded />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
          <Button
            variant="contained"
            fullWidth
            disabled={busy}
            sx={{ borderRadius: "25px" }}
            onClick={save}
          >
            Send Proposal
          </Button>

          {/* <Stack
            justifyContent="center"
            alignItems={"center"}
            direction="row"
            spacing={1}
            sx={{ marginTop: "18px" }}
          >
            <Typography sx={{ color: "#373737" }} fontSize={"12px"}>
              Not Familiar with Web3 Wallets?
            </Typography>
            <Typography
              fontSize={"12px"}
              color="primary"
              sx={{ cursor: "pointer" }}
            >
              Read Our Guide
            </Typography>
          </Stack> */}
        </Stack>
      )}

      {step === 6 && (
        <Stack
          sx={{
            px: isDesktop ? "35px" : "12px",
            py: "40px",
            background: "#FFFFFF",
          }}
        >
          <Typography textAlign="center" fontWeight={600} fontSize="16px">
            Welcome To Payant 🎉
          </Typography>
          <Typography
            textAlign="center"
            fontSize="14px"
            sx={{
              marginTop: "14px",
              textAlign: isDesktop ? "start" : "center",
            }}
          >
            You are now ready to add a client and create proposals. Good luck.
          </Typography>
          <Button
            variant="contained"
            sx={{ mt: "40px", borderRadius: "25px" }}
            onClick={() => props.closeHandler()}
          >
            Discover Dashboard
          </Button>
        </Stack>
      )}

      <div
        style={{
          position: "absolute",
          overflow: "hidden",
          height: 0,
          width: 0,
          zIndex: -999,
        }}
      >
        <div ref={elementRef}>
          <PrintableInvoice invoice={invoice!} />
        </div>
      </div>
    </Stack>
  );
}
