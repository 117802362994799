import {
  createClient,
  cacheExchange,
  fetchExchange,
  Client,
  createRequest,
} from "urql";
import { AppConfig } from "../config";
import { gql } from "@urql/core";

let client: Client;

/**
 * Returns the information about dispute on kleros subgraph.
 * @param disputeId
 */
export async function GetDisputeStatus(disputeId: any) {
  console.log("Getting dispute info on dispute id: " + disputeId);
  const tokensQuery = gql`
    query Dispute($id: ID!) {
      dispute(id: $id) {
        id
        disputeID
        creator {
          id
        }
        subcourtID {
          id
        }
        startTime
        appealed
        ruled
        currentRulling
        txid
      }
    }
  `;

  if (!client) {
    client = createClient({
      url: AppConfig.kleros.subgraph,
      exchanges: [fetchExchange],
    });
  }

  const req = createRequest(tokensQuery, { id: disputeId });

  const data = await client.executeQuery(req).toPromise();
  return data;
}
