import { atom } from "recoil";

/**
 * Stores the information on the current logged in user.
 */
export const aPageTitle = atom<string | null>({
  key: "payantCurrentSection",
  default: null,
});

export const aShowSidebar = atom<boolean | null>({
  key: "payantShowSidebar",
  default: false,
});
