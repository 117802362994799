import { Button, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { FbChangePassword } from "../core/firebase";
import NextButton from "./nextButton";
import PayantTextField from "./textfield";

export default function FragChangePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassConfirm, setNewPassConfirm] = useState("");
  const [busy, setBusy] = useState(false);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  const { enqueueSnackbar } = useSnackbar();

  function isFormValid() {
    return Boolean(newPass && currentPassword && newPassConfirm);
  }

  async function changePass() {
    setBusy(true);
    try {
      if (newPass !== newPassConfirm) {
        throw new Error("Passwords do not match.");
      }
      // Validate new password
      if (newPass.length < 8) {
        throw new Error("Password must be at least 8 characters long.");
      }
      // Change password
      await FbChangePassword(currentPassword, newPass).catch((err) => {
        throw new Error("Error changing password. ");
      });
      enqueueSnackbar("Password changed successfully.", {
        variant: "success",
      });
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setBusy(false);
  }

  return (
    <Paper
      elevation={0}
      sx={{
        px: "25px",
        height: "314px",
        py: isDesktop ? "40px" : "18px",
        width: isDesktop ? "500px" : "auto",
        borderRadius: "18px",
      }}
    >
      <Stack spacing={"11px"}>
        <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
          Change Password
        </Typography>

        <PayantTextField
          required
          label="Existing Password"
          type="password"
          value={currentPassword}
          onUpdate={(txt) => setCurrentPassword(txt)}
        />
        <PayantTextField
          required
          label="New Password"
          type="password"
          value={newPass}
          onUpdate={(txt) => setNewPass(txt)}
        />
        <PayantTextField
          required
          type="password"
          label="Confirm New Password"
          value={newPassConfirm}
          onUpdate={(txt) => setNewPassConfirm(txt)}
        />
        <Button
          variant="contained"
          disabled={busy || !isFormValid()}
          sx={{
            height: "44px",
            width: "220px",
            borderRadius: "25px",
            alignSelf: "center",
          }}
          onClick={changePass}
        >
          Change Password
        </Button>
      </Stack>
    </Paper>
  );
}
