import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import PayantTextField from "../components/textfield";
import { FbSetClient } from "../core/firebase";
import { Client } from "payant-lib";
import { BasicDialogProps } from "../models/DialogProps";
import { TransparentPaper } from "../components/transPaper";

export default function EditClientDialog(props: BasicDialogProps) {
  const [client, setClient] = useState(props.data as Client);
  const [busy, setBusy] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  async function save() {
    setBusy(true);
    try {
      const _prof = await FbSetClient(client);
      if (_prof) {
        setClient(_prof);
        enqueueSnackbar("Client details updated successfully.", {
          variant: "success",
        });
        props.closeHandler();
      } else {
        throw new Error("Unknown error setting profile.");
      }
    } catch (err: any) {
      enqueueSnackbar("Error saving details. See logs for details.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  return (
    <Dialog
      open={props.isOpen}
      sx={{ backdropFilter: "blur(2px)" }}
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth={isDesktop ? false : true}
    >
      <DialogContent sx={{ p: 0, ...(isDesktop ? { width: "466px" } : {}) }}>
        <Stack
          sx={{ px: "25px", py: "40px", background: "white" }}
          spacing={"8px"}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              alignSelf: "center",
              mt: "10px",
            }}
          >
            Edit Client Details
          </Typography>
          {/* Name*/}
          <PayantTextField
            required
            label="Client Name"
            value={client.name || ""}
            onUpdate={(txt) => setClient({ ...(client as any), name: txt })}
          />

          {/* Email  */}
          <PayantTextField
            required
            label="Email Address"
            value={client.email || ""}
            onUpdate={(txt) => setClient({ ...(client as any), email: txt })}
          />

          {/* Address  */}
          <PayantTextField
            label="Address"
            required
            value={client.address || ""}
            onUpdate={(txt) => setClient({ ...(client as any), address: txt })}
          />

          {/* City  */}
          <PayantTextField
            required
            label="City"
            value={client.city || ""}
            onUpdate={(txt) => setClient({ ...(client as any), city: txt })}
          />

          {/* Country  */}
          <PayantTextField
            required
            label="Country"
            value={client.country || ""}
            onUpdate={(txt) => setClient({ ...(client as any), country: txt })}
          />

          {/* Registration Number  */}
          <PayantTextField
            label="Registration Number (optional)"
            value={client.registrationNumber || ""}
            onUpdate={(txt) =>
              setClient({ ...(client as any), registrationNumber: txt })
            }
          />

          {/* Tax  */}
          <PayantTextField
            label="Tax ID"
            value={client.taxId || ""}
            onUpdate={(txt) => setClient({ ...(client as any), taxId: txt })}
          />
          {/* Vat  */}
          <PayantTextField
            label="VAT ID"
            value={client.vatId || ""}
            onUpdate={(txt) => setClient({ ...(client as any), vatId: txt })}
          />

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={"24px"}
            sx={{ pt: "22px" }}
          >
            <Button
              onClick={() => props.closeHandler()}
              variant="outlined"
              sx={{ height: "44px", width: "150px", borderRadius: "25px" }}
            >
              Back
            </Button>
            <Button
              disabled={busy}
              onClick={save}
              variant="contained"
              sx={{ height: "44px", width: "150px", borderRadius: "25px" }}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
