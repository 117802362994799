import {
  CircleOutlined,
  CheckCircleOutlineOutlined,
  Brightness1,
} from "@mui/icons-material";
import {
  Checkbox,
  checkboxClasses,
  CheckboxProps,
  Icon,
  styled,
} from "@mui/material";
import React from "react";

const PayantCheckBox = styled(Checkbox)<CheckboxProps>({
  outlineColor: "red",
  [`.${checkboxClasses.checked}`]: {},
});

export default function PayantCheckbox(props: CheckboxProps) {
  return (
    <PayantCheckBox
      variant="outlined"
      {...(props as any)}
      sx={{ borderRadius: "100px", transition: "all .5s" }}
      icon={
        <Brightness1 sx={{ color: "#F0F0F0", width: "32px", height: "32px" }} />
      }
      checkedIcon={
        <Icon
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#5D18EB",
            width: "32px",
            height: "32px",
            borderRadius: "50%",
            padding: "0px",
            transition: "all .5s",
          }}
        >
          <Brightness1
            sx={{
              margin: 0,
              p: 0,
              color: "#F0F0F0",
              fontSize: "12px",
            }}
          />
        </Icon>
      }
    />
  );
}
