import {
  Close,
  HelpOutlineRounded,
  InsertDriveFileOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  LinkProps,
  List,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PAYANT_LOGO_TRANSPARENT } from "../assets/assets";
import { FbGetProfile, FbGetUser, FbLogout } from "../core/firebase";
import { getAuth } from "firebase/auth";
import {
  ClientsIcon,
  HomeIcon,
  ReferralsIcon,
  SettingsIcon,
  TransactionsIcon,
} from "../assets/icons";
import { useRecoilState } from "recoil";
import { aShowSidebar } from "../states/ui";
import { UserProfile } from "payant-lib";

type NavItemProps = {
  selected: boolean;
};

const NavItem = styled(Link)<NavItemProps & LinkProps>(
  ({ theme, selected }) => ({
    marginTop: "2px",
    textDecoration: "none",
    height: "36px",
    borderRadius: "4px",
    fontSize: "14px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    transition: "all .2s ease",
    width: "207px",
    // Hover state
    "&:hover": {
      color: `#000`,
      background: "#E9E8FC",
      boxShadow: "0 0 2px #0004",
      // Text
      "p:last-child": {
        opacity: 1,
        fontSize: "12px",
        fontWeight: 600,
      },
      // Icon
      "& .NavItemIcon": {
        margin: "10px",
        color: `#000`,
        alignContent: "center",
        width: "16px",
        height: "16px",
      },
    },
    ...(selected
      ? {
          background: "#E9E8FC",
          color: `#000000`,
          boxShadow: "0 0 0px #0004",
          // text
          "p:last-child": {
            fontSize: "12px",
            fontWeight: 600,
          },
          // Icon
          "& .NavItemIcon": {
            margin: "10px",
            width: "16px",
            height: "16px",
          },
        }
      : {
          color: `#7F7F88`,
          // The text
          "p:last-child": {
            fontSize: "12px",
            transition: "all .2s ease",
          },
          // Icon
          "& .NavItemIcon": {
            transition: "all .2s ease",
            margin: "10px",
            width: "16px",
            fill: "#7F7F88",
            height: "16px",
          },
        }),
  })
);

export default function Sidebar() {
  const [user, setUser] = useState("");
  const [profile, setProfile] = useState<UserProfile>();
  const [showSidebar, setShowSidebar] = useRecoilState(aShowSidebar);

  const theme = useTheme();
  const location = useLocation();
  const currentRoute = location.pathname;
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  async function loadProfile() {
    const prof = await FbGetProfile();
    setProfile(prof || undefined);
  }

  React.useEffect(() => {
    getAuth().onAuthStateChanged((a) => {
      setUser(a?.displayName || a?.email || "");
    });
    loadProfile();
  }, []);
  return (
    <Stack
      sx={{
        borderRadius: "0px",
        height: "100%",
        mx: isDesktop ? "24px" : "0px",
        px: isDesktop ? "0px" : "24px",
        background: "#FFF",
      }}
      spacing={0}
    >
      <Stack
        sx={{ width: "100%", marginTop: "19px" }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <img
          style={{
            marginRight: "12px",
          }}
          alt="Payant Logo Transparent"
          src={PAYANT_LOGO_TRANSPARENT}
          width="100px"
        />
        {!isDesktop && showSidebar && (
          <IconButton
            onClick={() => setShowSidebar(false)}
            aria-label="close menu"
          >
            <Close />
          </IconButton>
        )}
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        sx={{ width: "100%", pt: "51px" }}
        spacing="7px"
      >
        <Avatar
          alt={FbGetUser()?.displayName || FbGetUser()?.email || ""}
          src={FbGetUser()?.photoURL || ""}
          variant="rounded"
          sx={{ bgcolor: "#5D18EB", borderRadius: "10px" }}
        >
          P
        </Avatar>
        <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
          {user}
        </Typography>
        <Typography
          onClick={() => FbLogout()}
          sx={{ cursor: "pointer" }}
          fontSize="14px"
          fontWeight="bold"
        >
          (logout)
        </Typography>
      </Stack>

      <List sx={{ overflow: "visible", mt: "8px", flex: 1 }}>
        <NavItem
          id="nav_dash"
          to="dashboard"
          onClick={() => setShowSidebar(false)}
          selected={currentRoute.endsWith("/dashboard")}
        >
          <HomeIcon className="NavItemIcon" />
          <Typography className="NavItemText" sx={{ flex: 1 }}>
            Dashboard
          </Typography>
        </NavItem>
        <NavItem
          id="nav_inv"
          onClick={() => setShowSidebar(false)}
          to="invoices"
          selected={currentRoute.endsWith("/invoices")}
        >
          <InsertDriveFileOutlined
            className="NavItemIcon"
            sx={{ p: 0, m: 0 }}
            viewBox="0 0 24 24"
          />
          <Typography>Invoices</Typography>
        </NavItem>
        <NavItem
          id="nav_prop"
          onClick={() => setShowSidebar(false)}
          to="quotes"
          selected={currentRoute.endsWith("/quotes")}
        >
          <InsertDriveFileOutlined
            className="NavItemIcon"
            sx={{ p: 0, m: 0 }}
            viewBox="0 0 24 24"
          />
          <Typography>Proposals</Typography>
        </NavItem>
        <NavItem
          id="nav_client"
          onClick={() => setShowSidebar(false)}
          to="clients"
          selected={currentRoute.endsWith("/clients")}
        >
          <ClientsIcon className="NavItemIcon" />
          <Typography>Clients</Typography>
        </NavItem>
        <NavItem
          id="nav_tran"
          onClick={() => setShowSidebar(false)}
          to="transactions"
          selected={currentRoute.endsWith("/transactions")}
        >
          <TransactionsIcon className="NavItemIcon" />
          <Typography>Transactions</Typography>
        </NavItem>
        <NavItem
          id="nav_set"
          onClick={() => setShowSidebar(false)}
          to="settings"
          selected={currentRoute.endsWith("/settings")}
        >
          <SettingsIcon className="NavItemIcon" />
          <Typography>Settings</Typography>
        </NavItem>
        <NavItem
          id="nav_ref"
          onClick={() => setShowSidebar(false)}
          to="#"
          selected={currentRoute.endsWith("/referrals")}
        >
          <ReferralsIcon className="NavItemIcon" />
          <Typography>Referrals (coming soon)</Typography>
        </NavItem>
        <NavItem
          id="nav_help"
          onClick={(e) => {
            e.preventDefault();
            window.open("https://payant.zendesk.com", "_blank");
            setShowSidebar(false);
          }}
          to=""
          selected={currentRoute.endsWith("/help")}
        >
          <HelpOutlineRounded
            className="NavItemIcon"
            sx={{ p: 0, m: 0 }}
            viewBox="0 0 24 24"
          />
          <Typography>Help Center</Typography>
        </NavItem>
      </List>

      <Typography
        id="nav_chat"
        onClick={() => {
          (window as any).Intercom("boot", {
            api_base: "https://api-iam.intercom.io",
            app_id: "xrmsb03k",
            ...(profile
              ? {
                  name: profile.fullName,
                  email: profile.emailAddress,
                  created_at: profile.dateRegistered,
                  user_id: FbGetUser()?.uid,
                }
              : {}),
          });
          (window as any).Intercom("show");
        }}
        sx={{ cursor: "pointer", pb: "27px" }}
        fontSize="14px"
        textAlign="center"
        fontWeight="bold"
      >
        Get Help
      </Typography>
    </Stack>
  );
}
