import {
  AppBar,
  Box,
  ClickAwayListener,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { useRecoilState } from "recoil";
import Sidebar from "../components/sidebar";
import SectionClients from "../sections/clients";
import SectionCreateClient from "../sections/create_client";
import SectionNewInvoice from "../sections/create_invoice";
import SectionDashboard from "../sections/dashboard";
import SectionInvoices from "../sections/invoices";
import SectionSettings from "../sections/settings";
import SectionTransactions from "../sections/transactions";
import { aPageTitle, aShowSidebar } from "../states/ui";
import SectionQuotes from "../sections/quotes";

import { Menu } from "@mui/icons-material";
import { PAYANT_LOGO_TRANSPARENT } from "../assets/assets";

/**
 * The dashboard page contains the sidebar and sections, as well as, it defines the sub-routes
 * of the application that are specific to the logged in users only.
 * This page and all the routes in it can only be accessed by the logged in users.
 * @returns
 */
export default function PageDashboard() {
  const [showSidebar, setShowSidebar] = useRecoilState(aShowSidebar);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  const [pageTitle] = useRecoilState(aPageTitle);
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        background: "#FFFFFF",
      }}
      direction="row"
    >
      {/* We don't render the standard sidebar on mobile platform  */}
      {isDesktop && (
        <Box
          sx={{
            zIndex: "99",
            overflow: "visible",
          }}
        >
          <Sidebar />
        </Box>
      )}
      {/* Custom sidebar for mobile  */}
      {!isDesktop && (
        <Box
          sx={{
            zIndex: "99",
            overflow: "visible",
            position: "absolute",
            width: "auto",
            height: "100%",
            transition: "all .3s ease-in",
            top: 0,
            left: showSidebar ? 0 : "-100%",
          }}
        >
          <Sidebar />
        </Box>
      )}
      <Box flex={1}>
        {/* The container below aligns the sections to the middle in case their height/width is smaller than the fixed
        viewport  */}
        <Stack
          sx={{
            height: "100vh",
            width: isDesktop ? "100%" : "100vw",
            py: isDesktop ? "37px" : 0,
            px: isDesktop ? "22px" : 0,
          }}
          justifyContent="center"
          alignItems="center"
        >
          {/* This container is added to create the rounded background for the sections. It is the section container actually. */}
          <Stack
            sx={{
              background: "#fafbfb",
              borderRadius: "18px",
              width: "100%",
              height: "100%",
            }}
          >
            {/* This is where we show the menu button in mobile  */}

            {!isDesktop && (
              <AppBar position="static" sx={{ mb: "16px" }}>
                <Toolbar>
                  <IconButton
                    onClick={() => setShowSidebar(true)}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                  >
                    <Menu />
                  </IconButton>
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ flexGrow: 1 }}
                  >
                    {pageTitle}
                  </Typography>
                  <img
                    style={{
                      marginRight: "12px",
                      alignSelf: "center",
                    }}
                    alt="Payant Logo Transparent"
                    src={PAYANT_LOGO_TRANSPARENT}
                    width="64px"
                  />
                </Toolbar>
              </AppBar>
            )}

            <Routes>
              <Route path="dashboard" element={<SectionDashboard />} />
              <Route path="invoices/create" element={<SectionNewInvoice />} />
              <Route path="invoices" element={<SectionInvoices />} />
              <Route path="quotes" element={<SectionQuotes />} />
              <Route path="quotes/create" element={<SectionNewInvoice />} />
              <Route path="clients" element={<SectionClients />} />
              <Route path="clients/create" element={<SectionCreateClient />} />
              <Route path="transactions" element={<SectionTransactions />} />
              <Route path="settings" element={<SectionSettings />} />
              <Route path="*" element={<Navigate to={"dashboard"} />} />
            </Routes>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}
