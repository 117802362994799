import {
  filledInputClasses,
  inputLabelClasses,
  styled,
  TextField,
  textFieldClasses,
  TextFieldProps,
} from "@mui/material";
import React from "react";
import { AppConfig } from "../config";

const PayantField = styled(TextField)<TextFieldProps>((state) => ({
  " & .MuiFilledInput-root": {
    fontSize: "12px",
    borderRadius: "6px",
    fontWeight: 400,
    background: "#F0F0F0",
  },

  // For some reason, the label is also styled with inputs.
  [`& .${filledInputClasses.error}:not(.${inputLabelClasses.root})`]: {
    background: "#EDBDF833",
    border: "1px solid red",
  },
}));

export default function PayantTextField(
  props: TextFieldProps & {
    onUpdate?: (text: string) => void;
    filled?: boolean;
  }
) {
  return (
    <PayantField
      {...(props as any)}
      variant="filled"
      sx={{
        minHeight: "34px",
        ...props.sx,
        " & .MuiFilledInput-root": {
          border: props.value ? "1px solid #938BEF" : "none",
        },
      }}
      value={props.value}
      defaultValue={props.value}
      InputProps={{
        disableUnderline: true,
        ...props.InputProps,
      }}
      onChange={(c) => {
        if (props.onChange) {
          props.onChange(c);
        }
        if (props.onUpdate) {
          props.onUpdate(c.currentTarget.value);
        }
      }}
    />
  );
}
