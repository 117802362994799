import {
  ArrowBackRounded,
  ArrowForwardRounded,
  Delete,
  FileDownload,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  List,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import PayantStepper from "../components/stepper";
import PayantTextField from "../components/textfield";
import { useLocation, useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { Client, INVOICE_STATUS } from "payant-lib";
import { Invoice } from "payant-lib";
import {
  FbGetClients,
  FbGetProfile,
  FbGetUser,
  FbSetInvoice,
} from "../core/firebase";
import moment from "moment";
import { AppConfig } from "../config";
import NextButton from "../components/nextButton";
import { DatePicker } from "@mui/x-date-pickers";
import { UserProfile } from "payant-lib";
import { useRecoilState } from "recoil";
import { aPageTitle } from "../states/ui";
import { SendInvoice } from "../core/api";
import { GenerateInvoiceNumber, Moneyfy, printInvoice } from "../core/helper";
import { useReactToPrint } from "react-to-print";
import PrintableInvoice from "../components/printableInvoice";
import PageTitle from "../components/pageTitle";
import { getAuth } from "firebase/auth";

export default function SectionNewInvoice() {
  const [currentStep, setCurrentStep] = React.useState(1);
  const [selectedItem, setSelectedItem] = React.useState(0);
  const [busy, setBusy] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [clients, setClients] = React.useState<Client[]>([]);

  const [, setPageTitle] = useRecoilState(aPageTitle);
  const [invoice, setInvoice] = React.useState<Invoice>();
  const [endDate, setEndDate] = React.useState<any>();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  const navigate = useNavigate();
  const loc = useLocation();
  let timer: any;

  /**
   * Saves the changes in the local invoice object to the database.
   */
  async function saveInvoice() {
    try {
      // Make sure invoice is created
      if (!invoice) {
        return;
      }
      // Make sure user has selected a client and typed a name for the invoice
      if (!(invoice.client && invoice.name)) {
        return;
      }
      // We dont save in draft mode if our proposal is in feedback state.
      if (invoice.status === INVOICE_STATUS.REFUSED) {
        return;
      }
      // Make sure we are not already saving.
      // Save the changes to the invoice
      // If the owner id is null, we assign the current user id.
      if (!(invoice.ownerId && invoice.contractor)) {
        invoice.ownerId = FbGetUser()!.uid;
        const profile = await FbGetProfile();
        invoice.contractor = profile as UserProfile;
      }
      // If the creation date is not assigned, we generate it
      if (!invoice.dateCreated) {
        invoice.dateCreated = new Date().getTime();
      }
      // We always set the update time
      invoice.dateUpdated = new Date().getTime();
      // Set date end
      if (endDate) {
        const dEnd = moment(endDate, "MM-DD-YYYY");
        if (!dEnd.isValid()) {
          // enqueueSnackbar(
          //   "Invalid end date. Please fix this to save the quote.",
          //   {
          //     variant: "error",
          //   }
          // );
          return;
        }
        invoice.endDate = dEnd.toDate().getTime();
      }
      if (!invoice.invoiceNumber) {
        // Generate an invoice number.
        const invNum = await GenerateInvoiceNumber(getAuth().currentUser!.uid);
        invoice!.invoiceNumber = invNum;
      }
      // Total
      invoice.total = getTotal();
      invoice.status = INVOICE_STATUS.DRAFT;
      // Save
      const i = await FbSetInvoice(invoice!);
      setInvoice(i);
    } catch (err: any) {
      enqueueSnackbar("Error saving invoice. Please contact administrators.", {
        variant: "error",
      });
      console.log(err);
    }

    return true;
  }

  /**
   * Finalizes the invoice and sends the transactional emails.
   */
  async function sendQuote() {
    try {
      setBusy(true);
      if (timer) clearTimeout(timer);
      // Send the email
      // On production, if email fails, we abort saving.
      invoice!.dateUpdated = new Date().getTime();
      invoice!.status = INVOICE_STATUS.REVIEW;
      await FbSetInvoice(invoice!);
      if (AppConfig.mode === "PRODUCTION") {
        const r = await SendInvoice(invoice!.id!);
        // Make sure the status is success
        if (!r.status) {
          // fail
          throw new Error(
            "Error sending email. Please contact administrators or try again."
          );
        }
      }
      enqueueSnackbar(
        "The proposal has been sent to you and your client. You will receive an email when it has been approved.",
        { variant: "success" }
      );
      setCurrentStep(currentStep + 1);
    } catch (err: any) {
      enqueueSnackbar("Error saving proposal. Please contact administrators.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  async function loadClients() {
    setBusy(true);
    try {
      const clis = await FbGetClients();
      setClients(clis);
    } catch (err: any) {
      enqueueSnackbar("Error loading clients. See logs for details.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  /**
   * Performs input validation, and then moves to the next step.
   * @param step
   */
  async function nextStep(step: number) {
    switch (step) {
      case 2: {
        // Make sure the user has selected a client, and entered invoice name.
        if (!invoice?.name) {
          enqueueSnackbar("Please enter the name for the invoice.", {
            variant: "error",
          });
          return;
        }
        if (!invoice.client) {
          enqueueSnackbar("Please select a client for the invoice. ", {
            variant: "error",
          });
          return;
        }
        // Make sure the user has selected the due date.
        if (!endDate) {
          enqueueSnackbar("Please select a due date for the invoice. ", {
            variant: "error",
          });
          return;
        }
        const dEnd = moment(endDate, "MM-DD-YYYY");
        if (!dEnd.isValid()) {
          enqueueSnackbar("Invalid end date.", {
            variant: "error",
          });
          return;
        }
        setCurrentStep(step);
        setInvoice({
          ...invoice,
          endDate: dEnd.toDate().getTime(),
        });
        saveInvoice();
        break;
      }
      case 3: {
        // Make sure there are items, at least 1.
        if (!invoice?.items || invoice.items.length === 0) {
          enqueueSnackbar("Please add at least 1 item to the invoice.", {
            variant: "error",
          });
          return;
        }

        let _total = 0;

        for (let item of invoice.items) {
          if (!item.price) {
            enqueueSnackbar("Please enter a price for all the items.", {
              variant: "error",
            });
            return;
          }
          _total += item.price;
        }
        // TODO: ADD VAT
        const _fee = (AppConfig.payantFee * _total) / 100;
        setInvoice({
          ...invoice,
          total: _total,
          fee: _fee,
        });
        setCurrentStep(step);
        saveInvoice();
        break;
      }
    }
  }

  /**
   * Returns false if the specified step is invalid or incomplete. Used by the form validation.
   * @param step
   */
  function isStepValid(step: number) {
    switch (step) {
      case 1: {
        // End date, client, and invoice name.
        const dEnd = moment(endDate, "MM-DD-YYYY");
        if (!dEnd.isValid()) {
          return false;
        }
        return Boolean(invoice?.client && invoice?.name);
      }
      case 2: {
        // Validate items (and their prices), itemname, and description.
        if (!invoice?.items || invoice.items.length <= 0) {
          return false;
        }
        for (let i of invoice.items) {
          if (!(i.name && i.description && i.price)) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  }

  /**
   * Deletes the item from invoice.
   * @param itemNumber
   */
  function removeItem(itemNumber: number) {
    if (invoice?.items.length && invoice.items.length > 1) {
      invoice?.items.splice(itemNumber, 1);
      setInvoice({ ...(invoice as any) });
      // If the selected item was the last item, we set selected item to -1
      if (selectedItem === invoice.items.length) {
        setSelectedItem(selectedItem - 1);
      }
      saveInvoice();
    }
  }

  function getTotal() {
    let i = 0;
    if (!invoice) return 0;
    for (let c of invoice!.items) {
      i += c.price || 0;
    }
    if (i === 0) return 0;
    // Add VAT
    // 1. Find the % of total
    const perc = (i * (invoice?.vat || 0)) / 100;
    // 2. Add it to total
    return i + perc;
  }

  const elementRef = React.useRef<any>();
  const triggerPrint = useReactToPrint({
    content: () => elementRef.current,
    copyStyles: true,
    //pageStyle: "font-family: 'Inter'",
    onBeforeGetContent: () => {
      setBusy(true);
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      setBusy(false);
    },

    async print(target: any) {
      await printInvoice((invoice!.name || invoice!.id) + ".pdf", target);
    },
  });

  React.useEffect(() => {
    setPageTitle("Create Proposal");
    loadClients();
    // If the location has invoice, we set it
    if (loc.state?.invoice) {
      setInvoice(loc.state.invoice);
      const dm = moment(new Date(loc.state.invoice.endDate)).format(
        "MM-DD-YYYY"
      );
      setEndDate(dm);
    } else {
      // Set the invoice.items to an array with one item
      setInvoice({
        ...(invoice as any),
        name: loc.state?.name || "",
        client: loc.state?.client,
        items: [{}],
      });
    }
    return () => {
      saveInvoice();
    };
  }, []);

  function RenderWorkItemButton(num: number, allowDelete: boolean = true) {
    return (
      <Button
        id="prop_item_name"
        variant="text"
        sx={{
          mx: "1px",
          my: "1px",
          borderRadius: "0px",
          borderBottom: selectedItem === num ? `4px solid #A882F3` : "0px",
        }}
        onClick={() => setSelectedItem(num)}
      >
        Item # {num + 1}
        {selectedItem !== num && allowDelete && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              removeItem(num);
            }}
            variant="contained"
            sx={{
              zIndex: 10,
              p: 0,
              my: 0,
              mx: "4px",
              minWidth: "24px",
              width: "24px",
              minHeight: "24px",
              height: "24px",
            }}
          >
            <Tooltip title="Delete item">
              <Delete sx={{ width: "18px", height: "18px" }} />
            </Tooltip>
          </Button>
        )}
      </Button>
    );
  }

  return (
    <Stack
      className="payant-section"
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Stack
        id="prop_cont"
        flex={1}
        justifyContent={isDesktop ? "center" : "normal"}
        alignItems={isDesktop ? "center" : "normal"}
      >
        <Paper
          elevation={0}
          sx={{ borderRadius: 0, width: isDesktop ? "466px" : "100%", p: 2 }}
        >
          <PayantStepper
            sx={{ px: isDesktop ? "45px" : "22.5", pb: "10px", pt: "14px" }}
            hidelast
            width={"100%"}
            active={currentStep}
            stepsCount={3}
          />

          {/* Step one: Client and name  */}
          {currentStep === 1 && (
            <Stack spacing={"10px"}>
              <Typography
                sx={{ fontWeight: 600, fontSize: "16px", alignSelf: "center" }}
              >
                Create Proposal
              </Typography>
              <PayantTextField
                id="prop_name"
                value={invoice?.name}
                onUpdate={(txt) =>
                  setInvoice({ ...(invoice as any), name: txt })
                }
                sx={{ mt: "18px" }}
                required
                label="Proposal Name"
              />
              <Stack>
                <Typography
                  id="prop_new_client"
                  onClick={() =>
                    navigate("../clients/create", {
                      state: {
                        fromQuote: true,
                        name: invoice?.name || "",
                      },
                    })
                  }
                  color="primary"
                  sx={{
                    alignSelf: "end",
                    fontSize: "12px",
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                >
                  New Client
                </Typography>
                <FormControl variant="filled" required id="prop_client">
                  <InputLabel>Client</InputLabel>
                  <Select
                    required
                    label="Client"
                    sx={{ mt: "5px", mb: "18px" }}
                    onChange={(c) =>
                      setInvoice({
                        ...(invoice as any),
                        client: clients.find((c2) => c2.id === c.target.value),
                      })
                    }
                    defaultValue={-1}
                    value={parseInt(invoice?.client?.id || "-1")}
                    IconComponent={KeyboardArrowDown}
                  >
                    <MenuItem key={-1} value={-1} disabled>
                      Select a client...
                    </MenuItem>
                    {clients.map((c) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              <DatePicker
                label="Due Date"
                value={endDate ? endDate : null}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => (
                  <PayantTextField
                    id="prop_date"
                    required
                    value={endDate}
                    onBlur={(e) => setEndDate(e.currentTarget.value)}
                    label="Due Date"
                    placeholder="DD-MM-YYYY"
                    sx={{ flex: 1 }}
                    {...params}
                  />
                )}
              />
              <Button
                id="prop_next_1"
                disabled={!isStepValid(1)}
                fullWidth
                variant="contained"
                sx={{
                  height: "44px",
                  alignSelf: "center",
                  borderRadius: "25px",
                  mt: "18px",
                }}
                onClick={() => nextStep(currentStep + 1)}
              >
                Next
              </Button>
            </Stack>
          )}

          {/* Step two: scope of work and work items  */}
          {currentStep === 2 && (
            <Stack key={selectedItem} spacing={"10px"}>
              <Typography
                fontWeight={600}
                fontSize="16px"
                sx={{ alignSelf: "center" }}
              >
                Add Services
              </Typography>
              <Typography
                fontWeight={400}
                fontSize="12px"
                sx={{ alignSelf: "center" }}
              >
                Create your proposal to get your client's approval
              </Typography>
              <Stack
                direction="row"
                sx={{ width: "100%" }}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <List sx={{ flex: 1 }} component={"nav"}>
                  {invoice?.items?.map((i, j) => RenderWorkItemButton(j))}
                </List>
                <Button
                  id="prop_add"
                  sx={{ my: "1px" }}
                  onClick={() => {
                    invoice?.items.push({} as any);
                    setInvoice({ ...(invoice as any) });
                  }}
                  variant="text"
                >
                  Add Item
                </Button>
              </Stack>
              <Stack direction={"row"} spacing={"10px"}>
                <PayantTextField
                  id="prop_name"
                  fullWidth
                  value={invoice?.items[selectedItem]?.name}
                  onUpdate={(txt) => {
                    invoice!.items[selectedItem].name = txt;
                    setInvoice({ ...(invoice as any) });
                  }}
                  label="Item Name"
                  required
                />
                <PayantTextField
                  id="prop_price"
                  fullWidth
                  required
                  value={invoice?.items[selectedItem]?.price?.toString()}
                  onUpdate={(txt) => {
                    if (parseFloat(txt) > 0) {
                      invoice!.items[selectedItem].price = parseFloat(txt);
                    } else {
                      invoice!.items[selectedItem].price = 0;
                    }
                    setInvoice({ ...(invoice as any) });
                  }}
                  InputProps={{ endAdornment: <Typography>$</Typography> }}
                  label="Item Price"
                />
              </Stack>
              <PayantTextField
                id="prop_details"
                required
                value={invoice?.items[selectedItem]?.description}
                onUpdate={(txt) => {
                  invoice!.items[selectedItem].description = txt;
                  setInvoice({ ...(invoice as any) });
                }}
                rows={10}
                maxRows={10}
                multiline
                label="Item Description"
              />

              <Stack direction="row" spacing={"10px"}>
                <PayantTextField
                  id="prop_vat"
                  fullWidth
                  required
                  value={invoice?.vat?.toString()}
                  onUpdate={(txt) => {
                    if (parseFloat(txt) > 0) {
                      invoice!.vat = parseFloat(txt);
                    } else {
                      invoice!.vat = 0;
                    }
                    setInvoice({ ...(invoice as any) });
                  }}
                  InputProps={{ endAdornment: <Typography>%</Typography> }}
                  label="VAT %"
                />
                <PayantTextField
                  id="prop_total"
                  fullWidth
                  value={Moneyfy(getTotal())}
                  InputProps={{ endAdornment: <Typography>$</Typography> }}
                  label="Total"
                />
              </Stack>

              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={"24px"}
                sx={{ pt: "22px" }}
              >
                <Button
                  id="prop_back"
                  onClick={() => setCurrentStep(currentStep - 1)}
                  variant="outlined"
                  sx={{
                    height: "44px",
                    width: "120px",
                    borderRadius: "28px",
                    ":hover": {
                      background: "#938BEF",
                      color: "white",
                      border: "0px",
                    },
                  }}
                >
                  Back
                </Button>
                <Button
                  id="prop_next_2"
                  disabled={!isStepValid(2)}
                  onClick={() => nextStep(currentStep + 1)}
                  variant="contained"
                  sx={{ height: "44px", width: "120px", borderRadius: "28px" }}
                >
                  Next
                </Button>
              </Stack>
            </Stack>
          )}

          {/* Step three: shows review  */}
          {currentStep === 3 && (
            <Stack spacing={"10px"} id="prop_container_review">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography fontWeight={600} fontSize="16px">
                  Review Proposal
                </Typography>
                <Button
                  id="prop_download"
                  disabled={busy}
                  onClick={triggerPrint}
                  variant="text"
                  endIcon={<FileDownload />}
                >
                  Download Proposal
                </Button>
              </Stack>
              <Stack direction="row" spacing={"10px"} sx={{ mt: "15px" }}>
                <PayantTextField
                  fullWidth
                  value={invoice?.client?.name || ""}
                  label="Client's Name"
                />
                <PayantTextField
                  fullWidth
                  value={invoice?.name || ""}
                  label="Proposal Name"
                />
              </Stack>
              <Stack direction="row" spacing={"10px"} sx={{ mt: "15px" }}>
                <PayantTextField
                  fullWidth
                  value={Moneyfy(getTotal())}
                  label="Total"
                />
                <PayantTextField
                  fullWidth
                  value={endDate ? moment(endDate).format("Do MMM. YYYY") : ""}
                  label="Deadline"
                />
              </Stack>
              <List component={"nav"}>
                {invoice?.items?.map((i, j) => RenderWorkItemButton(j, false))}
              </List>
              <Stack direction="row" spacing={"10px"}>
                <PayantTextField
                  fullWidth
                  value={invoice?.items[selectedItem]?.name || ""}
                  label="Item Name"
                />

                <PayantTextField
                  fullWidth
                  value={"$" + invoice?.items[selectedItem]?.price || "$0"}
                  label="Item Price"
                />
              </Stack>
              <PayantTextField
                value={invoice?.items[selectedItem]?.description || ""}
                rows={10}
                maxRows={10}
                multiline
                label="Item Description"
              />
              {invoice?.items.length && invoice.items.length > 1 && (
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent="center"
                >
                  <Tooltip title="Previous item">
                    <IconButton
                      disabled={selectedItem === 0}
                      onClick={() => setSelectedItem(selectedItem - 1)}
                    >
                      <ArrowBackRounded />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Next item">
                    <IconButton
                      onClick={() => setSelectedItem(selectedItem + 1)}
                      disabled={
                        selectedItem + 1 >= (invoice?.items.length || 1)
                      }
                    >
                      <ArrowForwardRounded />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={"24px"}
                sx={{ pt: "22px" }}
              >
                <Button
                  disabled={busy}
                  onClick={() => setCurrentStep(currentStep - 1)}
                  variant="outlined"
                  sx={{
                    height: "44px",
                    width: "150px",
                    borderRadius: "28px",
                    ":hover": {
                      background: "#938BEF",
                      color: "white",
                      border: "0px",
                    },
                  }}
                >
                  Back
                </Button>
                <Button
                  id="prop_send"
                  disabled={busy}
                  onClick={sendQuote}
                  variant="contained"
                  sx={{ height: "44px", width: "150px", borderRadius: "28px" }}
                >
                  Send Proposal
                </Button>
              </Stack>
            </Stack>
          )}

          {/* Completed screen  */}
          {currentStep === 4 && (
            <Stack>
              <Typography textAlign="center" fontWeight={600} fontSize="16px">
                Congratulations 🎉
              </Typography>
              <Typography
                textAlign="center"
                fontSize="12px"
                fontWeight={400}
                sx={{ marginTop: "25px" }}
              >
                We have sent the proposal to{" "}
                <strong>{invoice?.client?.name}</strong> for approval. Once it
                has been approved you will receive a notification. You will then
                be able to create the Smart Invoice.
              </Typography>
              <NextButton onClick={() => navigate("/portal/dashboard/")}>
                Discover Dashboard
              </NextButton>
            </Stack>
          )}
        </Paper>
      </Stack>
      <div
        style={{
          position: "absolute",
          overflow: "hidden",
          height: 0,
          width: 0,
          zIndex: -999,
        }}
      >
        <div ref={elementRef}>
          <PrintableInvoice invoice={invoice!} />
        </div>
      </div>
    </Stack>
  );
}
