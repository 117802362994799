import {
  Paper,
  Stack,
  StackProps,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
  StepperProps,
  StepProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.lineVertical}`]: {
      background: "#938BEF",
      width: "4px",
      borderRadius: "15px",
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.lineVertical}`]: {
      background: "#938BEF",
      width: "4px",
      borderRadius: "15px",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    background: "#D4D4D4",
    width: "4px",
    borderRadius: "15px",
    border: "none",
    marginLeft: "8px",
  },
}));

const QontoStepIconRoot = styled("div")<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState }) => ({
  display: "flex",
  justifyContent: "center",
  height: "21px",
  width: "21px",
  borderRadius: "50%",
  alignItems: "center",
  color: "#7F7F88",
  backgroundColor: "#F0F0F0",
  ...(ownerState.active && {
    background: "#5D18EB",
    color: "#FFFFFF",
  }),
  ...(ownerState.completed && {
    background: "#938BEF",
    color: "#FFFFFF",
  }),
}));

function QontoStepIcon(props: StepIconProps & StepProps) {
  const { active, completed, className, icon } = props;

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      <Typography fontSize={12}>{icon?.toString()}</Typography>
    </QontoStepIconRoot>
  );
}

export default function HowPayantWorksStepper(
  props: {
    labels: string[];
  } & StepperProps
) {
  return (
    <Stepper
      variant="elevation"
      sx={{
        width: "100%",
        my: "0px",
        mx: 0,
        p: 0,
      }}
      connector={<QontoConnector sx={{ m: 0, mr: "8px", p: 0 }} />}
      orientation="vertical"
      {...props}
    >
      {props.labels.map((val, ind) => (
        <Step sx={{ m: 0, p: 0 }}>
          <StepLabel StepIconComponent={QontoStepIcon}>
            <Typography fontSize={"12px"}>{val}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
