import flowAbi from "./assets/Flow.json";
import factoryAbi from "./assets/FlowFactory.json";

export const GlobalDialogBorderRadius = "18px";

const development = {
  mode: "DEVELOPMENT",
  payantFee: 0, // in percentage of total of the invoice.
  version: "1.0.0-alpha",
  api: "http://localhost:1234/api/v1",
  firebase: {
    apiKey: "AIzaSyBBnZfYnE43f9FglQb3orMI32nYZDfcY5Q",
    authDomain: "payant-test.firebaseapp.com",
    databaseURL: "https://payant-test-default-rtdb.firebaseio.com",
    projectId: "payant-test",
    storageBucket: "payant-test.appspot.com",
    messagingSenderId: "556734078169",
    appId: "1:556734078169:web:6a24b2d73d01e2bb7d32c8",
  },
  // Applied to: Text Fields, Cards, Dialogs, Sidebar, Buttons, and Literally every paper component.
  globalCornerRadius: "6px",
  contractConfig: [
    {
      name: "polygon mumbai",
      network: "mumbai",
      tokens: [
        {
          name: "usdc",
          address: "0x5d3157a8459eE2F353A606b175e749D85ec4C03B",
          decimals: 6,
        },
        {
          name: "usdt",
          address: "0x4fD058EFC690713dcf70fD5a63264bdf941Ec8D7",
          decimals: 6,
        },
      ],
      InterpreterCallerV1ConstructionConfigStruct: {
        callerMeta:
          "0x789c95533d6fe3300cddf32b08cff96a871bbab56e0adc54a0c816785024ba364e160d49ee2528f2df2bc98d1cc53917b7c420f91ef91e237ece0032c51acc1e207b91f4175e18b7a48fd9dc57041aee2b8fc0a9694841e9219c94d50e06658f9d83ad9805ce14b8bc32256ab0f40715ec9941018ec740602be9e8223cb41a8da949f53398ac99f1437cef457939de50a779905659db9a87d5eabdb655b75f3a352bcd6ad56ab2c4498660718e7a7283b622e13bef5c08f0197e2fec728dcc625ed5526c8f2d8a400b88b3ede7a0d938f50add66bcf7cd5b7ebf8e1b580e9cc1d630f2726c32fab96b9a236cd24d44d4b075117043eb650a6c99ad3c70f7ab58ba6574d6ecd6855f4e4b0a5588b2883fcda735797be6b6906d85c03e582dd95e627803e67f95dc1529c1d4ef0a45eef68807eb9856779800389312f514a02fe524bbe66ae7d71e139f4fee4d265a12abbe0adfadddd7f71e83e393dddfecd5d37e2b8107075a8feb28e558f02dd189f03c6c64342e91efffa9789ea461f3fa783e4e4716f13e6f7789bef8c428af3e7a1b014e63cea4a96d551bc8bf15ffec8d09e14f01a874b6b03fc9e8d7fa5ec3b1808b4afac9aa272df8a4804bc77763c7579962f6afea503967fb8c8f8ad969f605b5cc6866",
        deployer: "0x3Abf3DF898BB950bCc95FAD43A8516f881d2dA94",
      },
      flowFactory: "0x30B7E78AB8A3Db68A8c15d4e5Ac78B0A1bD542c0",
      flowFactoryBlock: null,
      dispatchWithdraw: [
        "0xCb47FfD648F47cca52327D11bfE5C91B54340f93",
        "0xDFC2A4DCF706b894f0B2B459FA0647733FC4F1Fd",
        "0xC1282d26F5f7829B309b72A3E0cf5ac7194c31e1",
      ],
      dispatchCancel: [
        "0xCb47FfD648F47cca52327D11bfE5C91B54340f93",
        "0xDFC2A4DCF706b894f0B2B459FA0647733FC4F1Fd",
        "0x3B9C1eA254fEf65Af78f70eDF0b7048D3588dE17",
      ],
    },
  ],
  factoryAbi: factoryAbi,
  flowAbi: flowAbi,
  compaignMonitorAPI:
    "8I7K/fBl/FaCp9Yvkyp/YGCFGBRVEwDQ7W0LbxTbapRADzJOZ5Eu55yp7JYLm/pcZqzGVLbOSWMw9UVFn8he+XOUPghn5GrMpqv0vtqHl1oNZjneyKQtjixTpZZ8UYILxkFUmyqioBEqv7ZgC6Uz+g==",
  kleros: {
    subgraph:
      "https://api.thegraph.com/subgraphs/name/salgozino/klerosboard-xdai",
  },
};

const production = {
  mode: "PRODUCTION",
  payantFee: 0, // in percentage of total of the invoice.
  version: "1.0.0-alpha",
  globalCornerRadius: "6px",
  api: "https://payant-backend.vercel.app/api/v1",
  firebase: {
    apiKey: "AIzaSyCurmBhgPBoeybq36FeopudccsN1HzvYbY",
    authDomain: "payant-backend.firebaseapp.com",
    databaseURL: "https://payant-backend-default-rtdb.firebaseio.com",
    projectId: "payant-backend",
    storageBucket: "payant-backend.appspot.com",
    messagingSenderId: "135505006866",
    appId: "1:135505006866:web:fdb3be2b56dd773e24a3cb",
  },
  contractConfig: [
    {
      name: "polygon mumbai",
      network: "mumbai",
      tokens: [
        {
          name: "usdc",
          address: "0x5d3157a8459eE2F353A606b175e749D85ec4C03B",
          decimals: 6,
        },
        {
          name: "usdt",
          address: "0xA02f6adc7926efeBBd59Fd43A84f4E0c0c91e832",
          decimals: 6,
        },
      ],
      InterpreterCallerV1ConstructionConfigStruct: {
        callerMeta:
          "0x789c95533d6fe3300cddf32b08cff96a871bbab56e0adc54a0c816785024ba364e160d49ee2528f2df2bc98d1cc53917b7c420f91ef91e237ece0032c51acc1e207b91f4175e18b7a48fd9dc57041aee2b8fc0a9694841e9219c94d50e06658f9d83ad9805ce14b8bc32256ab0f40715ec9941018ec740602be9e8223cb41a8da949f53398ac99f1437cef457939de50a779905659db9a87d5eabdb655b75f3a352bcd6ad56ab2c4498660718e7a7283b622e13bef5c08f0197e2fec728dcc625ed5526c8f2d8a400b88b3ede7a0d938f50add66bcf7cd5b7ebf8e1b580e9cc1d630f2726c32fab96b9a236cd24d44d4b075117043eb650a6c99ad3c70f7ab58ba6574d6ecd6855f4e4b0a5588b2883fcda735797be6b6906d85c03e582dd95e627803e67f95dc1529c1d4ef0a45eef68807eb9856779800389312f514a02fe524bbe66ae7d71e139f4fee4d265a12abbe0adfadddd7f71e83e393dddfecd5d37e2b8107075a8feb28e558f02dd189f03c6c64342e91efffa9789ea461f3fa783e4e4716f13e6f7789bef8c428af3e7a1b014e63cea4a96d551bc8bf15ffec8d09e14f01a874b6b03fc9e8d7fa5ec3b1808b4afac9aa272df8a4804bc77763c7579962f6afea503967fb8c8f8ad969f605b5cc6866",
        deployer: "0x3Abf3DF898BB950bCc95FAD43A8516f881d2dA94",
      },
      flowFactory: "0x30B7E78AB8A3Db68A8c15d4e5Ac78B0A1bD542c0",
      flowFactoryBlock: null,
      dispatchWithdraw: [
        "0xCb47FfD648F47cca52327D11bfE5C91B54340f93",
        "0xDFC2A4DCF706b894f0B2B459FA0647733FC4F1Fd",
        "0xC1282d26F5f7829B309b72A3E0cf5ac7194c31e1",
      ],
      dispatchCancel: [
        "0xCb47FfD648F47cca52327D11bfE5C91B54340f93",
        "0xDFC2A4DCF706b894f0B2B459FA0647733FC4F1Fd",
        "0x3B9C1eA254fEf65Af78f70eDF0b7048D3588dE17",
      ],
    },
  ],
  factoryAbi: factoryAbi,
  flowAbi: flowAbi,
  compaignMonitorAPI:
    "8I7K/fBl/FaCp9Yvkyp/YGCFGBRVEwDQ7W0LbxTbapRADzJOZ5Eu55yp7JYLm/pcZqzGVLbOSWMw9UVFn8he+XOUPghn5GrMpqv0vtqHl1oNZjneyKQtjixTpZZ8UYILxkFUmyqioBEqv7ZgC6Uz+g==",
  kleros: {
    subgraph:
      "https://api.thegraph.com/subgraphs/name/salgozino/klerosboard-xdai",
  },
};
const mode =
  process.env.REACT_APP_MODE === "development" ? "development" : "production";
if (mode === "development") {
  document.title = "[DEV] " + document.title;
}

export const AppConfig = mode === "production" ? production : development;
