import React, { useEffect } from "react";
import { getAuth } from "firebase/auth";

/**
 * Renders children only if the user is logged in. Otherwise navigates to the /login (or specified redirectTo) page.
 * @returns
 */
export default function ProtectedRoute(props: {
  redirectTo?: string;
  children: any;
  fallbackChildren: any;
}) {
  const [user, setUser] = React.useState<any>();
  useEffect(() => {
    const unsub = getAuth().onAuthStateChanged((user) => {
      if (user) {
        // we are logged in
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return unsub;
  });

  if (!user) {
    return <>{props.fallbackChildren}</>;
  }
  return <>{props.children}</>;
}
