import React from "react";
import ReactDOM from "react-dom/client";
import ThemeManager from "./providers/ThemeManager";
import "@rainbow-me/rainbowkit/styles.css";
import { SnackbarProvider } from "notistack";
import { CssBaseline } from "@mui/material";
import AppRoutes from "./Routes";
import { RecoilRoot } from "recoil";
import { Box, Stack } from "@mui/system";
import "./index.css";
import { WagmiConfig, createClient, configureChains } from "wagmi";
import { mainnet, polygonMumbai, goerli } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import {
  RainbowKitProvider,
  lightTheme,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import {
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const { chains, provider } = configureChains(
  [polygonMumbai, goerli, mainnet],
  [publicProvider()]
);

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      metaMaskWallet({ chains }),
      walletConnectWallet({ chains }),
      coinbaseWallet({ appName: "Payant.io", chains }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

root.render(
  <React.StrictMode>
    <CssBaseline />
    <RecoilRoot>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          appInfo={{
            appName: "Payant",
            learnMoreUrl: "https://payant.io/learn",
          }}
          chains={chains}
          modalSize="compact"
          theme={lightTheme({
            accentColor: "#000000",
            accentColorForeground: "white",
            borderRadius: "medium",
            fontStack: "rounded",
          })}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeManager>
              <SnackbarProvider
                title="Payant"
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
              >
                <Stack sx={{ width: "100vw", height: "100vh" }}>
                  <AppRoutes />
                </Stack>
              </SnackbarProvider>
            </ThemeManager>
          </LocalizationProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </RecoilRoot>
  </React.StrictMode>
);
