import { Button, ButtonProps } from "@mui/material";
import { AppConfig } from "../config";

export default function NextButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      sx={{
        background: "#f4f4f4",
        marginTop: "48px",
        textTransform: "none",
        color: "text.primary",
        fontWeight: "bold",
        fontSize: "15px",
        borderRadius: AppConfig.globalCornerRadius,
        ...props.sx,
      }}
      fullWidth
      variant="text"
    />
  );
}
