import {
  Avatar,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import PayantTextField from "../components/textfield";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { SendVerificationEmail } from "../rest/user";
import { FbLogin, FbLogout } from "../core/firebase";
import { PHOENIX } from "../assets/assets";

export default function PageLogin() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ email?: boolean; pass?: boolean }>();
  const [form, setForm] = useState<any>({
    email: "",
    pass: "",
    persist: false,
  });

  // For redirecting
  const nav = useNavigate();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  // For errors and msgs
  const { enqueueSnackbar } = useSnackbar();

  function isFormValid() {
    if (!Boolean(form.email)) {
      return false;
    }
    if (!Boolean(form.pass)) {
      return false;
    }
    return true;
  }

  /**
   * Parse the input and send it to the register function.
   */
  async function login() {
    // Get the data.
    setErrors({});
    try {
      // Uses firebase client sdk to login
      if (!Boolean(form.email)) {
        enqueueSnackbar("Please enter a valid email address.", {
          variant: "error",
        });
        setErrors({ email: true });
        return;
      }
      if (!Boolean(form.pass)) {
        enqueueSnackbar("Please enter your password.", { variant: "error" });
        setErrors({ pass: true });
        return;
      }
      setLoading(true);
      const resp = await FbLogin(form.email, form.pass);
      if (!resp.emailVerified) {
        // Email is not verified.
        enqueueSnackbar("ERROR: Email not verified. Please check your inbox.", {
          variant: "error",
        });
        setErrors({ email: true });
        // Send verification email
        await SendVerificationEmail(form.email);
        await FbLogout();
        setLoading(false);
        return;
      }
      // Logged in
      nav("/portal/");
      enqueueSnackbar("You are logged in.", { variant: "success" });
    } catch (err: any) {
      if (err.message?.includes("auth/invalid-email")) {
        setErrors({ email: true });
        enqueueSnackbar(
          "Invalid email address, please try entering your email again.",
          {
            variant: "error",
          }
        );
      } else if (err.message?.includes("auth/user-not-found")) {
        setErrors({ email: true });
        enqueueSnackbar("Email did not match with any accounts.", {
          variant: "error",
        });
      } else if (err.message?.includes("auth/email-already-in-use")) {
        setErrors({ email: true });
        enqueueSnackbar("An account with this email address already exists.", {
          variant: "error",
        });
      } else if (err.message?.includes("auth/wrong-password")) {
        setErrors({ pass: true });
        enqueueSnackbar("Invalid password. Please try again.", {
          variant: "error",
        });
      } else if (err.message?.includes("auth/too-many-requests")) {
        enqueueSnackbar(
          "Too many attempts. Please try again or reset your password.",
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar("Error logging in. Please contact administrators.", {
          variant: "error",
        });
        console.log(err);
      }
    }
    setLoading(false);
  }

  async function loadSupport() {
    try {
      // Here we show the bubbble
      (window as any).Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "xrmsb03k",
      });
    } catch (err: any) {}
  }

  useEffect(() => {
    loadSupport();
  }, []);

  return (
    <Grid
      container
      columns={2}
      sx={{
        width: "100%",
        ...(isDesktop
          ? {}
          : { flexDirection: "column-reverse", flexWrap: "nowrap" }),
      }}
    >
      {/* On desktop, each item uses 1 column. On mobile, the items occupy all columns and the layout is also reversed, so 
      that the login section appears before the message. */}
      <Grid item xs={isDesktop ? 1 : 2}>
        <Stack
          justifyContent="center"
          alignItems="center"
          flex={1}
          sx={{ background: "#5D18EB", color: "white", height: "100%" }}
        >
          <Stack>
            <Typography
              sx={{
                fontFamily: "Baloo",
                fontSize: isDesktop ? "99px" : "28px",
                lineHeight: isDesktop ? "104px" : "30px",
                ml: isDesktop ? 0 : "32px",
                mt: isDesktop ? 0 : "32px",
              }}
            >
              GET PAID
              <br />
              ON TIME.
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Bakbak One'",
                fontSize: "99px",
                lineHeight: "0px",
                mt: "40px",
                color: "#80E7D6",
                ml: "32px",
              }}
            >
              “
            </Typography>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                lineHeight: "14.5px",
                color: "#FFFFFF",
                width: "329px",
                ml: "32px",
              }}
            >
              Why is it so hard to get paid?
              <br />
              <br />
              Well, founder Emily often asked herself the same thing. She got
              paid late more times than she can count in the 7 years she worked
              as a contractor in Web 3 and Crypto, and the big companies were
              the worst. She was so over chasing invoices, that she decided to
              build a solution herself.
              <br />
              <br />
              With Payant, you get access to a web 2.5 platform that secures
              your payments in a smart escrow, so you never get paid late again.
            </Typography>
            <Stack
              sx={{
                borderLeft: "1px solid #80E7D6",
                height: isDesktop ? "200px" : "100px",
                my: "10px",
                pl: "22px",
                pt: "30px",
              }}
            >
              <Stack direction="row" alignItems="center" spacing={"12px"}>
                <Avatar
                  sx={{ height: "46px", width: "46px" }}
                  src={PHOENIX}
                  alt="Emily Rose Dallara"
                />
                <Stack spacing={"6px"}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "14px",
                      fontFamily: "Inter",
                    }}
                  >
                    Emily Rose Dallara
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      fontFamily: "Inter",
                    }}
                  >
                    CEO & Founder
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
      {/* The login section  */}
      <Grid item xs={isDesktop ? 1 : 2}>
        <Stack
          flex={1}
          sx={{ background: "#FFFFFF", height: "100vh", px: "18px" }}
          justifyContent="center"
          alignItems={isDesktop ? "center" : "inherit"}
        >
          <Stack spacing={isDesktop ? "18px" : "12px"}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: isDesktop ? "44px" : "24px",
                fontWeight: 700,
                lineHeight: "53px",
                textAlign: "center",
              }}
            >
              Welcome to Payant
            </Typography>
            <PayantTextField
              label="Email Address"
              placeholder="Email address"
              error={errors?.email}
              disabled={loading}
              autoComplete="email"
              onChange={(c) =>
                setForm({ ...form, email: c.currentTarget.value })
              }
            />
            <PayantTextField
              error={errors?.pass}
              disabled={loading}
              label="Password"
              type="password"
              placeholder="Password"
              onKeyDown={(k) => {
                if (k.code === "Enter") login();
              }}
              onChange={(c) =>
                setForm({ ...form, pass: c.currentTarget.value })
              }
            />
            <Button
              variant="contained"
              disabled={loading || !isFormValid()}
              onClick={login}
            >
              Sign In
            </Button>
            <NavLink
              style={{
                alignSelf: "center",
                fontSize: "12px",
                textDecoration: "none",
              }}
              to="/reset-password"
            >
              Forgot your password? Reset it now.
            </NavLink>
            <Stack
              direction="row"
              sx={{ width: "100%" }}
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Typography fontSize={"12px"}>Don't have an account?</Typography>
              <NavLink
                style={{ fontSize: "12px", textDecoration: "none" }}
                to="/signup"
              >
                Signup
              </NavLink>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}
