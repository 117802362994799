import {
  PaperProps,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router";
import { Moneyfy, isActiveInvoice } from "../core/helper";
import { Client, INVOICE_STATUS } from "payant-lib";
import BusinessDetailsDialog from "../dialogs/clientDetails";
import { useModal } from "mui-modal-provider";
import LoadingIndicator from "./loadingIndicator";
import { RestGetClients, RestGetInvoices } from "../core/api";
import SortIcon from "./sortIcon";

export default function RecentClients(props: PaperProps) {
  const [clients, setClients] = React.useState<
    (Client & {
      pendingQuotes: number;
      activeInvoices: number;
      totalLocked: number;
      readyToWithdraw: number;
      totalRevenues: number;
    })[]
  >([]);
  const [busy, setBusy] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  const [sort, setSort] = React.useState<
    "name" | "pending" | "active" | "locked" | "ready" | "total"
  >("name");

  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const navigate = useNavigate();

  // Toggles sorting (mode and order)
  function toggleSort(
    sorting: "name" | "pending" | "active" | "locked" | "ready" | "total"
  ) {
    // If the specified sorting is same as previous, we toggle the order only.
    if (sort === sorting) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Change the sorting only
      setSort(sorting);
    }
  }

  function getItems() {
    // Apply viewmode.
    let _items = clients;

    // Apply sorting
    switch (sort) {
      case "total": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.totalRevenues - b.totalRevenues);
        } else {
          _items.sort((a, b) => b.totalRevenues - a.totalRevenues);
        }
        break;
      }
      case "ready": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.readyToWithdraw - b.readyToWithdraw);
        } else {
          _items.sort((a, b) => b.readyToWithdraw - a.readyToWithdraw);
        }
        break;
      }

      case "locked": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.totalLocked - b.totalLocked);
        } else {
          _items.sort((a, b) => b.totalLocked - a.totalLocked);
        }
        break;
      }

      case "pending": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.pendingQuotes - b.pendingQuotes);
        } else {
          _items.sort((a, b) => b.pendingQuotes - a.pendingQuotes);
        }
        break;
      }

      case "active": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.activeInvoices - b.activeInvoices);
        } else {
          _items.sort((a, b) => b.activeInvoices - a.activeInvoices);
        }
        break;
      }

      case "name": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          _items.sort((a, b) => b.name.localeCompare(a.name));
        }
        break;
      }
    }

    // Apply filter
    return _items;
  }

  async function loadClients() {
    setBusy(true);
    try {
      // load clients
      const _clis: (Client & {
        pendingQuotes: number;
        activeInvoices: number;
        totalLocked: number;
        readyToWithdraw: number;
        totalRevenues: number;
      })[] = [];
      const clis = await RestGetClients();
      const invs = await RestGetInvoices();
      setClients(clis as any);
      // Render active invoices and total active of each client
      for (let c of clis) {
        // Get invoices of this client
        const cInvoices = invs.filter((i) => i.client.id === c.id);
        let pendingQuotes: number = 0;
        let activeInvoices: number = 0;
        let totalLocked: number = 0;
        let readyToWithdraw: number = 0;
        let totalRevenues: number = 0;
        // NOTICE: Admin requested change to show awaiting payment instead of total active, the variable name
        // is same but it shows total of unpaid invoices rather than active.
        for (let ci of cInvoices) {
          // Pending
          if (
            ci.status === INVOICE_STATUS.REVIEW ||
            ci.status === INVOICE_STATUS.REVIEWED
          ) {
            pendingQuotes += 1;
          }
          // Active invoice: not in review or complete status
          if (isActiveInvoice(ci)) {
            activeInvoices++;
            // If its also not approved, it means its cnsidered locked
            if (ci.status !== INVOICE_STATUS.APPROVED) {
              totalLocked += ci.total || 0;
            }
            // IF its approved, its ready to withdraw
            if (ci.status === INVOICE_STATUS.APPROVED) {
              readyToWithdraw += ci.total || 0;
            }
          }
          // If its complete, we make it in paid out
          if (ci.status === INVOICE_STATUS.COMPLETE) {
            totalRevenues += ci.total || 0;
          }
        }
        _clis.push({
          ...c,
          totalRevenues: totalRevenues,
          activeInvoices: activeInvoices,
          totalLocked: totalLocked,
          pendingQuotes: pendingQuotes,
          readyToWithdraw: readyToWithdraw,
        });
      }
      setClients(_clis);
    } catch (err: any) {
      enqueueSnackbar("Error loading clients. Please see logs for details.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  function showInfoDialog(cli: Client) {
    const modal: any = showModal(BusinessDetailsDialog, {
      closeHandler: () => modal.destroy(),
      isOpen: true,
      data: cli,
      canDismiss: true,
    });
  }

  React.useEffect(() => {
    loadClients();
    //const timer = setInterval(loadClients, 3000);
    //return () => clearInterval(timer);
  }, []);

  function RenderClient(
    cli: Client & {
      pendingQuotes: number;
      activeInvoices: number;
      totalLocked: number;
      readyToWithdraw: number;
      totalRevenues: number;
    },
    index: number
  ) {
    return (
      <TableRow key={cli.id} onClick={() => showInfoDialog(cli)}>
        <TableCell align="left">
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                textDecoration: "underline",
                fontWeight: "bold",
                fontSize: isDesktop ? "16px" : "10px",
              }}
              onClick={(e) => {
                showInfoDialog(cli);
                e.stopPropagation();
              }}
            >
              {cli.name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left" sx={{ fontSize: isDesktop ? "16px" : "10px" }}>
          <Tooltip title="This is the number of Proposals that awaiting approval by your client.">
            <span>{cli.pendingQuotes}</span>
          </Tooltip>
        </TableCell>
        {isDesktop && (
          <TableCell
            align="left"
            sx={{ fontSize: isDesktop ? "16px" : "10px" }}
          >
            <Tooltip title="This is the total number of currently active invoices that are awaiting deliverables or under review.">
              <span>{cli.activeInvoices}</span>
            </Tooltip>
          </TableCell>
        )}
        {isDesktop && (
          <TableCell
            align="left"
            sx={{ fontSize: isDesktop ? "16px" : "10px" }}
          >
            <Tooltip title="This is the total amount of funds currently locked in Payant’s Smart Contract in USD.">
              <span>{Moneyfy(cli.totalLocked)}</span>
            </Tooltip>
          </TableCell>
        )}
        <TableCell align="left" sx={{ fontSize: isDesktop ? "16px" : "10px" }}>
          <Tooltip title="This is the total amount of funds you can withdraw from Payant’s Smart Contract in USD.">
            <span>{Moneyfy(cli.readyToWithdraw)}</span>
          </Tooltip>
        </TableCell>
        <TableCell
          align="left"
          style={{ color: "#6DC881" }}
          sx={{ fontSize: isDesktop ? "16px" : "10px" }}
        >
          <Tooltip title="This is the total amount of revenues in USD generated with your selected client.">
            <span>{Moneyfy(cli.totalRevenues)}</span>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {busy && <LoadingIndicator />}
      {!busy && (
        <TableContainer sx={{ overflow: "auto" }}>
          <Table stickyHeader size="small" border={0} sx={{ p: "4px" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ fontSize: isDesktop ? "12px" : "10px" }}
                  align="left"
                  onClick={() => toggleSort("name")}
                >
                  Client's Name
                  <SortIcon order={sortOrder} active={sort === "name"} />
                </TableCell>
                {isDesktop && (
                  <TableCell align="left" onClick={() => toggleSort("pending")}>
                    <Tooltip title="This is the number of Proposals that awaiting approval by your client.">
                      <span>Pending Proposals</span>
                    </Tooltip>
                    <SortIcon order={sortOrder} active={sort === "pending"} />
                  </TableCell>
                )}
                {isDesktop && (
                  <TableCell align="left" onClick={() => toggleSort("active")}>
                    <Tooltip title="This is the total number of currently active invoices that are awaiting deliverables or under review.">
                      <span>Active Invoices</span>
                    </Tooltip>
                    <SortIcon order={sortOrder} active={sort === "active"} />
                  </TableCell>
                )}
                <TableCell
                  sx={{ fontSize: isDesktop ? "12px" : "10px" }}
                  align="left"
                  onClick={() => toggleSort("locked")}
                >
                  <Tooltip title="This is the total amount of funds currently locked in Payant’s Smart Contract in USD.">
                    <span>Total Locked</span>
                  </Tooltip>
                  <SortIcon order={sortOrder} active={sort === "locked"} />
                </TableCell>
                <TableCell
                  sx={{ fontSize: isDesktop ? "12px" : "10px" }}
                  align="left"
                  onClick={() => toggleSort("ready")}
                >
                  <Tooltip title="This is the total amount of funds you can withdraw from Payant’s Smart Contract in USD.">
                    <span>Ready to Withdraw</span>
                  </Tooltip>
                  <SortIcon order={sortOrder} active={sort === "ready"} />
                </TableCell>
                <TableCell
                  sx={{ fontSize: isDesktop ? "12px" : "10px" }}
                  align="left"
                  onClick={() => toggleSort("total")}
                >
                  <Tooltip title="This is the total amount of revenues in USD generated with your selected client.">
                    <span>Total Revenues</span>
                  </Tooltip>
                  <SortIcon order={sortOrder} active={sort === "total"} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getItems()?.map((c, ind) => RenderClient(c, ind + 1))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
