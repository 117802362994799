import { SearchSharp } from "@mui/icons-material";
import {
  OutlinedInput,
  OutlinedTextFieldProps,
  Paper,
  Stack,
  styled,
  TextField,
  TextFieldProps,
  useMediaQuery,
} from "@mui/material";
import React from "react";

const PayantSearchBox = styled(OutlinedInput)<OutlinedTextFieldProps>(
  (state) => ({
    " & .MuiOutlinedInput-notchedOutline": {
      display: "none",
    },
  })
);

export default function SearchBox(props: TextFieldProps) {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  return (
    <Stack direction="row" sx={{ width: isDesktop ? "240px" : "100%" }}>
      <Paper elevation={0} sx={{ borderRadius: "6px", width: "100%" }}>
        <PayantSearchBox
          size="small"
          InputLabelProps={{ sx: { fontSize: "12px" } }}
          variant="outlined"
          placeholder="Search for invoice"
          startAdornment={
            <SearchSharp sx={{ width: "18px", height: "18px", opacity: 0.6 }} />
          }
          sx={{
            minHeight: "34px",
            ...props.sx,
            border: 0,
          }}
          {...(props as any)}
        />
      </Paper>
    </Stack>
  );
}
