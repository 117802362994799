import { Button, Paper, Stack, Typography } from "@mui/material";
import {
  confirmPasswordReset,
  getAuth,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from "firebase/auth";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PAYANT_LOGO_TRANSPARENT } from "../assets/assets";
import PayantTextField from "../components/textfield";

/**
 * Shows user hint that they need to click the link sent to their email to verify their account.
 * Accounts that are NOT verified are redirected to this screen. This page basically performs login and checks if the emailVerified parameter is true on firebase.
 * @returns
 */
export default function PageRecovery() {
  const loc = useLocation();
  const nav = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  // Location.State should contain the username and hashed password
  const code = loc.state?.code;

  useEffect(() => {
    if (!code) {
      // Redirect to /
      nav("/");
    } else {
      loadSupport();
    }
  }, []);

  async function resetPassword() {
    // MAke sure both are same
    if (!pass1 || !pass2) {
      enqueueSnackbar("Enter the password. ", { variant: "error" });
      return;
    }
    if (pass1 !== pass2) {
      enqueueSnackbar("Passwords do not match. ", { variant: "error" });
      return;
    }
    try {
      // Proceed.
      setLoading(true);
      const em = await verifyPasswordResetCode(getAuth(), code!);
      if (em) {
        // Reset password
        await confirmPasswordReset(getAuth(), code!, pass1);
        // Signin
        await signInWithEmailAndPassword(getAuth(), em, pass1);
        // Redirect to dashboard
        nav("/dashboard");
      } else {
        enqueueSnackbar("Error verifying reset code. Please try again.", {
          variant: "error",
        });
      }
    } catch (err: any) {
      enqueueSnackbar("Error changing password. Please see logs for details.", {
        variant: "error",
      });
      console.log(err);
    }
    setLoading(false);
  }

  async function loadSupport() {
    try {
      // Here we show the bubbble
      (window as any).Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "xrmsb03k",
      });
    } catch (err: any) {}
  }

  if (!code) {
    return <>Redirecting...</>;
  }

  return (
    <Stack spacing={2} sx={{ height: "100%", py: "33px", px: "112px" }}>
      <img
        style={{ marginTop: "10px" }}
        src={PAYANT_LOGO_TRANSPARENT}
        alt="Payant.io Logo"
        width={196}
      />
      <Stack
        flex={1}
        direction={"row"}
        alignItems="center"
        justifyContent="center"
      >
        <Paper
          elevation={5}
          sx={{
            minWidth: "301px",
            maxWidth: "301px",
            background: "#FFFFFF",
          }}
        >
          <Stack
            spacing={"10px"}
            alignItems={"center"}
            sx={{ my: "40px", px: "15px" }}
          >
            <Typography
              textAlign={"center"}
              fontFamily="'Baloo Chettan 2'"
              fontWeight="bold"
              fontSize="24px"
            >
              Reset Password
            </Typography>
            <PayantTextField
              margin="normal"
              disabled={loading}
              required
              label="New Password"
              type="password"
              value={pass1}
              fullWidth
              onChange={(c) => setPass1(c.currentTarget.value)}
            />
            <PayantTextField
              margin="normal"
              value={pass2}
              disabled={loading}
              required
              fullWidth
              label="Confirm New Password"
              type="password"
              onChange={(c) => setPass2(c.currentTarget.value)}
            />
            <Button
              onClick={resetPassword}
              disabled={loading}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Change Password
            </Button>
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
}
