import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import React from "react";
import { Client } from "payant-lib";
import { BasicDialogProps } from "../models/DialogProps";
import EditClientDialog from "./editClientDetails";
import { TransparentPaper } from "../components/transPaper";

export default function BusinessDetailsDialog(props: BasicDialogProps) {
  const cli = props.data as Client;
  const { showModal } = useModal();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  function showEditDialog() {
    const modal: any = showModal(EditClientDialog, {
      canDismiss: true,
      closeHandler: () => {
        modal.destroy();
        props.closeHandler();
      },
      isOpen: true,
      data: cli,
    });
  }
  return (
    <Dialog
      PaperComponent={TransparentPaper}
      open={props.isOpen}
      sx={{ backdropFilter: "blur(2px)" }}
      onClose={props.closeHandler}
      fullWidth={isDesktop ? false : true}
    >
      <DialogContent sx={{ p: 0, ...(isDesktop ? { width: "466px" } : {}) }}>
        <Stack
          sx={{ px: "25px", py: "40px", background: "white" }}
          spacing={"20px"}
        >
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
              Business Details
            </Typography>
            <Button variant="text" onClick={showEditDialog}>
              Edit Details
            </Button>
          </Stack>
          {/* Business name*/}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: 500, fontSize: "14px" }}
            >
              Client's Name
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              {cli.name || "N/A"}
            </Typography>
          </Stack>
          {/* Email address */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: 500, fontSize: "14px" }}
            >
              Email Address
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              {cli.email || "N/A"}
            </Typography>
          </Stack>
          {/* Address  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: 500, fontSize: "14px" }}
            >
              Address
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              {cli.address || "N/A"}
            </Typography>
          </Stack>
          {/* City  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: 500, fontSize: "14px" }}
            >
              City
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              {cli.city || "N/A"}
            </Typography>
          </Stack>
          {/* Country  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: 500, fontSize: "14px" }}
            >
              Country
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              {cli.country || "N/A"}
            </Typography>
          </Stack>
          {/* Reg. Number  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: 500, fontSize: "14px" }}
            >
              Registration Number
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              {cli.registrationNumber || "N/A"}
            </Typography>
          </Stack>
          {/* Tax  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: 500, fontSize: "14px" }}
            >
              Tax ID
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              {cli.taxId || "N/A"}
            </Typography>
          </Stack>
          {/* VAT  */}
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography
              color="#8B8B8B"
              sx={{ fontWeight: 500, fontSize: "14px" }}
            >
              VAT ID
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              {cli.vatId || "N/A"}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
