import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import PayantTextField from "../components/textfield";
import { FbGetProfile, FbSetProfile } from "../core/firebase";
import { BasicDialogProps } from "../models/DialogProps";
import { UserProfile } from "payant-lib";
import { TransparentPaper } from "../components/transPaper";

export default function EditUserProfileDialog(props: BasicDialogProps) {
  const [profile, setProfile] = useState(props.data as UserProfile);
  const [busy, setBusy] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  async function save() {
    setBusy(true);
    try {
      const _prof = await FbSetProfile(profile);
      const _newProf = await FbGetProfile();
      if (_prof && _newProf) {
        setProfile(_newProf);
        enqueueSnackbar("Profile updated successfully.", {
          variant: "success",
        });
        props.closeHandler();
      } else {
        throw new Error("Unknown error setting profile.");
      }
    } catch (err: any) {
      enqueueSnackbar("Error saving profile. See logs for details.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  return (
    <Dialog
      open={props.isOpen}
      PaperComponent={TransparentPaper}
      sx={{ backdropFilter: "blur(2px)" }}
      onClose={props.closeHandler}
      fullWidth={isDesktop ? false : true}
    >
      <DialogContent sx={{ p: 0, ...(isDesktop ? { width: "466px" } : {}) }}>
        <Stack
          sx={{ px: "25px", py: "40px", background: "#FFF" }}
          spacing={"20px"}
        >
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>
              Edit Profile
            </Typography>
            <Button disabled={busy} onClick={save} variant="outlined">
              SAVE
            </Button>
          </Stack>
          {/* Name*/}
          <PayantTextField
            label="Full Name"
            required
            value={profile.fullName || ""}
            onUpdate={(txt) =>
              setProfile({ ...(profile as any), fullName: txt })
            }
          />
          <PayantTextField
            label="Phone Number"
            value={profile.phoneNumber || ""}
          />
          <PayantTextField
            required
            disabled
            label="Email"
            value={profile.emailAddress || ""}
          />

          {/* Address  */}
          <PayantTextField
            required
            label="Address"
            value={profile.userAddress || ""}
            onUpdate={(txt) =>
              setProfile({ ...(profile as any), userAddress: txt })
            }
          />

          {/* City  */}
          <PayantTextField
            required
            label="City"
            value={profile.userCity || ""}
            onUpdate={(txt) =>
              setProfile({ ...(profile as any), userCity: txt })
            }
          />

          {/* Country  */}
          <PayantTextField
            required
            label="Country"
            value={profile.userCountry || ""}
            onUpdate={(txt) =>
              setProfile({ ...(profile as any), userCountry: txt })
            }
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
