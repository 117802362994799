import { atom } from "recoil";
import { localPersist } from "./persistence";

/**
 * Stores the current application theme. Light by default.
 */
export const aTheme = atom({
  key: "payantTheme",
  default: "light",
  effects: [localPersist],
});
