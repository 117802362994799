import { ContactPage, MoreTime, Search } from "@mui/icons-material";
import {
  Box,
  List,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router";
import SearchBox from "../components/searchbox";
import { FbGetTransactions } from "../core/firebase";
import { Moneyfy, ShortifyAddress } from "../core/helper";
import { Transaction } from "payant-lib";
import LoadingIndicator from "../components/loadingIndicator";
import { useRecoilState } from "recoil";
import { aPageTitle } from "../states/ui";
import { RestGetTransactions } from "../core/api";
import SortIcon from "../components/sortIcon";
import PageTitle from "../components/pageTitle";

export default function SectionTransactions() {
  const [transactions, setTransactions] = React.useState<Transaction[]>([]);
  const [filter, setFilter] = React.useState("");
  const [, setPageTitle] = useRecoilState(aPageTitle);
  const [busy, setBusy] = React.useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  const [sort, setSort] = React.useState<
    "name" | "client" | "date" | "type" | "from" | "to" | "amount"
  >("name");

  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  // Toggles sorting (mode and order)
  function toggleSort(
    sorting: "name" | "client" | "date" | "type" | "from" | "to" | "amount"
  ) {
    // If the specified sorting is same as previous, we toggle the order only.
    if (sort === sorting) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Change the sorting only
      setSort(sorting);
    }
  }

  function getItems(): Transaction[] {
    // Apply sorting.
    let _items = transactions;
    switch (sort) {
      case "amount": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.amount - b.amount);
        } else {
          _items.sort((a, b) => b.amount - a.amount);
        }
        break;
      }

      case "date": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.time - b.time);
        } else {
          _items.sort((a, b) => b.time - a.time);
        }
        break;
      }

      case "from": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.from.localeCompare(b.from));
        } else {
          _items.sort((a, b) => b.from.localeCompare(a.from));
        }
        break;
      }

      case "to": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.to.localeCompare(b.to));
        } else {
          _items.sort((a, b) => b.to.localeCompare(a.to));
        }
        break;
      }

      case "type": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.type.localeCompare(b.type));
        } else {
          _items.sort((a, b) => b.type.localeCompare(a.type));
        }
        break;
      }

      case "name": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.invoiceName.localeCompare(b.invoiceName));
        } else {
          _items.sort((a, b) => b.invoiceName.localeCompare(a.invoiceName));
        }
        break;
      }

      case "client": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.clientName.localeCompare(b.clientName));
        } else {
          _items.sort((a, b) => b.clientName.localeCompare(a.clientName));
        }
        break;
      }
    }
    // Aplpy filter and return
    if (Boolean(filter)) {
      return _items.filter(
        (c) => c.invoiceId.toString().includes(filter) || c.id.includes(filter)
      );
    } else return _items;
  }

  async function loadTxs() {
    try {
      // load clients
      const clis = await RestGetTransactions();
      setTransactions(clis);
    } catch (err: any) {
      enqueueSnackbar(
        "Error loading transactions. Please see logs for details.",
        {
          variant: "error",
        }
      );
      console.log(err);
    }
    setBusy(false);
  }

  React.useEffect(() => {
    setPageTitle("Transactions");
    setBusy(true);
    loadTxs();
    // const timer = setInterval(loadTxs, 10000);
    //return () => clearInterval(timer);
  }, []);

  function RenderTx(tx: Transaction) {
    return (
      <TableRow>
        <TableCell align="left">{tx.invoiceName}</TableCell>
        <TableCell align="left">{tx.clientName}</TableCell>

        <TableCell align="left">
          {moment(new Date(tx.time)).format("Do MMM. YYYY")}
        </TableCell>
        <TableCell align="left">{tx.type.toUpperCase()}</TableCell>
        {isDesktop && (
          <TableCell align="left">{ShortifyAddress(tx.from)}</TableCell>
        )}
        {isDesktop && (
          <TableCell align="left">{ShortifyAddress(tx.to)}</TableCell>
        )}
        <TableCell align="right">{Moneyfy(tx.amount)}</TableCell>
      </TableRow>
    );
  }

  return (
    <Stack
      className="payant-section"
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        px: isDesktop ? "24px" : "18px",
      }}
    >
      <PageTitle />
      <Stack
        sx={{ pt: isDesktop ? "48px" : "12px", width: "100%" }}
        spacing={isDesktop ? 0 : "8px"}
      >
        {/* The ssearch box  */}
        <SearchBox
          placeholder="Search for hash, invoice.."
          onChange={(c) => setFilter(c.currentTarget.value)}
        />

        <Box
          sx={{
            mt: isDesktop ? "24px" : "12px",
            px: isDesktop ? "20px" : "18px",
            paddingTop: isDesktop ? "15px" : "0px",
            paddingBottom: isDesktop ? "30px" : "0px",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {/* Main container  */}
          <Stack sx={{ height: "100%", overflow: "hidden" }}>
            {busy && <LoadingIndicator />}
            {!busy && (
              <Stack
                className={"payant-section-scroll-container"}
                flex={getItems().length > 0 ? 1 : "none"}
                sx={{
                  overflow: "hidden",
                }}
              >
                <TableContainer sx={{ flex: 1 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{ m: 0, p: 0 }}
                          onClick={() => toggleSort("name")}
                        >
                          Invoice Name
                          <SortIcon
                            order={sortOrder}
                            active={sort === "name"}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => toggleSort("client")}
                        >
                          Client Name
                          <SortIcon
                            order={sortOrder}
                            active={sort === "client"}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => toggleSort("date")}
                        >
                          Date
                          <SortIcon
                            order={sortOrder}
                            active={sort === "date"}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => toggleSort("type")}
                        >
                          Type
                          <SortIcon
                            order={sortOrder}
                            active={sort === "type"}
                          />
                        </TableCell>
                        {isDesktop && (
                          <TableCell
                            align="left"
                            onClick={() => toggleSort("from")}
                          >
                            From
                            <SortIcon
                              order={sortOrder}
                              active={sort === "from"}
                            />
                          </TableCell>
                        )}
                        {isDesktop && (
                          <TableCell
                            align="left"
                            onClick={() => toggleSort("to")}
                          >
                            To
                            <SortIcon
                              order={sortOrder}
                              active={sort === "to"}
                            />
                          </TableCell>
                        )}
                        <TableCell
                          align="right"
                          onClick={() => toggleSort("amount")}
                        >
                          Amount
                          <SortIcon
                            order={sortOrder}
                            active={sort === "amount"}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ overflow: "auto" }}>
                      {getItems().map((c) => RenderTx(c))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            )}

            {Boolean(filter) && !busy && getItems().length === 0 && (
              <Stack flex={1} justifyContent="center" alignItems="center">
                <Search
                  color="primary"
                  sx={{ opacity: 0.5, height: "256px", width: "256px" }}
                />
                <Typography fontSize={"10px"} fontStyle="italic">
                  Your search didn't match anything. Try something else?
                </Typography>
              </Stack>
            )}

            {transactions.length === 0 && !busy && (
              <Stack flex={1} justifyContent="center" alignItems="center">
                <MoreTime
                  color="primary"
                  sx={{ opacity: 0.5, height: "256px", width: "256px" }}
                />
                <Typography fontSize={"10px"} fontStyle="italic">
                  There's nothing to see.
                </Typography>
              </Stack>
            )}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
