import { EmailOutlined } from "@mui/icons-material";
import { Badge, Box, Stack, Typography } from "@mui/material";
import { applyActionCode, getAuth } from "firebase/auth";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PAYANT_LOGO_TRANSPARENT } from "../assets/assets";

/**
 * Used by the /auth handler to verify an account using oobCode receievd through email.
 */
export default function PageVerify() {
  const loc = useLocation();
  const nav = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const code = loc.state?.code;

  // Location.State should contain the username and hashed password
  async function verifyEmail() {
    try {
      await setLoading(true);
      // Proceed.
      await applyActionCode(getAuth(), code!);
      enqueueSnackbar("Your account has been confirmed.", {
        variant: "success",
      });
      setConfirmed(true);
      nav("/portal/dashboard?onboarding=true");
    } catch (err: any) {
      enqueueSnackbar(
        "Error verifying your account. Please contact administrators.",
        {
          variant: "error",
        }
      );
      console.log(err);
    }
    await setLoading(false);
  }
  async function loadSupport() {
    try {
      // Here we show the bubbble
      (window as any).Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "xrmsb03k",
      });
    } catch (err: any) {}
  }

  useEffect(() => {
    if (!code) {
      // Redirect to /
      nav("/");
      return;
    }
    verifyEmail();
    loadSupport();
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        background:
          "transparent linear-gradient(119deg, #F3F3F3 0%, #EEEEEE 100%) 0% 0% no-repeat padding-box;",
      }}
    >
      <Stack
        alignItems={"center"}
        justifyContent="center"
        sx={{
          width: "100%",
          minHeight: "85px",
          background: "#FFFFFF 0% 0% no-repeat padding-box;",
          boxShadow: "0px 3px 6px #00000029;",
        }}
      >
        <img src={PAYANT_LOGO_TRANSPARENT} alt="Payant.io Logo" width={196} />
      </Stack>
      <Stack
        spacing={"35px"}
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "214px" }}
      >
        <Badge
          variant="dot"
          color="primary"
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <EmailOutlined color="primary" fontSize="large" />
        </Badge>
        <Typography fontSize="24px">Account Verification</Typography>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Typography fontSize="24px" textAlign={"center"}>
            {loading
              ? "Please wait while we confirm your email address.."
              : confirmed
              ? "Your email has been confirmed."
              : "We couldn't verify your email address. Please try again."}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
