import axios from "axios";
import { getAuth } from "firebase/auth";
import { AppConfig } from "../config";
import { Client, Invoice, Transaction } from "payant-lib";

const BASE = AppConfig.api;

/**
 *
 * @returns The token used for authentication on server side.
 */
export async function getToken() {
  const user = await getAuth().currentUser!;
  const tokenId = await user.getIdToken(true);
  return tokenId;
}

/**
 * Sends invoice transactional emails based on its status.
 * @param invoiceId
 * @returns
 */
export async function SendInvoice(invoiceId: string) {
  try {
    const resp = await axios.post(BASE + "/email/invoice", {
      invoiceId: invoiceId,
    });
    return resp.data as {
      status: boolean;
      code: number;
      message: string;
      data: any;
    };
  } catch (err: any) {
    // For errors, the data is contained within error.response.data
    console.log(err);
    return err.response.data as {
      status: boolean;
      code: number;
      message: string;
      data: any;
    };
  }
}

/**
 * Returns list of all the invoices of the current user.
 * @returns
 */
export async function RestGetInvoices() {
  // Get the invoices from invoice/own/ api.
  // The endpoint supports pagination, but for now it is unused because our current design does not support pagination.
  const token = await getToken();
  const invoices = [];
  let last = "";
  while (true) {
    const resp = await axios.get(BASE + "/invoice/own/", {
      params: {
        size: 100,
        ...(last ? { last: last } : {}),
      },
      headers: {
        authorization: "bearer " + token,
      },
    });
    last = resp.data.data.last;
    const invs = resp.data.data.data as Invoice[];
    invoices.push(...invs);
    if (!last) break;
  }
  return invoices;
}

export async function RestGetInvoice(id: string) {
  const resp = await axios.get(BASE + "/invoice/" + id);

  return resp.data.data as Invoice;
}

/**
 * Returns list of all the invoices of the current user.
 * @returns
 */
export async function RestGetRecentInvoices() {
  // Get the invoices from invoice/own/ api.
  // The endpoint supports pagination, but for now it is unused because our current design does not support pagination.
  const token = await getToken();
  const resp = await axios.get(BASE + "/invoice/own/", {
    params: {
      size: 20,
    },
    headers: {
      authorization: "bearer " + token,
    },
  });
  return resp.data.data.data as Invoice[];
}

/**
 * Returns list of all the clients.
 * @returns
 */
export async function RestGetClients() {
  // Get the invoices from /clients api.
  // The endpoint supports pagination, but for now it is unused because our current design does not support pagination.
  const token = await getToken();
  const clients = [];
  let last = "";
  while (true) {
    const resp = await axios.get(BASE + "/clients", {
      params: {
        size: 100,
        ...(last ? { last: last } : {}),
      },
      headers: {
        authorization: "bearer " + token,
      },
    });
    last = resp.data.data.last;
    const invs = resp.data.data.data as Client[];
    clients.push(...invs);
    if (!last) break;
  }
  return clients;
}

/**
 * Returns list of all the transactions
 * @returns
 */
export async function RestGetTransactions() {
  // Get the invoices from /clients api.
  // The endpoint supports pagination, but for now it is unused because our current design does not support pagination.
  const token = await getToken();
  const txs = [];
  let last = "";
  while (true) {
    const resp = await axios.get(BASE + "/transactions/own/", {
      params: {
        size: 100,
        ...(last ? { last: last } : {}),
      },
      headers: {
        authorization: "bearer " + token,
      },
    });
    last = resp.data.data.last;
    const invs = resp.data.data.data as Transaction[];
    txs.push(...invs);
    if (!last) break;
  }
  return txs;
}

/**
 * Deletes the specified invoice.
 * @param invoiceId
 */
export async function RestDeleteInvoice(invoiceId: string) {
  const token = await getToken();
  const resp = await axios.delete(BASE + "/invoice/" + invoiceId, {
    headers: {
      authorization: "bearer " + token,
    },
  });
  return resp.data as {
    status: boolean;
    code: number;
    message: string;
    data: any;
  };
}
