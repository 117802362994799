import React from "react";
import { useLocation, useNavigate } from "react-router";

/**
 * Handles firebase auth redirect based on action.
 * @returns
 */
export default function PageAuthRedirect() {
  const loc = useLocation();
  const nav = useNavigate();
  const params = new URLSearchParams(loc.search);
  const mode = params.get("mode");
  const code = params.get("oobCode");

  React.useEffect(() => {
    // Check the code and action
    switch (mode) {
      case "resetPassword":
        // Display reset password handler and UI.
        nav("/recover", { state: { code: code } });
        break;
      case "verifyEmail":
        // Display email verification handler and UI.
        nav("/verify", { state: { code: code } });
        break;
      default:
        // Error: invalid mode.
        nav("/");
    }
  }, []);

  return <div>Redirecting, please wait..</div>;
}
