import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
export default function LoadingIndicator(props: { height?: any }) {
  return (
    <div
      style={{
        zIndex: 999,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Player
        autoplay
        loop
        src="https://assets5.lottiefiles.com/packages/lf20_EfQil8GTV7.json"
        style={{ width: "100px", ...props }}
      ></Player>
    </div>
  );
}
