import {
  Avatar,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import PayantTextField from "../components/textfield";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ResetPassword } from "../rest/user";
import { PHOENIX } from "../assets/assets";
import { CheckCircleOutline } from "@mui/icons-material";

export default function PagePasswordReset() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [email, setEmail] = useState("");

  // For redirecting
  const nav = useNavigate();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  // For errors and msgs
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Parse the input and send it to the register function.
   */
  async function reset() {
    // Get the data.
    setError(false);
    try {
      if (Boolean(email) === false) {
        enqueueSnackbar("Please enter a valid email address.", {
          variant: "error",
        });
        setError(true);
        return;
      }
      // Send the data to /api/v1/user/register/
      setLoading(true);
      const resp = await ResetPassword(email!);
      if (resp.status) {
        // Logged in. Login to Firebase and Redirect to /dashboard
        // Set the logged in state
        setIsSent(true);
      } else {
        // Error.
        enqueueSnackbar("ERROR: " + resp.message, { variant: "error" });
      }
    } catch (err: any) {
      enqueueSnackbar(
        "Error sending recovery email; Please contact administrators.",
        { variant: "error" }
      );
      console.log(err);
    }
    setLoading(false);
  }

  async function loadSupport() {
    try {
      // Here we show the bubbble
      (window as any).Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "xrmsb03k",
      });
    } catch (err: any) {}
  }

  useEffect(() => {
    loadSupport();
  }, []);

  return (
    <Grid
      container
      columns={2}
      sx={{
        width: "100%",
        ...(isDesktop
          ? {}
          : { flexDirection: "column-reverse", flexWrap: "nowrap" }),
      }}
    >
      {/* On desktop, each item uses 1 column. On mobile, the items occupy all columns and the layout is also reversed, so 
      that the login section appears before the message. */}
      <Grid item xs={isDesktop ? 1 : 2}>
        <Stack
          justifyContent="center"
          alignItems="center"
          flex={1}
          sx={{ background: "#5D18EB", color: "white", height: "100%" }}
        >
          <Stack>
            <Typography
              sx={{
                fontFamily: "Baloo",
                fontSize: isDesktop ? "99px" : "28px",
                lineHeight: isDesktop ? "104px" : "30px",
                ml: isDesktop ? 0 : "32px",
                mt: isDesktop ? 0 : "32px",
              }}
            >
              GET PAID
              <br />
              ON TIME.
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Bakbak One'",
                fontSize: "99px",
                lineHeight: "0px",
                mt: "40px",
                color: "#80E7D6",
                ml: "32px",
              }}
            >
              “
            </Typography>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                lineHeight: "14.5px",
                color: "#FFFFFF",
                width: "329px",
                ml: "32px",
              }}
            >
              Why is it so hard to get paid?
              <br />
              <br />
              Well, founder Emily often asked herself the same thing. She got
              paid late more times than she can count in the 7 years she worked
              as a contractor in Web 3 and Crypto, and the big companies were
              the worst. She was so over chasing invoices, that she decided to
              build a solution herself.
              <br />
              <br />
              With Payant, you get access to a web 2.5 platform that secures
              your payments in a smart escrow, so you never get paid late again.
            </Typography>
            <Stack
              sx={{
                borderLeft: "1px solid #80E7D6",
                height: isDesktop ? "200px" : "100px",
                my: "10px",
                pl: "22px",
                pt: "30px",
              }}
            >
              <Stack direction="row" alignItems="center" spacing={"12px"}>
                <Avatar
                  sx={{ height: "46px", width: "46px" }}
                  src={PHOENIX}
                  alt="Emily Rose Dallara"
                />
                <Stack spacing={"6px"}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "14px",
                      fontFamily: "Inter",
                    }}
                  >
                    Emily Rose Dallara
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      fontFamily: "Inter",
                    }}
                  >
                    CEO & Founder
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
      {/* The login section  */}
      <Grid item xs={isDesktop ? 1 : 2}>
        <Stack
          flex={1}
          sx={{ background: "#FFFFFF", height: "100vh", px: "18px" }}
          justifyContent="center"
          alignItems={isDesktop ? "center" : "inherit"}
        >
          {!isSent && (
            <Stack spacing={"18px"}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: isDesktop ? "44px" : "24px",
                  fontWeight: 700,
                  lineHeight: "53px",
                }}
              >
                Reset Your Password
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "14px",
                  maxWidth: "400px",
                }}
              >
                Please enter your account email address. You will receive a link
                to reset your password.
              </Typography>
              {/* <img
            style={{ marginTop: "10px", alignSelf: "center" }}
            src={PAYANT_LOGO_TRANSPARENT}
            alt="Payant.io Logo"
            width={196}
          /> */}
              <PayantTextField
                label="Email Address"
                placeholder="Email address"
                error={error}
                disabled={loading}
                autoComplete="email"
                onChange={(c) => setEmail(c.currentTarget.value)}
              />
              <Button variant="contained" disabled={loading} onClick={reset}>
                Reset Password
              </Button>
              <NavLink
                style={{
                  alignSelf: "center",
                  fontSize: "12px",
                  textDecoration: "none",
                }}
                to="/login"
              >
                Nevermind, I know my password
              </NavLink>
            </Stack>
          )}
          {isSent && (
            <Stack
              flex={1}
              sx={{ background: "#FFFFFF" }}
              justifyContent="center"
              alignItems="center"
              spacing={"10px"}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: isDesktop ? "44px" : "24px",
                  fontWeight: 700,
                  lineHeight: "53px",
                }}
              >
                Reset Your Password
              </Typography>
              <CheckCircleOutline color="primary" fontSize="large" />
              <Typography color="primary" textAlign={"center"}>
                Check Your Email!
              </Typography>
              <Typography fontSize="12px" textAlign={"center"}>
                We have sent you an email with the link to reset your password.
              </Typography>
              <NavLink
                style={{
                  alignSelf: "center",
                  fontSize: "12px",
                  textDecoration: "none",
                }}
                to="/login"
              >
                Back to Login
              </NavLink>
            </Stack>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
