import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import React from "react";

export default function SortIcon(props: {
  active: boolean;
  order: "asc" | "desc";
}) {
  return (
    <>
      {props.active && props.order === "asc" && (
        <ArrowDownward
          sx={{ fontSize: "12px" }}
          fontSize="small"
          color={"primary"}
        />
      )}
      {props.active && props.order === "desc" && (
        <ArrowUpward sx={{ fontSize: "12px" }} color={"primary"} />
      )}
    </>
  );
}
