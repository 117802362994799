import {
  Button,
  Paper,
  PaperProps,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router";
import {
  GetInvoiceStatus,
  GetInvoiceStatusColor,
  Moneyfy,
  isQuote,
} from "../core/helper";
import InvoiceDialog from "../dialogs/invoiceDetails";
import { INVOICE_STATUS, Invoice } from "payant-lib";
import LoadingIndicator from "./loadingIndicator";
import { RestGetInvoices } from "../core/api";
import SortIcon from "./sortIcon";
import { Delete } from "@mui/icons-material";
import DeleteQuoteDialog from "../dialogs/deleteQuoteDialog";
import BusinessDetailsDialog from "../dialogs/clientDetails";

export default function RecentQuotes(
  props: PaperProps & { mockData?: boolean }
) {
  const [invoices, setInvoices] = React.useState<Invoice[]>([]);
  const [busy, setBusy] = React.useState(false);
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const nav = useNavigate();

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  const [sort, setSort] = React.useState<
    "name" | "client" | "status" | "deadline" | "amount"
  >("deadline");
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = {
      x: event.clientX,
      y: event.currentTarget.getBoundingClientRect().y,
    };
  };

  function toggleSort(
    sorting: "name" | "client" | "status" | "deadline" | "amount"
  ) {
    // If the specified sorting is same as previous, we toggle the order only.
    if (sort === sorting) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Change the sorting only
      setSort(sorting);
    }
  }

  // Returns the items for the table view. This function is used to filter and sort items based on various UI selections.
  function getItems() {
    let _invs = invoices;
    // Apply sorting
    switch (sort) {
      case "amount": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.total - b.total);
        } else {
          _invs.sort((a, b) => b.total - a.total);
        }
        break;
      }
      case "deadline": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.endDate - b.endDate);
        } else {
          _invs.sort((a, b) => b.endDate - a.endDate);
        }
        break;
      }

      case "client": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.client.name.localeCompare(b.client.name));
        } else {
          _invs.sort((a, b) => b.client.name.localeCompare(a.client.name));
        }
        break;
      }

      case "name": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          _invs.sort((a, b) => b.name.localeCompare(a.name));
        }
        break;
      }

      case "status": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.status.localeCompare(b.status));
        } else {
          _invs.sort((a, b) => b.status.localeCompare(a.status));
        }
        break;
      }
    }

    // Apply filter and return
    return _invs;
  }

  async function loadInvoices() {
    if (props.mockData === true) {
      const _eyes: Invoice[] = [];
      for (let i = 0; i < 100; i += 1) {
        _eyes.push({
          endDate: 24123123,
          id: "test+" + i,
          client: {
            id: "test+" + i,
            name: "Test " + i,
          } as any,
          contractor: {
            fullName: "Test " + i,
          } as any,
          name: "Test " + i,
          status: INVOICE_STATUS.DRAFT,

          total: 3000,
        } as any);
      }
      setInvoices(_eyes);
      return;
    }
    setBusy(true);
    try {
      const invs = (await RestGetInvoices()).filter((a) => isQuote(a));
      setInvoices(invs.sort((a, b) => b.dateCreated - a.dateCreated));
    } catch (err: any) {
      enqueueSnackbar(
        "Error loading proposals. Please contact administrators.",
        {
          variant: "error",
        }
      );
      console.log(err);
    }
    setBusy(false);
  }

  function showDeleteDialog(inv: Invoice) {
    const modal: any = showModal(DeleteQuoteDialog, {
      closeHandler: () => {
        modal.destroy();
        loadInvoices();
      },
      isOpen: true,
      data: inv,
      canDismiss: true,
    });
  }

  function showInvoiceDialog(inv: Invoice) {
    // If it's a draft, we open editor.
    if (inv.status === INVOICE_STATUS.DRAFT) {
      nav("/portal/invoices/create", { state: { invoice: inv } });
    } else {
      const modal: any = showModal(InvoiceDialog, {
        canDismiss: true,
        closeHandler: () => modal.destroy(),
        isOpen: true,
        data: inv,
      });
    }
  }
  function showClientDialog(inv: Invoice) {
    const modal: any = showModal(BusinessDetailsDialog, {
      canDismiss: true,
      closeHandler: () => modal.destroy(),
      isOpen: true,
      data: inv.client,
    });
  }

  React.useEffect(() => {
    loadInvoices();
  }, []);

  function RenderInvoiceItem(invoice: Invoice, i: number) {
    const row = (
      <TableRow
        sx={{ background: "transparent" }}
        key={invoice.id}
        onClick={() => showInvoiceDialog(invoice)}
        onMouseMove={handleMouseMove}
        {...(i === 0 ? { id: "dash_prop_1" } : {})}
      >
        <TableCell sx={{ padding: 0 }}>{invoice.name}</TableCell>
        <TableCell sx={{ fontSize: isDesktop ? "16px" : "10px" }}>
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                textDecoration: "underline",
                fontWeight: "bold",
                fontSize: isDesktop ? "16px" : "10px",
              }}
              onClick={(e) => {
                showClientDialog(invoice);
                e.stopPropagation();
              }}
            >
              {invoice.client?.name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{ fontSize: isDesktop ? "16px" : "10px" }}>
          {GetInvoiceStatus(invoice)}
        </TableCell>
        {isDesktop && (
          <TableCell sx={{ fontSize: isDesktop ? "16px" : "10px" }}>
            <Tooltip title="This is the estimated date to which your deliverables will be sent for approval.">
              <span>
                {" "}
                {invoice.endDate
                  ? moment(new Date(invoice.endDate)).format("Do MMM. YYYY")
                  : "Not Specified"}
              </span>
            </Tooltip>
          </TableCell>
        )}
        <TableCell
          sx={{ fontSize: isDesktop ? "16px" : "10px", textAlign: "left" }}
          align="left"
        >
          {Moneyfy(invoice?.total || 0)}
        </TableCell>
      </TableRow>
    );

    // If its a draft, we add tooltip
    if (isQuote(invoice)) {
      return (
        <Tooltip
          sx={{ background: "white" }}
          arrow
          enterDelay={1000}
          leaveDelay={0}
          enterNextDelay={1000}
          PopperProps={{
            anchorEl: {
              getBoundingClientRect: () => {
                return new DOMRect(
                  positionRef.current.x,
                  positionRef.current.y + 20,
                  0,
                  0
                );
              },
            },
          }}
          placement="bottom"
          title={
            <Paper sx={{ background: "white" }}>
              <Button
                color="error"
                startIcon={<Delete color="error" />}
                onClick={() => showDeleteDialog(invoice)}
              >
                DELETE
              </Button>
            </Paper>
          }
        >
          {row}
        </Tooltip>
      );
    }

    return row;
  }
  return (
    <>
      {busy && <LoadingIndicator />}
      {!busy && (
        <TableContainer sx={{ overflowY: "auto" }}>
          <Table
            className="dashboardTableContainer"
            stickyHeader
            size="small"
            border={0}
            sx={{ px: "20px" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ p: 0, fontSize: isDesktop ? "12px" : "10px" }}
                  onClick={() => toggleSort("name")}
                >
                  Proposal Name
                  <SortIcon order={sortOrder} active={sort === "name"} />
                </TableCell>
                <TableCell
                  sx={{ fontSize: isDesktop ? "12px" : "10px" }}
                  onClick={() => toggleSort("client")}
                >
                  Client
                  <SortIcon order={sortOrder} active={sort === "client"} />
                </TableCell>
                <TableCell
                  align="justify"
                  sx={{ flex: 1, fontSize: isDesktop ? "12px" : "10px" }}
                  onClick={() => toggleSort("status")}
                >
                  Status
                  <SortIcon order={sortOrder} active={sort === "status"} />
                </TableCell>
                {isDesktop && (
                  <TableCell onClick={() => toggleSort("deadline")}>
                    <Tooltip title="This is the estimated date to which your deliverables will be sent for approval.">
                      <span>Deadline</span>
                    </Tooltip>
                    <SortIcon order={sortOrder} active={sort === "deadline"} />
                  </TableCell>
                )}
                <TableCell
                  sx={{ fontSize: isDesktop ? "12px" : "10px" }}
                  align="left"
                  onClick={() => toggleSort("amount")}
                >
                  Amount
                  <SortIcon order={sortOrder} active={sort === "amount"} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getItems()?.map((c, i) => RenderInvoiceItem(c, i))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
