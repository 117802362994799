import {
  PaperProps,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router";
import { GetInvoiceStatus, Moneyfy, isInvoice } from "../core/helper";
import InvoiceDialog from "../dialogs/invoiceDetails";
import BusinessDetailsDialog from "../dialogs/clientDetails";
import { Invoice } from "payant-lib";
import LoadingIndicator from "./loadingIndicator";
import { RestGetInvoices } from "../core/api";
import SortIcon from "./sortIcon";

export default function RecentInvoices(props: PaperProps) {
  const [invoices, setInvoices] = React.useState<Invoice[]>([]);
  const [busy, setBusy] = React.useState(false);
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  const [sort, setSort] = React.useState<
    "name" | "client" | "status" | "deadline" | "amount"
  >("deadline");
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");

  const nav = useNavigate();

  function toggleSort(
    sorting: "name" | "client" | "status" | "deadline" | "amount"
  ) {
    // If the specified sorting is same as previous, we toggle the order only.
    if (sort === sorting) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Change the sorting only
      setSort(sorting);
    }
  }

  function getItems() {
    let _invs = invoices;

    // Apply sorting
    switch (sort) {
      case "amount": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.total - b.total);
        } else {
          _invs.sort((a, b) => b.total - a.total);
        }
        break;
      }
      case "deadline": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.endDate - b.endDate);
        } else {
          _invs.sort((a, b) => b.endDate - a.endDate);
        }
        break;
      }

      case "client": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.client.name.localeCompare(b.client.name));
        } else {
          _invs.sort((a, b) => b.client.name.localeCompare(a.client.name));
        }
        break;
      }

      case "name": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          _invs.sort((a, b) => b.name.localeCompare(a.name));
        }
        break;
      }

      case "status": {
        if (sortOrder === "asc") {
          _invs.sort((a, b) => a.status.localeCompare(b.status));
        } else {
          _invs.sort((a, b) => b.status.localeCompare(a.status));
        }
        break;
      }
    }
    return _invs;
  }

  function showInvoiceDialog(inv: Invoice) {
    const modal: any = showModal(InvoiceDialog, {
      canDismiss: true,
      closeHandler: () => modal.destroy(),
      isOpen: true,
      data: inv,
    });
  }

  function showClientDialog(inv: Invoice) {
    const modal: any = showModal(BusinessDetailsDialog, {
      canDismiss: true,
      closeHandler: () => modal.destroy(),
      isOpen: true,
      data: inv.client,
    });
  }

  async function loadInvoices() {
    setBusy(true);
    try {
      // load clients
      const invs = (await RestGetInvoices()).filter((a) => isInvoice(a));
      setInvoices(invs.sort((a, b) => b.dateCreated - a.dateCreated));
    } catch (err: any) {
      enqueueSnackbar(
        "Error loading invoices. Please contact administrators.",
        {
          variant: "error",
        }
      );
      console.log(err);
    }
    setBusy(false);
  }

  React.useEffect(() => {
    loadInvoices();
  }, []);

  function RenderInvoiceItem(invoice: Invoice) {
    return (
      <TableRow key={invoice.id} onClick={() => showInvoiceDialog(invoice)}>
        <TableCell sx={{ p: 0 }}>{invoice.invoiceNumber}</TableCell>
        <TableCell sx={{ p: 0 }}>{invoice.name}</TableCell>
        <TableCell sx={{ fontSize: isDesktop ? "16px" : "10px" }}>
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                textDecoration: "underline",
                fontWeight: "bold",
                fontSize: isDesktop ? "16px" : "10px",
              }}
              onClick={(e) => {
                showClientDialog(invoice);
                e.stopPropagation();
              }}
            >
              {invoice.client?.name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            textAlign: "left",
            fontSize: isDesktop ? "16px" : "10px",
          }}
        >
          {Moneyfy(invoice?.total || 0)}
        </TableCell>

        {isDesktop && (
          <TableCell sx={{ fontSize: isDesktop ? "16px" : "10px" }}>
            <Tooltip title="This is the estimated date to which your deliverables will be sent for approval.">
              <span>
                {invoice.endDate
                  ? moment(new Date(invoice.endDate)).format("Do MMM. YYYY")
                  : "Not Specified"}
              </span>
            </Tooltip>
          </TableCell>
        )}
        <TableCell sx={{ fontSize: isDesktop ? "16px" : "10px" }}>
          {GetInvoiceStatus(invoice)}
        </TableCell>
      </TableRow>
    );
  }
  return (
    <>
      {busy && <LoadingIndicator />}
      {!busy && (
        <TableContainer sx={{ overflow: "auto" }}>
          <Table
            stickyHeader
            size="small"
            border={0}
            sx={{ px: "20px", flex: 1 }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ p: 0, fontSize: isDesktop ? "12px" : "10px" }}
                >
                  Invoice #
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ p: 0, fontSize: isDesktop ? "12px" : "10px" }}
                  onClick={() => toggleSort("name")}
                >
                  Invoice Name
                  <SortIcon order={sortOrder} active={sort === "name"} />
                </TableCell>
                <TableCell
                  sx={{ fontSize: isDesktop ? "12px" : "10px" }}
                  onClick={() => toggleSort("client")}
                >
                  Client Name
                  <SortIcon order={sortOrder} active={sort === "client"} />
                </TableCell>
                <TableCell
                  sx={{ fontSize: isDesktop ? "12px" : "10px" }}
                  align="left"
                  onClick={() => toggleSort("amount")}
                >
                  Amount
                  <SortIcon order={sortOrder} active={sort === "amount"} />
                </TableCell>
                {isDesktop && (
                  <TableCell onClick={() => toggleSort("deadline")}>
                    <Tooltip title="This is the estimated date to which your deliverables will be sent for approval.">
                      <span>Deadline</span>
                    </Tooltip>
                    <SortIcon order={sortOrder} active={sort === "deadline"} />
                  </TableCell>
                )}
                <TableCell
                  align="justify"
                  sx={{ flex: 1, fontSize: isDesktop ? "12px" : "10px" }}
                  onClick={() => toggleSort("status")}
                >
                  Status
                  <SortIcon order={sortOrder} active={sort === "status"} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getItems()?.map((c) => RenderInvoiceItem(c))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
