import {
  AddCircleOutlineOutlined,
  MoreTime,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router";
import SearchBox from "../components/searchbox";
import { Moneyfy, isActiveInvoice } from "../core/helper";
import BusinessDetailsDialog from "../dialogs/clientDetails";
import { Client, INVOICE_STATUS } from "payant-lib";
import LoadingIndicator from "../components/loadingIndicator";
import SexyCard from "../components/sexyCard";
import { useRecoilState } from "recoil";
import { aPageTitle } from "../states/ui";
import { ClientsIcon } from "../assets/icons";
import { RestGetClients, RestGetInvoices } from "../core/api";
import SortIcon from "../components/sortIcon";
import PageTitle from "../components/pageTitle";

export default function SectionClients() {
  const [clients, setClients] = React.useState<
    (Client & {
      pendingQuotes: number;
      activeInvoices: number;
      totalLocked: number;
      readyToWithdraw: number;
      totalRevenues: number;
    })[]
  >([]);
  const [filter, setFilter] = React.useState("");
  const [activeClients, setActiveClients] = React.useState(0);
  const [inactiveClients, setInActiveClients] = React.useState(0);

  const [, setPageTitle] = useRecoilState(aPageTitle);
  const [busy, setBusy] = React.useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  const [sort, setSort] = React.useState<
    "name" | "pending" | "active" | "locked" | "ready" | "total"
  >("name");

  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");

  const [viewMode, setViewMode] = React.useState<"active" | "inactive" | "all">(
    "all"
  );

  // Toggles sorting (mode and order)
  function toggleSort(
    sorting: "name" | "pending" | "active" | "locked" | "ready" | "total"
  ) {
    // If the specified sorting is same as previous, we toggle the order only.
    if (sort === sorting) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Change the sorting only
      setSort(sorting);
    }
  }

  // Toggles the view mode
  function toggleViewMode(mode: "active" | "inactive" | "all") {
    if (mode === viewMode) {
      setViewMode("all");
    } else {
      setViewMode(mode);
    }
  }

  function getItems() {
    // Apply viewmode.
    let _items = [];
    // Apply view mode
    switch (viewMode) {
      case "all":
        _items = clients;
        break;
      case "active":
        _items = clients.filter((a) => a.activeInvoices > 0);
        break;
      case "inactive":
        _items = clients.filter((a) => a.activeInvoices <= 0);
        break;
    }

    // Apply sorting
    switch (sort) {
      case "total": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.totalRevenues - b.totalRevenues);
        } else {
          _items.sort((a, b) => b.totalRevenues - a.totalRevenues);
        }
        break;
      }
      case "ready": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.readyToWithdraw - b.readyToWithdraw);
        } else {
          _items.sort((a, b) => b.readyToWithdraw - a.readyToWithdraw);
        }
        break;
      }

      case "locked": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.totalLocked - b.totalLocked);
        } else {
          _items.sort((a, b) => b.totalLocked - a.totalLocked);
        }
        break;
      }

      case "pending": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.pendingQuotes - b.pendingQuotes);
        } else {
          _items.sort((a, b) => b.pendingQuotes - a.pendingQuotes);
        }
        break;
      }

      case "active": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.activeInvoices - b.activeInvoices);
        } else {
          _items.sort((a, b) => b.activeInvoices - a.activeInvoices);
        }
        break;
      }

      case "name": {
        if (sortOrder === "asc") {
          _items.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          _items.sort((a, b) => b.name.localeCompare(a.name));
        }
        break;
      }
    }

    // Apply filter
    if (Boolean(filter)) {
      return _items.filter(
        (c) => c.name.includes(filter) || c.email.includes(filter)
      );
    } else return _items;
  }

  async function loadClients() {
    //setBusy(true);
    try {
      // load clients
      const _clis: (Client & {
        pendingQuotes: number;
        activeInvoices: number;
        totalLocked: number;
        readyToWithdraw: number;
        totalRevenues: number;
      })[] = [];
      const clis = await RestGetClients();
      const invs = await RestGetInvoices();
      setClients(clis as any);
      // Render active invoices and total active of each client
      for (let c of clis) {
        // Get invoices of this client
        const cInvoices = invs.filter((i) => i.client.id === c.id);
        let pendingQuotes: number = 0;
        let activeInvoices: number = 0;
        let totalLocked: number = 0;
        let readyToWithdraw: number = 0;
        let totalRevenues: number = 0;
        // NOTICE: Admin requested change to show awaiting payment instead of total active, the variable name
        // is same but it shows total of unpaid invoices rather than active.
        for (let ci of cInvoices) {
          // Pending
          if (
            ci.status === INVOICE_STATUS.REVIEW ||
            ci.status === INVOICE_STATUS.REVIEWED
          ) {
            pendingQuotes += 1;
          }
          // Active invoice: not in review or complete status or validated
          if (isActiveInvoice(ci)) {
            activeInvoices++;
            // If its also not approved, it means its cnsidered locked
            if (ci.status !== INVOICE_STATUS.APPROVED) {
              totalLocked += ci.total || 0;
            }
            // IF its approved, its ready to withdraw
            if (ci.status === INVOICE_STATUS.APPROVED) {
              readyToWithdraw += ci.total || 0;
            }
          }
          // If its complete, we make it in paid out
          if (ci.status === INVOICE_STATUS.COMPLETE) {
            totalRevenues += ci.total || 0;
          }
        }
        _clis.push({
          ...c,
          totalRevenues: totalRevenues,
          activeInvoices: activeInvoices,
          totalLocked: totalLocked,
          pendingQuotes: pendingQuotes,
          readyToWithdraw: readyToWithdraw,
        });
      }
      setClients(_clis);

      // load clients
      const _activeClients = _clis.filter((a) => a.activeInvoices > 0).length;
      const _inactiveClients = _clis.length - _activeClients;

      setActiveClients(_activeClients);
      setInActiveClients(_inactiveClients);
    } catch (err: any) {
      enqueueSnackbar("Error loading clients. Please see logs for details.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  function showInfoDialog(cli: Client) {
    const modal: any = showModal(BusinessDetailsDialog, {
      closeHandler: () => modal.destroy(),
      isOpen: true,
      data: cli,
      canDismiss: true,
    });
  }

  React.useEffect(() => {
    setPageTitle("Clients");
    setBusy(true);
    loadClients();
  }, []);

  function RenderClient(
    client: Client & {
      pendingQuotes: number;
      activeInvoices: number;
      totalLocked: number;
      readyToWithdraw: number;
      totalRevenues: number;
    }
  ) {
    return (
      <TableRow onClick={() => showInfoDialog(client)}>
        <TableCell align="left">
          <Stack
            direction="row"
            spacing={"16px"}
            alignItems="center"
            justifyContent="start"
          >
            {isDesktop && <ClientsIcon height="16px" width={"16px"} />}
            <span>{client.name}</span>
          </Stack>{" "}
        </TableCell>

        {isDesktop && (
          <TableCell align="left">{client.pendingQuotes}</TableCell>
        )}
        {isDesktop && (
          <TableCell align="left">{client.activeInvoices}</TableCell>
        )}
        <TableCell align="left">{Moneyfy(client.totalLocked)}</TableCell>
        <TableCell align="left">{Moneyfy(client.readyToWithdraw)}</TableCell>
        <TableCell align="left" style={{ color: "#6DC881" }}>
          {Moneyfy(client.totalRevenues)}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack
      className="payant-section"
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        px: isDesktop ? "24px" : "18px",
      }}
    >
      <PageTitle />
      <Stack flex={1} sx={{ height: "100%" }} spacing={1}>
        <Grid container columns={isDesktop ? 4 : 2} spacing={"10px"}>
          <Grid item xs={1}>
            <SexyCard
              active={viewMode === "active"}
              onClick={() => toggleViewMode("active")}
              label="Active Clients"
              value={activeClients.toString()}
              icon={<ClientsIcon fill={"green"} />}
            />{" "}
          </Grid>
          <Grid item xs={1}>
            <SexyCard
              active={viewMode === "inactive"}
              onClick={() => toggleViewMode("inactive")}
              label="Inactive Clients"
              value={inactiveClients.toString()}
              icon={<ClientsIcon />}
            />{" "}
          </Grid>
        </Grid>

        {/* The search and create button  */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: isDesktop ? "48px" : "12px", width: "100%" }}
          spacing={isDesktop ? 0 : "8px"}
        >
          <SearchBox
            placeholder="Search for a client"
            onChange={(c) => setFilter(c.currentTarget.value)}
          />
          <Button
            onClick={() => navigate("create")}
            variant="contained"
            sx={{
              width: isDesktop ? "136px" : "110px",
              minWidth: isDesktop ? "136px" : "110px",
              fontSize: isDesktop ? "12px" : "10px",
              height: "36px",
              borderRadius: "25px",
              justifyContent: "start",
              px: "10px",
              py: "10px",
              textTransform: "none",
            }}
            startIcon={<AddCircleOutlineOutlined />}
          >
            Create Client
          </Button>
        </Stack>
        <Box
          sx={{
            mt: isDesktop ? "24px" : "12px",
            px: isDesktop ? "20px" : "18px",
            paddingTop: isDesktop ? "15px" : "0px",
            paddingBottom: isDesktop ? "30px" : "0px",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {/* Main container  */}
          <Stack sx={{ height: "100%", overflow: "hidden" }}>
            {busy && <LoadingIndicator />}
            {!busy && (
              <Stack
                className={"payant-section-scroll-container"}
                flex={getItems().length > 0 ? 1 : "none"}
                sx={{
                  overflow: "hidden",
                }}
              >
                <TableContainer sx={{ flex: 1 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{ m: 0, p: 0, pl: isDesktop ? "48px" : 0 }}
                          onClick={() => toggleSort("name")}
                        >
                          Client Name
                          <SortIcon
                            order={sortOrder}
                            active={sort === "name"}
                          />
                        </TableCell>
                        {isDesktop && (
                          <TableCell
                            align="left"
                            onClick={() => toggleSort("pending")}
                          >
                            Pending Proposal
                            <SortIcon
                              order={sortOrder}
                              active={sort === "pending"}
                            />
                          </TableCell>
                        )}
                        {isDesktop && (
                          <TableCell
                            align="left"
                            onClick={() => toggleSort("active")}
                          >
                            Active Invoices
                            <SortIcon
                              order={sortOrder}
                              active={sort === "active"}
                            />
                          </TableCell>
                        )}
                        <TableCell
                          align="left"
                          onClick={() => toggleSort("locked")}
                        >
                          Total Locked
                          <SortIcon
                            order={sortOrder}
                            active={sort === "locked"}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => toggleSort("ready")}
                        >
                          Ready to Withdraw
                          <SortIcon
                            order={sortOrder}
                            active={sort === "ready"}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => toggleSort("total")}
                        >
                          Total Revenues
                          <SortIcon
                            order={sortOrder}
                            active={sort === "total"}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {getItems().length > 0 && (
                      <TableBody sx={{ flex: 1 }}>
                        {getItems().map((c) => RenderClient(c))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Stack>
            )}

            {Boolean(filter) && !busy && getItems().length === 0 && (
              <Stack flex={1} justifyContent="center" alignItems="center">
                <Search
                  color="primary"
                  sx={{ opacity: 0.5, height: "45px", width: "45px" }}
                />
                <Typography fontSize={"10px"} fontStyle="italic">
                  Your search didn't match anything. Try something else?
                </Typography>
              </Stack>
            )}

            {Boolean(filter) === false && !busy && clients.length === 0 && (
              <Stack flex={1} justifyContent="center" alignItems="center">
                <MoreTime
                  color="primary"
                  sx={{ opacity: 0.5, height: "45px", width: "45px" }}
                />
                <Typography fontSize={"10px"} fontStyle="italic">
                  There's nothing to see. Please add some clients.
                </Typography>
              </Stack>
            )}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
