import React from "react";

import {
  Route,
  HashRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import ProtectedRoute from "./providers/ProtectedRoute";
import PageDashboard from "./pages/Dashboard";
import PageLogin from "./pages/Login";
import PageSignup from "./pages/Signup";
import PagePasswordReset from "./pages/Reset";
import PageInvoice from "./pages/Invoice";
import PageRecovery from "./pages/ResetPassword";
import PageVerify from "./pages/Verify";
import PageAuthRedirect from "./pages/Auth";
import ModalProvider from "mui-modal-provider";

export default function AppRoutes() {
  return (
    <Router basename="/">
      <ModalProvider>
        <Routes>
          {/* Add the routes here */}
          <Route path="/invoice" element={<PageInvoice />} />
          <Route path="/login" element={<PageLogin />} />
          <Route path="/signup" element={<PageSignup />} />
          <Route path="/reset-password" element={<PagePasswordReset />} />
          <Route path="/recover" element={<PageRecovery />} />
          <Route path="/confirm" element={<PageVerify />} />
          <Route path="/auth" element={<PageAuthRedirect />} />
          <Route path="/verify" element={<PageVerify />} />
          {/* The dashboard route. This is a protected route and redirects to login  */}
          <Route
            path="/portal/*"
            element={
              <ProtectedRoute fallbackChildren={<PageLogin />}>
                <PageDashboard />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/portal/" />} />
        </Routes>
      </ModalProvider>
    </Router>
  );
}
