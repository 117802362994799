// File provides wrapper functions on the firebase features.
// Having wrappers on the firebase database will allow us to replace
// the database in future just in case.

import { initializeApp } from "firebase/app";
import { AppConfig } from "../config";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  getCountFromServer,
  where,
} from "firebase/firestore";
import { Invoice } from "payant-lib";
import { Client } from "payant-lib";
import { UserProfile } from "payant-lib";
import { Transaction } from "payant-lib";
import { SendVerificationEmail } from "../rest/user";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: AppConfig.firebase.apiKey,
  authDomain: AppConfig.firebase.authDomain,
  projectId: AppConfig.firebase.projectId,
  storageBucket: AppConfig.firebase.storageBucket,
  messagingSenderId: AppConfig.firebase.messagingSenderId,
  appId: AppConfig.firebase.appId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const store = getFirestore(app);

// All the exported functions start with fb
export function FbGetUser() {
  return auth.currentUser;
}

/**
 * Returns the entire business profile of the user from /users/ collection.
 */
export async function FbGetProfile() {
  if (!auth.currentUser) return false;
  const up: Partial<UserProfile> = {
    emailAddress: auth.currentUser.email || "",
    fullName: auth.currentUser.displayName || "",
    phoneNumber: auth.currentUser.phoneNumber || "",
  };
  const rd = doc(store, "users", auth.currentUser.uid);
  // Get the docs by query
  const d = await getDoc(rd);
  if (d.exists()) {
    return {
      ...up,
      ...d.data(),
    } as UserProfile;
  }
  return false;
}

/**
 * Updates or sets the profile data of the current user.
 * @param profile
 * @returns
 */
export async function FbSetProfile(profile: UserProfile) {
  if (!auth.currentUser) return false;
  const rd = doc(store, "users", auth.currentUser.uid);
  await setDoc(rd, profile, { merge: true });
  return true;
}

/**
 * Performs the login with provided username and password.
 */
export async function FbLogin(email: string, pass: string) {
  const resp = await signInWithEmailAndPassword(auth, email, pass);
  return resp.user;
}

/**
 * Registers the user on firebase.
 * @param email
 * @param name
 * @param pass
 */
export async function FbSignup(email: string, name: string, pass: string) {
  const resp = await createUserWithEmailAndPassword(auth, email, pass);
  await updateProfile(resp.user, { displayName: name });
  // Send email
  await SendVerificationEmail(email);
  return auth.currentUser;
}

/**
 * Does what it says (❁´◡`❁)
 */
export async function FbLogout() {
  await signOut(auth);
}

export async function FbSendVerificationEmail(email: any) {
  await SendVerificationEmail(email);
}

/**
 * Creates or updates an invoice. Use replace: true to disable merging.
 */
export async function FbSetInvoice(invoice: Invoice, replace: boolean = false) {
  // If the ID doesn't exist on invoice, we give it a random one.
  const __col = collection(store, "invoices");
  const __doc = invoice.id ? doc(__col, invoice.id.toString()) : doc(__col);
  // create the invoice
  if (!invoice.id) invoice.id = __doc.id;
  await setDoc(__doc, invoice, {
    // If ID is not specified, we also set it
    merge: !replace,
  });
  // Return the invoice
  const inv = await getDoc(__doc);
  return inv.data() as Invoice;
}

/**
 * Returns the invoices of the current user.
 */
export async function FbGetInvoices(
  status?:
    | "review" // Currently being reviewed
    | "validated" // Client accepted the invoice
    | "paid" // Client has paid the invoice
    | "unpaid" // Invoice is unpaid
    | "complete" // Invoice completed.
    | "expired" // Expired
    | "delivered" // Delivered by contractr
    | "approved" // Work approved by client
    | "rejected" // work rejected by client
    | "dispute" // work in dispute
) {
  const currentUid = auth.currentUser?.uid!;
  // The invoices collection: Note that invoices collection does not belong to any specific user.
  const col = collection(store, "invoices");
  let q = query(col, where("ownerId", "==", currentUid));
  if (status) {
    q = query(q, where("status", "==", status));
  }
  // Get the docs by query
  const docs = await getDocs(q);
  const invs: Invoice[] = [];
  for (let d of docs.docs) {
    if (d.exists()) {
      invs.push(d.data() as Invoice);
    }
  }
  return invs;
}

export async function FbGetInvoiceCount(
  status?:
    | "review" // Currently being reviewed
    | "validated" // Client accepted the invoice
    | "paid" // Client has paid the invoice
    | "unpaid" // Invoice is unpaid
    | "complete" // Invoice completed.
    | "expired" // Expired
    | "delivered" // Delivered by contractr
    | "approved" // Work approved by client
    | "rejected" // work rejected by client
    | "dispute" // work in dispute
) {
  const currentUid = auth.currentUser?.uid!;
  // The invoices collection: Note that invoices collection does not belong to any specific user.
  const col = collection(store, "invoices");
  let q = query(col, where("ownerId", "==", currentUid));
  if (status) {
    q = query(q, where("status", "==", status));
  }
  // Get the docs by query
  const cn = await getCountFromServer(q);
  if (cn && cn.data()) return cn.data().count;
  return 0;
}

/**
 * Gets the recent number of invoices.
 */
export async function FbGetRecentInvoices(count: number = 5) {
  const currentUid = auth.currentUser?.uid!;
  // The invoices collection:
  const col = collection(store, "invoices");
  const q = query(
    col,
    orderBy("dateCreated", "desc"),
    where("ownerId", "==", currentUid),
    limit(count)
  );
  // Get the docs by query
  const docs = await getDocs(q);
  const invs: Invoice[] = [];
  for (let d of docs.docs) {
    if (d.exists()) {
      invs.push(d.data() as Invoice);
    }
  }
  return invs;
}

/**
 * Returns the invoice by specified id.
 */
export async function FbGetInvoice(id: string) {
  // The invoices collection
  const rd = doc(store, "invoices", id);
  // Get the docs by query
  const d = await getDoc(rd);
  if (d.exists()) {
    return d.data() as Invoice;
  }
  return false;
}

/**
 * Creates a new client or updates an existing one. Use replace: true to perform full replacement.
 * @param client
 * @param replace
 * @returns
 */
export async function FbSetClient(client: Client) {
  // If the ID doesn't exist, we give it a random one.

  if (!client.id) {
    client.id = new Date().getTime().toString();
    client.dateCreated = new Date().getTime();
    client.ownerId = auth.currentUser!.uid;
  }
  client.dateUpdated = new Date().getTime();
  const __doc = doc(
    store,
    "users",
    client.ownerId,
    "clients",
    client.id.toString()
  );
  // create client
  await setDoc(__doc, {
    ...client,
  });
  // Return client
  const cli = await getDoc(__doc);
  return cli.data() as Client;
}

/**
 * Returns the invoices of the current user.
 */
export async function FbGetClients() {
  const currentUid = auth.currentUser?.uid!;
  // The clients collection
  const col = collection(store, "users", currentUid, "clients");
  // Get the docs by query
  const docs = await getDocs(col);
  const clis: Client[] = [];
  for (let d of docs.docs) {
    if (d.exists()) {
      clis.push(d.data() as Client);
    }
  }
  return clis;
}

/**
 * Gets the client by its ID.
 * @param id
 */
export async function FbGetClient(id: string) {
  const currentUid = auth.currentUser?.uid!;
  // The clients collection
  const col = collection(store, "users", currentUid, "clients");
  // Get the doc by id
  const _doc = await getDoc(doc(col, id));
  if (_doc.exists() && _doc.data()) {
    return _doc.data() as Client;
  }
  return false;
}

/**
 * Returns the clients, with most recent first.
 */
export async function FbGetRecentClients() {
  const currentUid = auth.currentUser?.uid!;
  // The clients collection
  const col = collection(store, "users", currentUid, "clients");
  const q = query(col, orderBy("dateUpdated", "desc"));
  // Get the docs by query
  const docs = await getDocs(q);
  const clis: Client[] = [];
  for (let d of docs.docs) {
    if (d.exists()) {
      clis.push(d.data() as Client);
    }
  }
  return clis;
}

/**
 * Gets the transactions of the current user.
 */
export async function FbGetTransactions() {
  const currentUid = auth.currentUser?.uid!;
  // The clients collection
  const col = collection(store, "transactions");
  // Get the docs by query
  const _q = query(col, where("uid", "==", currentUid));
  const docs = await getDocs(_q);
  const clis: Transaction[] = [];
  for (let d of docs.docs) {
    if (d.exists()) {
      clis.push(d.data() as Transaction);
    }
  }
  return clis;
}

/**
 * Sets or updates the transaction on database.
 * @param tx
 * @returns
 */
export async function FbSetTransaction(tx: Transaction) {
  // If the ID doesn't exist, we give it a random one.
  const __doc = doc(store, "transactions", tx.id);
  // create client
  await setDoc(__doc, tx);
  // Return tx
  const _tx = await getDoc(__doc);
  return _tx.data() as Transaction;
}

/**
 * Gets the recent number of transactions.
 */
export async function FbGetRecentTransactions(count: number = 5) {
  const currentUid = auth.currentUser?.uid!;
  // The invoices collection:
  const col = collection(store, "transactions");

  const q = query(
    col,
    where("uid", "==", currentUid),
    orderBy("time", "desc"),
    limit(count)
  );
  // Get the docs by query
  const docs = await getDocs(q);
  const invs: Transaction[] = [];
  for (let d of docs.docs) {
    if (d.exists()) {
      invs.push(d.data() as Transaction);
    }
  }
  return invs;
}

/**
 * Reauthenticates the user and changes the password.
 * @param currentPassword
 * @param password
 */
export async function FbChangePassword(
  currentPassword: string,
  password: string
) {
  // 1. Reauthenticate
  const creds = EmailAuthProvider.credential(
    auth.currentUser!.email!,
    currentPassword
  );
  await reauthenticateWithCredential(auth.currentUser!, creds);
  await updatePassword(auth.currentUser!, password);
}
