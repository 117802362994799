import payant_logo from "./payant-logo.png";
import app_logo from "./payant-logo-transparent.svg";
import _checkmark_purple from "./checkmark-purple.svg";
import _pending_purple from "./pending.svg";
import _document_purple from "./document-view.svg";
import _logo_type from "./logotype.svg";
import _bg from "./bg_login.png";
import _logo_white from "./payant-logo-white.png";
import _phoenix from "./phoenix.png";

// const _inter = require("./Inter.ttf");
const _inter_b = require("./Inter-bold.ttf");

export const PAYANT_LOGO = payant_logo;
export const PAYANT_LOGO_TRANSPARENT = app_logo;
export const PAYANT_ICON = _logo_type;
export const BG_LOGIN = _bg;
export const CHECKMARK_PURPLE = _checkmark_purple;
export const PENDING_PURPLE = _pending_purple;
export const DOCUMENT_PURPLE = _document_purple;
export const PAYANT_LOGO_WHITE = _logo_white;
export const PHOENIX = _phoenix;
// export const FONT_INTER = _inter;
export const FONT_INTER_BOLD = _inter_b;
