import {
	createTheme,
	filledInputClasses,
	selectClasses,
	tableCellClasses,
	ThemeProvider,
} from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { AppConfig } from "../config";
import { aTheme } from "../states/theme";

/**
 * Wraps the children in the theme provider using the current application theme set in the state.
 * @param props
 * @returns
 */
export default function ThemeManager(props: any) {
	const [themeMode] = useRecoilState(aTheme);
	const theme = createTheme({
		typography: {
			fontFamily: "Inter",
		},
		components: {
			MuiAppBar: {
				defaultProps: {
					enableColorOnDark: true,
					color: "transparent",
					elevation: 0,
				},
			},
			MuiTable: {
				styleOverrides: {
					root: {
						borderCollapse: "separate",
						borderSpacing: "0px 8px",
						border: "solid 0px",
						background: "transparent",
					},
				},
			},
			MuiTableRow: {
				defaultProps: {
					sx: { background: "transparent" },
				},
				styleOverrides: {
					root: ({ theme, ownerState }) => ({
						background: "transparent",
						"& .printable": {
							background: "#F5F5F5",
							":first-child": {
								border: "solid 0px transparent",
								borderTopLeftRadius:
									AppConfig.globalCornerRadius,
								borderBottomLeftRadius:
									AppConfig.globalCornerRadius,
							},
							":last-child": {
								border: "solid 0px transparent",
								borderTopRightRadius:
									AppConfig.globalCornerRadius,
								borderBottomRightRadius:
									AppConfig.globalCornerRadius,
							},
						},

						":hover": {
							"& .MuiTableCell-body": {
								transition: "all .2s",
								background: `${theme.palette.action.hover}`,
								color: "#373737",
								cursor: "pointer",
							},
						},
						"& .printableBody": {
							background: "transparent",
						},
					}),
				},
			},
			MuiTableCell: {
				styleOverrides: {
					head: ({ theme }) => ({
						color: "#7F7F88",
						// Background is important here, otherwise some text can be seen under the headings
						background: "#fafbfb",
						fontWeight: 600,
						fontSize: "10px",
						[theme.breakpoints.up("sm")]: {
							fontSize: "12px",
						},
					}),
					root: ({ ownerState, theme }) => ({
						borderBottom: "none",
					}),

					body: ({ theme }) => ({
						transition: "all .2s",
						background: `transparent`,
						color: "#373737",
						fontWeight: 500,
						fontSize: "10px",
						":first-child": {
							border: "solid 0px transparent",
							borderTopLeftRadius: "4px",
							borderBottomLeftRadius: "4px",
						},
						":last-child": {
							border: "solid 0px transparent",
							borderTopRightRadius: "4px",
							borderBottomRightRadius: "4px",
						},
						[theme.breakpoints.up("sm")]: { fontSize: "12px" },
					}),
				},
			},
			MuiListItemButton: {
				styleOverrides: {
					root: {
						borderRadius: AppConfig.globalCornerRadius,
						marginBottom: "2px",
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						textTransform: "none",
						borderRadius: AppConfig.globalCornerRadius,
					},

					containedPrimary: {
						color: "white",
						background: "#5D18EB",
						fontSize: "14px",
						fontWeight: 700,
						textTransform: "none",
						":hover": {
							background: "#938BEF",
							color: "white",
						},
						":active": {
							background: "#5106F7",
							color: "white",
						},
						":disabled": {
							background: "#938BEF",
							color: "white",
						},
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						borderRadius: AppConfig.globalCornerRadius,
					},
				},
			},
			MuiSelect: {
				defaultProps: {
					disableUnderline: true,
				},
			},
			MuiTextField: {},
			MuiTabs: {
				styleOverrides: {
					indicator: {
						background: "#938BEF",
						height: "3px",
					},
				},
			},
			MuiTab: {
				styleOverrides: {
					root: {
						color: "#7F7F88",
						fontWeight: 500,
						fontSize: "16px",
						textTransform: "none",
					},
					selected: {
						fontWeight: 600,
						color: "black",
					},
					textColorInherit: {
						color: "black",
					},
				},
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						background: "#938BEF",
						color: "#FFF",
						padding: "8px",
					},
					arrow: {
						color: "#938BEF",
					},
				},
			},
		},
		palette: {
			mode: themeMode === "dark" ? "dark" : "light",
			...(themeMode === "dark"
				? {
						background: {
							default: "#192231",
							paper: "#24344d",
						},
				  }
				: {
						background: {
							default: "#FFF",
							paper: "#FFF",
						},
				  }),
			success: {
				main: "#6DC881",
			},
			primary: {
				main: "#4E00E4",
			},
			secondary: {
				main: "#6DC881",
			},
			action: {
				hover: "#D4D4D499",
			},
			text: {
				primary: "#000",
				secondary: "#373737",
			},
			common: {
				white: "#f4f4f4",
			},
		},
	});
	return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
