import { Button, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import PayantTextField from "../components/textfield";
import { useSnackbar } from "notistack";
import { FbSetClient } from "../core/firebase";
import { useRecoilState } from "recoil";
import { aPageTitle } from "../states/ui";
import { useLocation, useNavigate } from "react-router";

export function CreateClientControl() {
  const [client, setClient] = React.useState<any>();
  const [busy, setBusy] = React.useState(false);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  const [, setPageTitle] = useRecoilState(aPageTitle);
  const { enqueueSnackbar } = useSnackbar();

  const loc = useLocation();
  const nav = useNavigate();

  function isFormValid() {
    if (!client) {
      return false;
    }
    if (!client.name) {
      return false;
    }
    if (!client.email) {
      return false;
    }
    if (!client.address) {
      return false;
    }
    if (!client.city) {
      return false;
    }
    if (!client.country) {
      return false;
    }
    return true;
  }

  async function createClient() {
    // add client
    // Validate some fields
    if (!client) {
      enqueueSnackbar("Error: Please enter information about client.", {
        variant: "error",
      });
      return;
    }
    if (!client.name) {
      enqueueSnackbar("Error: Please enter the name of the client.", {
        variant: "error",
      });
      return;
    }
    if (!client.email) {
      enqueueSnackbar("Error: Please enter the email of the client.", {
        variant: "error",
      });
      return;
    }
    if (!client.address) {
      enqueueSnackbar("Error: Please enter the address of the client.", {
        variant: "error",
      });
      return;
    }
    if (!client.city) {
      enqueueSnackbar("Error: Please enter the city of the client.", {
        variant: "error",
      });
      return;
    }
    if (!client.country) {
      enqueueSnackbar("Error: Please enter the country of the client.", {
        variant: "error",
      });
      return;
    }
    // Create the client
    setBusy(true);
    try {
      // create
      const resp = await FbSetClient(client);
      enqueueSnackbar("Client added successfully.", { variant: "success" });
      // If the location has ref=quote query, we return back to create quote
      if (loc.state?.fromQuote) {
        // Go back to quote.
        nav("/portal/quotes/create", {
          state: { name: loc.state?.name || "", client: resp },
        });
      } else {
        window.history.back();
      }
    } catch (err: any) {
      enqueueSnackbar(
        "Error: unable to create the client. Please check logs for details.",
        {
          variant: "error",
        }
      );
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setPageTitle("Create Client");
  }, []);

  return (
    <Stack className="payant-section" spacing={"10px"}>
      <Typography
        fontWeight={600}
        fontSize="16px"
        sx={{ alignSelf: "center", mb: "5px" }}
      >
        Add Client
      </Typography>

      <PayantTextField
        value={client?.name}
        onChange={(c) =>
          setClient({ ...(client as any), name: c.currentTarget.value })
        }
        label="Name"
        required
      />
      <PayantTextField
        label="Email"
        required
        value={client?.email}
        onChange={(c) =>
          setClient({ ...(client as any), email: c.currentTarget.value })
        }
      />
      <PayantTextField
        label="Address"
        value={client?.address}
        onChange={(c) =>
          setClient({ ...(client as any), address: c.currentTarget.value })
        }
        required
      />
      <PayantTextField
        label="City"
        value={client?.city}
        onChange={(c) =>
          setClient({ ...(client as any), city: c.currentTarget.value })
        }
        required
      />
      <PayantTextField
        label="Country"
        value={client?.country}
        onChange={(c) =>
          setClient({ ...(client as any), country: c.currentTarget.value })
        }
        required
      />
      <PayantTextField
        label="Registration Number (optional)"
        value={client?.registrationNumber}
        onChange={(c) =>
          setClient({
            ...(client as any),
            registrationNumber: c.currentTarget.value,
          })
        }
      />
      <PayantTextField
        label="TAX ID (Optional)"
        value={client?.taxId}
        onChange={(c) =>
          setClient({ ...(client as any), taxId: c.currentTarget.value })
        }
      />
      <PayantTextField
        label="VAT ID (Optional)"
        value={client?.vatId}
        onChange={(c) =>
          setClient({ ...(client as any), vatId: c.currentTarget.value })
        }
      />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={"24px"}
        sx={{ pt: "22px" }}
      >
        <Button
          onClick={() => window.history.back()}
          variant="outlined"
          sx={{
            height: "44px",
            width: "120px",
            borderRadius: "28px",
            ":hover": {
              background: "#938BEF",
              color: "white",
              border: "0px",
            },
          }}
        >
          Back
        </Button>
        <Button
          id="create_client_CTA"
          disabled={busy || !isFormValid()}
          onClick={createClient}
          variant="contained"
          sx={{
            height: "44px",
            width: "120px",
            px: "10px",
            borderRadius: "28px",
          }}
        >
          Create Client
        </Button>
      </Stack>
    </Stack>
  );
}

export default function SectionCreateClient() {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
  return (
    <Stack
      id="client_new_container"
      sx={{ width: "100%", height: "100%" }}
      justifyContent="center"
      alignItems="center"
    >
      <Paper
        elevation={0}
        sx={{
          width: isDesktop ? "466px" : "100%",
          minWidth: isDesktop ? "400px" : "0px",
          maxWidth: isDesktop ? "594px" : "auto",
          px: isDesktop ? "35px" : "17.5px",
          py: "15px",
          background: "#FFF",
          borderRadius: 0,
        }}
      >
        <CreateClientControl />
      </Paper>
    </Stack>
  );
}
